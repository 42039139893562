import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Typography } from 'antd';

import { useQuery } from '@apollo/client';

import 'react-phone-input-2/lib/bootstrap.css';
import '../../Common/styles/phoneNumber.less';

//layout
import Layout from '../../Common/containers/Layout';

//partials
import { ProfilePicture } from '../../Common/partials/ProfilePicture';

// Queries
import { Queries as FetchProfileQueries } from '../../Common/queries/myAccount';

//skeletons
import FormSkeleton from '../../Common/partials/Skeletons/FormSkeleton';
//types
import { FetchProfileResponse } from '../../Common/types/settings.type';

// assets
import editIcon from '../../Common/assets/edit.svg';
import { capitaliseString } from '../../Common/helpers';
import { formatPhoneNumber } from '../../Common/helpers/wordFormat';
import EditPhoneNumberModal from './EditPhoneNumberModal';

const Settings: FunctionComponent = () => {
  const { data: profileData, loading: isloading } =
    useQuery<FetchProfileResponse>(FetchProfileQueries.getMeAccount);

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isloading) return <FormSkeleton page='settings' />;

  const { fetchProfile } = profileData;

  return (
    <Layout>
      <Row style={{ color: '#071421', marginTop: '33px' }}>
        <Col
          style={{ display: 'flex', justifyContent: 'space-between' }}
          xs={24}
          sm={12}
        >
          <h5 style={{ margin: '0' }}>Settings</h5>
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setIsModalOpen(true)}
          >
            <img src={editIcon} alt='edit' />
            <span
              style={{
                color: '#F8793A',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              Edit
            </span>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        {/* Profile image label for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>
            Profile image
          </span>
        </Col>
        {/* Profile image label for large devices */}
        <Col xs={0} sm={3} style={{ textAlign: 'end' }}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>
            Profile image
          </span>
        </Col>
        {/* Profile image for mobile devices */}
        <Col xs={24} sm={0}>
          <div
            style={{
              position: 'relative',
              top: 0,
              bottom: 0,
              marginBottom: '1rem',
            }}
          >
            <ProfilePicture
              size={50}
              user={{
                firstName: fetchProfile.firstName,
                profilePictureURL: fetchProfile.profileUrl,
              }}
              overlay={false}
            />
          </div>
        </Col>
        {/* Profile image section for large devices */}
        <Col xs={0} sm={12}>
          <div
            style={{
              position: 'relative',
              top: 0,
              bottom: 0,
              marginBottom: '1rem',
              paddingLeft: '1rem',
            }}
          >
            <ProfilePicture
              size={50}
              user={{
                firstName: fetchProfile.firstName,
                profilePictureURL: fetchProfile.profileUrl,
              }}
              overlay={false}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        {/* First name label for mobile devices */}
        <Col xs={12} sm={0}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>First name</span>
        </Col>
        {/* First name label for large device */}
        <Col xs={0} sm={3} style={{ textAlign: 'end' }}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>First name</span>
        </Col>
        {/* First name value for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#071421', fontSize: '16px' }}>
            {capitaliseString(fetchProfile.firstName)}
          </span>
        </Col>
        {/* First name value for large devices */}
        <Col xs={0} sm={12}>
          <span
            style={{ color: '#071421', fontSize: '16px', paddingLeft: '1rem' }}
          >
            {capitaliseString(fetchProfile.firstName)}
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        {/* Last name label for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>Last name</span>
        </Col>
        {/* Last name label for large devices */}
        <Col xs={0} sm={3} style={{ textAlign: 'end' }}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>Last name</span>
        </Col>
        {/* Last name value for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#071421', fontSize: '16px' }}>
            {capitaliseString(fetchProfile.lastName)}
          </span>
        </Col>
        {/* Last name value for large devices */}
        <Col xs={0} sm={3}>
          <span
            style={{ color: '#071421', fontSize: '16px', paddingLeft: '1rem' }}
          >
            {capitaliseString(fetchProfile.lastName)}
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        {/* Email label for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>Email</span>
        </Col>
        {/* Email label for large devices */}
        <Col xs={0} sm={3} style={{ textAlign: 'end' }}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>Email</span>
        </Col>
        {/* Email value for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#071421', fontSize: '16px' }}>
            {fetchProfile.email}
          </span>
        </Col>
        {/* Email value for large devices */}
        <Col xs={0} sm={3}>
          <span
            style={{ color: '#071421', fontSize: '16px', paddingLeft: '1rem' }}
          >
            {fetchProfile.email}
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        {/* Phone label for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>Phone</span>
        </Col>
        {/* Phone label for large devices */}
        <Col xs={0} sm={3} style={{ textAlign: 'end' }}>
          <span style={{ color: '#9BA1A6', fontSize: '16px' }}>Phone</span>
        </Col>
        {/* Phone value for mobile devices */}
        <Col xs={24} sm={0}>
          <span style={{ color: '#071421', fontSize: '16px' }}>
            {formatPhoneNumber(fetchProfile.phone)}
          </span>
        </Col>
        {/* Phone value for large devices */}
        <Col xs={0} sm={8}>
          <span
            style={{ color: '#071421', fontSize: '16px', paddingLeft: '1rem' }}
          >
            {formatPhoneNumber(fetchProfile.phone)}
          </span>
        </Col>
      </Row>
      <EditPhoneNumberModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        phoneNumber={fetchProfile?.phone}
      />
      {/* <Row style={{ marginTop: '16px' }}>
        <Col offset={3} style={{ paddingLeft: '1rem' }}>
          <Link to='#' style={{ fontWeight: 600 }}>
            <div style={{ display: 'flex', gap: '5px' }}>
              <img
                style={{ display: 'inline-block' }}
                src={keyIcon}
                alt='key'
              />
              <span
                style={{
                  fontSize: 18,
                  color: '#0C203A',
                  opacity: 0.6,
                  fontWeight: 600,
                }}
              >
                Account settings
              </Text>
              <Formik
                initialValues={{
                  firstName: fetchProfile.firstName,
                  lastName: fetchProfile.lastName,
                  phone: fetchProfile.phone,
                  profileUrl: fetchProfile.profileUrl,
                  email: fetchProfile.email,
                  country: {
                    countryCode: '',
                    dialCode: '',
                    format: '',
                    name: '',
                  },
                }}
                validate={validate}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <Form
                    onSubmitCapture={handleSubmit}
                    colon={false}
                    layout={'vertical'}
                    requiredMark={false}
                    style={{ marginTop: 24 }}
                    onKeyDown={onKeyDown}
                  >
                    <div>
                      <div
                        style={{
                          position: 'relative',
                          top: 0,
                          bottom: 0,
                          marginBottom: '1rem',
                        }}
                      >
                        <ProfilePicture
                          size={77}
                          user={{
                            firstName: values.firstName,
                            profilePictureURL: values.profileUrl,
                          }}
                        />

                        <ImageUpload
                          height={400}
                          width={400}
                          folder='profile-pictures'
                          onFileUpload={(file) => {
                            setFieldValue('profileUrl', file?.imageUrl);
                          }}
                          type='icon'
                          fileList={[]}
                        />
                      </div>
                    </div>

                    <Row gutter={16} justify='space-between'>
                      <Col span={12}>
                        <Form.Item
                          label='First name'
                          className='profile'
                          validateStatus={
                            errors.firstName ? 'error' : 'success'
                          }
                          help={errors.firstName}
                          required
                        >
                          <Input
                            type='text'
                            name='firstName'
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                            }}
                            size='large'
                            value={values.firstName}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label='Last name'
                          className='profile'
                          validateStatus={errors.lastName ? 'error' : 'success'}
                          help={errors.lastName}
                          required
                        >
                          <Input
                            type='text'
                            name='lastName'
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value);
                            }}
                            size='large'
                            value={values.lastName}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label='Email address'
                      className='profile'
                      validateStatus={errors.email ? 'error' : 'success'}
                      help={errors.email}
                      style={{ marginTop: 5 }}
                      required
                    >
                      <Input
                        type='text'
                        name='email'
                        size='large'
                        disabled
                        value={values.email}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Phone number'
                      className='profile'
                      validateStatus={errors.phone ? 'error' : 'success'}
                      help={errors.phone}
                      style={{ marginTop: 12 }}
                      required
                    >
                      <PhoneInput
                        country={values.country.countryCode}
                        value={values.phone}
                        onChange={(value, country) => {
                          setFieldValue('country', country);
                          setFieldValue('phone', value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginTop: 50 }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={isSubmitting}
                        className='btnUpdateDetails'
                        size='large'
                        style={{ width: '169px' }}
                      >
                        Update details
                      </Button>
                      <Button
                        type='link'
                        size='large'
                        className='btnCancel'
                        onClick={() => history.push('/')}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Formik>
            </div>
          </Link>
        </Col>
      </Row> */}
    </Layout>
  );
};

export default Settings;
