import React, { FunctionComponent, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Row, Col, Select, Input, Divider, message, Typography } from 'antd';
import { pdfjs } from 'react-pdf';

import FormSkeleton from '../../Common/partials/Skeletons/FormSkeleton';

//layout
import Layout from '../../Common/containers/Layout';

//Queries
import { Queries as SkillQuery } from '../../Common/queries/skills';
import { Queries as DesignationQuery } from '../../Common/queries/roles';
import {
  FetchProfileQueries,
  PatchProfileMutations,
} from '../../Common/queries/myAccount';

//types
import { SearchInput } from '../../Common/types/onBoarding.type';
import {
  FetchProfileResponse,
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../../Common/types/candidate.type';
import { SkillSearchResponse } from '../../Common/types/skills.type';
import { DesignationSearchResponse } from '../../Common/types/designation.type';

//partials

import CVUpload from '../../Common/partials/CVUpload';

import ProfileInformationPanel from '../partials/ProfileInformationPanel';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

//constants
import { currencies } from '../../Common/constants';

//assets
import download from '../../Common/assets/downloadIcon.svg';
import deleteSkill from '../../Common/assets/delete-cross.svg';
import arrowDown from '../../Common/assets/dropdown-arrow-down.svg';
import { ProfilePicture } from '../../Common/partials/ProfilePicture';
import ImageUpload from '../../Common/partials/ImageUpload';
import editIcon from '../../Common/assets/edit.svg';
import EditGeneralInfoModal from '../partials/EditGeneralInfoModal';
import EditJobPreferenceInfoModal from '../partials/EditJobPreferenceInfoModal';
import EditSkillsInfoModal from '../partials/EditSkillsInfoModal';
import EditSalaryExpectationModal from '../partials/EditSalaryExpectationModal';
import {
  capitalizeEachWordInArray,
  capitalizeFirstLetter,
} from '../../Common/helpers/wordFormat';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const Iframe = ({ iframe }: { iframe: string }) => (
  <div dangerouslySetInnerHTML={{ __html: iframe ? iframe : '' }} />
);

const DEFAULT_INPUT_SIZE = 'middle';

const UserProfile: FunctionComponent = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [uploadedFile, setUploadedFile] = useState('');
  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [isGeneralInfoModalOpen, setIsGeneralInfoModalOpen] = useState(false);
  const [isJobPreferenceModalOpen, setIsJobPreferenceModalOpen] =
    useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [isSalaryModalOpen, setIsSalaryModalOpen] = useState(false);
  const history = useHistory();

  const {
    data: profileData,
    loading: isloading,
    refetch,
  } = useQuery<FetchProfileResponse>(FetchProfileQueries.FetchProfile);

  useEffect(() => {
    if (profileData?.fetchProfile) {
      setProfilePictureURL(profileData.fetchProfile.profileUrl);
    }
  }, [profileData]);

  const [loadSkillSuggestions, { data: skills, loading: isSkillsLoading }] =
    useLazyQuery<SkillSearchResponse, SearchInput>(SkillQuery.fetchSkills);

  const [
    loadDesignationSuggestions,
    { data: designations, loading: isDesignationsloading },
  ] = useLazyQuery<DesignationSearchResponse, SearchInput>(
    DesignationQuery.fetchRoles
  );

  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  useEffect(() => {
    if (!isloading) {
      refetch();
    }
  }, []);

  const handleSkillsSearch = (searchTerm: string) => {
    loadSkillSuggestions({
      variables: {
        data: { searchTerm },
      },
    });
  };

  const handleDesignationSearch = (searchTerm: string) => {
    loadDesignationSuggestions({
      variables: {
        data: { searchTerm },
      },
    });
  };

  if (isloading) {
    return <FormSkeleton page='profile' />;
  }

  const onSubmit = async (formData) => {
    const formattedSkills = formData.skills.map((skill) => ({
      skill: skill.skill,
      noOfYears: skill.noOfYears,
    }));

    try {
      const payload = {
        location: formData.location,
        country: formData.country || fetchProfile.country,
        admin1: formData.admin_1 || fetchProfile.admin1,
        admin2: formData.admin_2 || fetchProfile.admin2,
        locality: formData.locality || fetchProfile.locality,
        geoLocation: {
          lat: formData.location_lat || fetchProfile.geoLocation.lat,
          lon: formData.location_lng || fetchProfile.geoLocation.lon,
        },
        resumeUrl: uploadedFile === '' ? fetchProfile.resumeUrl : uploadedFile,
        skills: formattedSkills,
        preferredWorkStyle: formData.preferredWorkStyle,
        currentlyEmployed: formData.currentlyEmployed,
        startDate: formData.startDate,
        jobSearchStatus: formData.jobSearch,
        expectedSalaryAmount: formData.expectedSalaryAmount,
        expectedSalaryCurrency: formData.expectedSalaryCurrency,
        expectedSalaryFrequency: formData.expectedSalaryFrequency,
        salaryFlexible: formData.salaryFlexible,
        pastResponsibilities: formData.pastResponsibilities,
        primaryRole: formData.primaryRole,
        secondaryRoles: formData.multipleRoles,
        anonymous: formData.anonymous,
      };

      await candidateUpdateProfile({
        variables: { data: payload },
      });

      if (!isProfileUpdating) {
        message.success('Profile updated Successfully', 3);
      }
    } catch (e) {
      e.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    }
  };

  const { fetchProfile } = profileData;

  //Get no of years
  const noOfYears = ['< 1', ...new Array(20), '20 +'].map((value, index) => (
    <Option key={index} value={value ? value : index}>
      {value ? value : index}
    </Option>
  ));

  const isAddSkillDisabled = (skills) => {
    const hasUndefinedSkills = skills.find(
      (skill) => skill.skill === undefined || skill.noOfYears === undefined
    );

    if (hasUndefinedSkills) {
      return true;
    }

    return false;
  };

  return (
    <Layout>
      <div>
        <Row className='justify-center' gutter={48}>
          <Col xs={24} lg={12} xl={12}>
            <div
              className='onboarding-card'
              style={{
                margin: '4rem 0.2rem',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ display: 'flex', gap: '24px' }}>
                <div
                  style={{
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    marginBottom: '1rem',
                  }}
                >
                  <ProfilePicture
                    size={80}
                    user={{
                      firstName: fetchProfile.firstName,
                      profilePictureURL: profilePictureURL,
                    }}
                    overlay={false}
                  />
                  <ImageUpload
                    height={400}
                    width={400}
                    folder='profile-pictures'
                    onFileUpload={(file) => {
                      setProfilePictureURL(file?.imageUrl);
                    }}
                    showUploadList={false}
                    type='icon'
                    fileList={[]}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '28px', fontWeight: 400 }}>
                    {`${capitalizeFirstLetter(
                      fetchProfile?.firstName
                    )} ${capitalizeFirstLetter(fetchProfile?.lastName)}`}
                  </span>
                  <span style={{ fontSize: '14px', fontWeight: 200 }}>
                    {fetchProfile?.email}
                  </span>
                </div>
              </div>
              <ProfileInformationPanel
                header={'General information'}
                labels={[
                  'Name',
                  'Email',
                  'Location',
                  'Primary role',
                  'Other roles',
                  'Bio',
                ]}
                values={[
                  `${capitalizeFirstLetter(
                    fetchProfile?.firstName
                  )} ${capitalizeFirstLetter(fetchProfile?.lastName)}`,
                  fetchProfile?.email,
                  fetchProfile?.location,
                  fetchProfile?.primaryRole,
                  capitalizeEachWordInArray(fetchProfile?.secondaryRoles)
                    .length > 0
                    ? capitalizeEachWordInArray(fetchProfile?.secondaryRoles)
                    : null,
                  fetchProfile?.pastResponsibilities,
                ]}
                onEditClick={() => {
                  setIsGeneralInfoModalOpen(true);
                }}
              />
              <EditGeneralInfoModal
                open={isGeneralInfoModalOpen}
                setOpen={setIsGeneralInfoModalOpen}
                firstName={fetchProfile?.firstName}
                lastName={fetchProfile?.lastName}
                email={fetchProfile?.email}
                location={fetchProfile?.location}
                geoLocation={fetchProfile?.geoLocation}
                country={fetchProfile?.country}
                primaryRole={fetchProfile?.primaryRole}
                otherRoles={fetchProfile?.secondaryRoles}
                bio={fetchProfile?.pastResponsibilities}
                handleDesignationSearch={(value) => {
                  handleDesignationSearch(value);
                }}
                isDesignationsloading={isDesignationsloading}
                designations={designations?.designationSearch?.hits}
              />
              <Divider />
              <ProfileInformationPanel
                header={'Skills'}
                labels={fetchProfile?.skills}
                values={fetchProfile?.skills}
                onEditClick={() => {
                  setIsSkillsModalOpen(true);
                }}
              />
              <EditSkillsInfoModal
                open={isSkillsModalOpen}
                setOpen={setIsSkillsModalOpen}
                handleSkillsSearch={handleSkillsSearch}
                isSkillsLoading={isSkillsLoading}
                searchSkills={skills?.skillSearch?.hits}
                skills={fetchProfile?.skills}
              />
              <Divider />
              <ProfileInformationPanel
                header={'Job preferences'}
                labels={[
                  'Work style',
                  'Employment status',
                  'Job search status',
                  'Notice period',
                ]}
                values={[
                  fetchProfile?.preferredWorkStyle,
                  fetchProfile?.currentlyEmployed,
                  capitalizeFirstLetter(fetchProfile?.jobSearchStatus),
                  capitalizeFirstLetter(fetchProfile?.startDate),
                ]}
                onEditClick={() => {
                  setIsJobPreferenceModalOpen(true);
                }}
              />
              <EditJobPreferenceInfoModal
                open={isJobPreferenceModalOpen}
                setOpen={setIsJobPreferenceModalOpen}
                workStyle={fetchProfile?.preferredWorkStyle}
                employmentStatus={fetchProfile?.currentlyEmployed}
                jobSearchStatus={fetchProfile?.jobSearchStatus}
                noticePeriod={fetchProfile?.startDate}
              />
              <Divider />
              <ProfileInformationPanel
                header={'Salary expectations'}
                labels={['Amount', 'Pay frequency']}
                values={[
                  `${fetchProfile?.expectedSalaryCurrency} ${fetchProfile?.expectedSalaryAmount}`,
                  capitalizeFirstLetter(fetchProfile?.expectedSalaryFrequency),
                ]}
                onEditClick={() => {
                  setIsSalaryModalOpen(true);
                }}
              />
              <EditSalaryExpectationModal
                open={isSalaryModalOpen}
                setOpen={setIsSalaryModalOpen}
                amount={fetchProfile?.expectedSalaryAmount}
                currency={fetchProfile?.expectedSalaryCurrency}
                frequency={fetchProfile?.expectedSalaryFrequency}
              />
            </div>
          </Col>
          <Col xs={0} lg={12} xl={12}>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '2rem 0rem 0.2rem',
              }}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 20 }}>
                  <CVUpload
                    folder='talent-cvs'
                    onFileUpload={(value) => setUploadedFile(value)}
                    type='icon'
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    className='btnClick'
                    href={
                      uploadedFile === ''
                        ? fetchProfile.resumeUrl
                        : uploadedFile
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img
                      src={download}
                      alt='download-icon'
                      style={{
                        marginRight: 5,
                      }}
                    />

                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: '#F8793A',
                      }}
                    >
                      Download
                    </Text>
                  </a>
                </div>
              </div>
            </Row>
            <Row justify='start'>
              <Col flex={1}>
                <Iframe
                  iframe={`<iframe src="${
                    uploadedFile === '' ? fetchProfile.resumeUrl : uploadedFile
                  }" width="100%" height="600" style="border:0;"></iframe>`}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                margin: '0rem 0.2rem',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CVUpload
                  folder='talent-cvs'
                  onFileUpload={(value) => setUploadedFile(value)}
                  type='icon'
                />
              </div>
              <div>
                <a
                  className='btnDownload'
                  href={
                    uploadedFile === '' ? fetchProfile.resumeUrl : uploadedFile
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img
                    src={download}
                    alt='download-icon'
                    style={{
                      marginRight: 5,
                    }}
                  />
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    Download CV
                  </Text>
                </a>
              </div>
            </div>

            <Row justify='center'>
              <Col xs={24}>
                <div style={{ overflow: 'scroll' }}>
                  <Iframe
                    iframe={`<iframe src="${
                      uploadedFile === ''
                        ? fetchProfile.resumeUrl
                        : uploadedFile
                    }" width="100%" height="600" style="border:0;"></iframe>`}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default UserProfile;
