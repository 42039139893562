import React, { FunctionComponent, useState } from "react";
import { Modal, Progress, Button } from "antd";
import { Set } from "immutable";

// containers
import { JobApplication } from "./JobApplication";

interface ApplyForJobProps{
  onClose: () => void;
  jobIds: Set<number>;
}

export const ApplyForJob: FunctionComponent<ApplyForJobProps> = ({ onClose, jobIds }: ApplyForJobProps) => {
  const [ processedJobs, setProcessedJobs ] = useState<Set<number>>(Set([]));
  const [ pendingJobs, setPendingJobs ] = useState<Set<number>>(jobIds);
  const [ appliedJobIds, setAppliedJobIds ] = useState<Set<number>>(Set([]));

  const curJobId = pendingJobs.toArray().length > 0 ? pendingJobs.toArray()[0] : null;

  return (
    <Modal
      title={(
        <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center" }}>
          <div>Applying for jobs <b>({processedJobs.size} of {jobIds.size})</b></div>
        </div>
      )}
      visible={true}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      {processedJobs.size === jobIds.size ? (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", padding: "2rem 2rem" }}>
          {appliedJobIds.size === 0 ? (
            <h4 style={{ padding: "1rem 0rem "}}>You didn't apply for any positions</h4>
          ): null}
          {appliedJobIds.size === 1 ? (
            <h4 style={{ padding: "1rem 0rem "}}>You applied for one position</h4>
          ) : null}
          {appliedJobIds.size > 1 ? (
            <h4 style={{ padding: "1rem 0rem "}}>You applied for {appliedJobIds.size} positions</h4>
          ) : null}
          <Button type="primary" size="large" block onMouseDown={() => onClose()}>Done</Button>
        </div>
      ) : null}
      {curJobId ? (
        <JobApplication 
          jobId={curJobId}
          onSubmitted={(jobId) => {
            setProcessedJobs(processedJobs.add(jobId));
            setPendingJobs(pendingJobs.remove(jobId));
            setAppliedJobIds(appliedJobIds.add(jobId));
          }}
          skipJob={(jobId) => {
            setProcessedJobs(processedJobs.add(jobId));
            setPendingJobs(pendingJobs.remove(jobId));
          }}
        />
      ) : null}
    </Modal>
  );
}