import create from 'zustand';
import { persist } from 'zustand/middleware';

type Skill = { key?: string; skill: string; noOfYears: string };

type LocationDetails = {
  country: string;
  admin_1: string;
  admin_2: string;
  formatted_address: string;
  locality: string;
  location_lat: number;
  location_lng: number;
};

type FileList = {
  uid: string;
  type: string;
  name: string;
  size: number;
  // lastModifiedDate: Date;
  // webkitRelativePath: string;
};

type OnBoardingStoreState = {
  location: LocationDetails;
  primaryRole: string;
  multipleRoles: string[];
  pastResponsibilities: string;
  skills: Skill[];
  preferredWorkStyle: string;
  currentlyEmployed: string;
  jobSearch: string;
  startDate: string;
  expectedSalaryAmount: number;
  expectedSalaryCurrency: string;
  expectedSalaryFrequency: string;
  salaryFlexible: boolean;
  anonymous: boolean;
  resumeUrl: string;
  fileList: FileList[];

  setResumeUrl: (resumeUrl: string) => void;
  setLocation: (location: LocationDetails) => void;
  setPrimaryRole: (primaryRole: string) => void;
  setMultipleRoles: (multipleRoles: string[]) => void;
  setPastResponsibilities: (pastResponsibilities: string) => void;
  setSkills: (skills: Skill[]) => void;
  setPreferredWorkStyle: (preferredWorkStyle: string) => void;
  setCurrentlyEmployed: (currentlyEmployed: string) => void;
  setJobSearch: (jobSearch: string) => void;
  setStartDate: (startDate: string) => void;
  setExpectedSalaryAmount: (expectedSalaryAmount: number) => void;
  setExpectedSalaryCurrency: (expectedSalaryCurrency: string) => void;
  setExpectedSalaryFrequency: (expectedSalaryFrequency: string) => void;
  setSalaryFlexible: (salaryFlexible: boolean) => void;
  setAnonymous: (anonymous: boolean) => void;
  resetOnboardingStore: () => void;
  setFileList: (fileList: FileList[]) => void;
  resetFile: () => void;
};

export const onBoardingStore = create<OnBoardingStoreState>(
  persist(
    (set) => ({
      location: {} as LocationDetails,
      primaryRole: '',
      multipleRoles: [],
      pastResponsibilities: '',
      skills: [],
      preferredWorkStyle: '',
      currentlyEmployed: '',
      jobSearch: '',
      startDate: '',
      expectedSalaryAmount: 0,
      expectedSalaryCurrency: 'LKR',
      expectedSalaryFrequency: '',
      salaryFlexible: false,
      anonymous: false,
      resumeUrl: '',
      fileList: [],

      setResumeUrl: (resumeUrl: string) => {
        set({
          resumeUrl,
        });
      },
      setLocation: (location: LocationDetails) => {
        set({
          location,
        });
      },
      setPrimaryRole: (primaryRole: string) => {
        set({
          primaryRole,
        });
      },
      setMultipleRoles: (multipleRoles: string[]) => {
        set({
          multipleRoles,
        });
      },
      setPastResponsibilities: (pastResponsibilities: string) => {
        set({
          pastResponsibilities,
        });
      },
      setSkills: (skills: Skill[]) => {
        set({
          skills,
        });
      },
      setPreferredWorkStyle: (preferredWorkStyle: string) => {
        set({
          preferredWorkStyle,
        });
      },
      setCurrentlyEmployed: (currentlyEmployed: string) => {
        set({
          currentlyEmployed,
        });
      },
      setJobSearch: (jobSearch: string) => {
        set({
          jobSearch,
        });
      },
      setStartDate: (startDate: string) => {
        set({
          startDate,
        });
      },
      setExpectedSalaryAmount: (expectedSalaryAmount: number) => {
        set({
          expectedSalaryAmount,
        });
      },
      setExpectedSalaryCurrency: (expectedSalaryCurrency: string) => {
        set({
          expectedSalaryCurrency,
        });
      },
      setExpectedSalaryFrequency: (expectedSalaryFrequency: string) => {
        set({
          expectedSalaryFrequency,
        });
      },
      setSalaryFlexible: (salaryFlexible: boolean) => {
        set({
          salaryFlexible,
        });
      },
      setFileList: (fileList) => {
        set({
          fileList,
        });
      },
      setAnonymous: (anonymous) => {
        set({
          anonymous,
        });
      },
      resetOnboardingStore: () => {
        set({
          location: {} as LocationDetails,
          primaryRole: '',
          multipleRoles: [],
          pastResponsibilities: '',
          skills: [],
          preferredWorkStyle: '',
          currentlyEmployed: '',
          jobSearch: '',
          startDate: '',
          expectedSalaryAmount: 0,
          expectedSalaryCurrency: 'LKR',
          expectedSalaryFrequency: '',
          salaryFlexible: false,
          anonymous: false,
          resumeUrl: '',
          fileList: [],
        });
      },
      resetFile: () => {
        set({
          fileList: [],
          resumeUrl: '',
        });
      },
    }),
    {
      name: 'onboarding-user-store',
    }
  )
);
