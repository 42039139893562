import { gql } from '@apollo/client';

export const SignUpMutations = {
	HuntCandidateSignUp: gql`
		mutation HuntCandidateSignUp($data: HuntCandidateSignUpInput!) {
			huntCandidateSignUp(data: $data) {
				token
				profile {
					id
					email
					firstName
					lastName
				}
			}
		}
	`,
};

export const SignInMutations = {
	CandidateSignIn: gql`
		mutation SignIn($data: HuntCandidateSignInInput!) {
			huntCandidateSignIn(data: $data) {
				token
				profile {
					id
					email
					firstName
					lastName
				}
			}
		}
	`,
};

export const SignOutQueries = {
	getCurrentAccountId: gql`
		query GetCurrentAccountId {
			me {
				id
			}
		}
	`,
};

export const UpdatePasswordMutations = {
	updatePassword: gql`
		mutation HuntCandidateUpdatePassword(
			$data: HuntCandidateUpdatePasswordInput!
		) {
			huntCandidateUpdatePassword(data: $data) {
				id
			}
		}
	`,
};

export const ResetPasswordMutations = {
	resetPassword: gql`
		mutation HuntCandidateResetPasswordEmail(
			$data: HuntCandidateResetPasswordInput!
		) {
			huntCandidateResetPasswordEmail(data: $data)
		}
	`,
};

export const ForgotPasswordMutations = {
	forgotPassword: gql`
		mutation HuntCandidateForgotPassword(
			$data: HuntCandidateForgotPasswordInput!
		) {
			huntCandidateForgotPasswordEmail(data: $data)
		}
	`,
};
