import { Button, Input, Modal, Select, message } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { currencies } from '../../Common/constants';
import {
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../../Common/types/candidate.type';
import { useMutation } from '@apollo/client';
import { PatchProfileMutations } from '../../Common/queries/myAccount';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  amount: number;
  currency: string;
  frequency: string;
}

function EditSalaryExpectationModal({
  open,
  setOpen,
  amount,
  currency,
  frequency,
}: IProps) {
  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  const onSubmit = async (values) => {
    const data = {
      expectedSalaryAmount: values.salaryExpectation,
      expectedSalaryCurrency: values.currency,
      expectedSalaryFrequency: values.paidEvery,
    };
    try {
      const response = await candidateUpdateProfile({
        variables: { data },
      });
      if (!isProfileUpdating) {
        message.success('Profile updated Successfully', 3);
      }
    } catch (error) {
      error.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    } finally {
      setOpen(false);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        salaryExpectation: amount,
        currency: currency,
        paidEvery: frequency,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          console.log(values);
        } finally {
          setOpen(false);
        }
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, resetForm }) => {
        const handleCloseModal = () => {
          resetForm();
          setOpen(false);
        };
        return (
          <Modal
            key='salary-expectation-modal'
            centered
            visible={open}
            onCancel={handleCloseModal}
            footer={null}
          >
            <h3
              style={{
                fontSize: '24px',
                color: '#071421',
                padding: '16px 20px 0px 16px',
              }}
            >
              Edit salary expectations
            </h3>
            <div style={{ padding: '0px 20px 0px 20px' }}>
              <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label
                    htmlFor='salaryExpectation'
                    style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#071421',
                      marginBottom: '8px',
                    }}
                  >
                    Salary Expectation
                  </label>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Input
                      name='salaryExpectation'
                      style={{
                        fontSize: '16px',
                        background: '#ffff',
                        height: '36px',
                      }}
                      type='number'
                      onChange={handleChange}
                      value={values.salaryExpectation}
                      size='middle'
                    />
                    <Select
                      style={{
                        width: '30%',
                        height: '32px',
                        fontSize: '16px',
                        background: '#ffff',
                      }}
                      size='middle'
                      options={currencies}
                      defaultValue={values.currency}
                      onChange={(e) => {
                        setFieldValue('currency', e);
                      }}
                    />
                  </div>
                  <label
                    htmlFor='salaryExpectation'
                    style={{
                      fontSize: '16px',
                      marginTop: '16px',
                      fontWeight: 400,
                      color: '#071421',
                      marginBottom: '8px',
                    }}
                  >
                    Paid every
                  </label>
                  <Select
                    style={{
                      width: '100%',
                      height: '32px',
                      fontSize: '16px',
                    }}
                    defaultValue={values.paidEvery}
                    size='middle'
                    options={[
                      {
                        value: 'hour',
                        label: 'Hour',
                      },
                      {
                        value: 'day',
                        label: 'Day',
                      },
                      {
                        value: 'week',
                        label: 'Week',
                      },
                      {
                        value: '2-weeks',
                        label: '2 Weeks',
                      },
                      {
                        value: 'month',
                        label: 'Month',
                      },
                      {
                        value: 'year',
                        label: 'Year',
                      },
                    ]}
                    onChange={(e) => {
                      setFieldValue('paidEvery', e);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    gap: '24px',
                    marginTop: '30px',
                  }}
                >
                  <Button
                    style={{
                      width: '100%',
                      height: '48px',
                      background: '#FFF1EB',
                      borderColor: '#FFF1EB',
                    }}
                    className='secondary-btn'
                    type='default'
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ width: '100%', height: '48px' }}
                    type='primary'
                    loading={isProfileUpdating}
                    onClick={() => onSubmit(values)}
                  >
                    Update
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
}

export default EditSalaryExpectationModal;
