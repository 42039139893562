import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

//layout
import Layout from '../../Common/containers/Layout';

const Loading: FunctionComponent = () => (
	<Layout>
		<div
			style={{
				display: 'flex',
				padding: '4rem 0rem',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
		</div>
	</Layout>
);

export default Loading;
