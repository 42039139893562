import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Row, Col, Steps } from 'antd';
import { useQuery } from '@apollo/client';

// partials
import { AboutForm } from '../partials/AboutForm';
import { Skills } from '../partials/Skills';
import { Expectations } from '../partials/Expectations';

//queries
import { FetchProfileQueries } from '../../Common/queries/myAccount';

//stores
import { onBoardingStore } from '../../Common/stores/onboarding.store';

//types
import { FetchProfileResponse } from '../../Common/types/candidate.type';

//auth layout
import OnBoardingLayout from '../../Common/containers/OnBoardingLayout';

//assets
import stepIcon from '../../Common/assets/tick-in-steps.svg';

const { Step } = Steps;

export const CreateCandidateProfile: FunctionComponent = () => {
	const [current, setCurrent] = useState<number>(0);
	const {
		pastResponsibilities,
		setFileList,
		setResumeUrl,
		setPastResponsibilities,
		setLocation,
		setPrimaryRole,
		setMultipleRoles,
		setSkills,
		setPreferredWorkStyle,
		setCurrentlyEmployed,
		setJobSearch,
		setStartDate,
		setExpectedSalaryAmount,
		setExpectedSalaryFrequency,
		setExpectedSalaryCurrency,
		setAnonymous,
		setSalaryFlexible,
	} = onBoardingStore((state) => state);

	const { data: profileData, loading: isloading } =
		useQuery<FetchProfileResponse>(FetchProfileQueries.FetchProfile);

	const storeProfileData = useCallback((profileData) => {
		const { fetchProfile } = profileData;

		let resumeName = fetchProfile.resumeUrl.replace(/.*?_/, '');

		setFileList([
			{
				uid: 'rc-upload-1',
				type: 'application/pdf',
				name: resumeName,
				size: 87762,
			},
		]);

		setResumeUrl(fetchProfile.resumeUrl);
		setPastResponsibilities(fetchProfile.pastResponsibilities);

		setPrimaryRole(fetchProfile.primaryRole);
		setMultipleRoles(fetchProfile.secondaryRoles);
		setLocation({
			admin_1: fetchProfile.admin1,
			admin_2: fetchProfile.admin2,
			country: fetchProfile.country,
			formatted_address: fetchProfile.location,
			locality: fetchProfile.locality,
			location_lat: fetchProfile.geoLocation.lat,
			location_lng: fetchProfile.geoLocation.lon,
		});

		const selectedSkills = fetchProfile.skills.map((skill, i) => {
			return { key: i, skill: skill.skill, noOfYears: skill.noOfYears };
		});
		setSkills(selectedSkills);
		setPreferredWorkStyle(fetchProfile.preferredWorkStyle);
		setCurrentlyEmployed(fetchProfile.currentlyEmployed ? 'yes' : 'no');
		setJobSearch(fetchProfile.jobSearchStatus);
		setStartDate(fetchProfile.startDate);
		setExpectedSalaryAmount(fetchProfile.expectedSalaryAmount);
		setExpectedSalaryCurrency(fetchProfile.expectedSalaryCurrency);
		setExpectedSalaryFrequency(fetchProfile.expectedSalaryFrequency);
		setSalaryFlexible(fetchProfile.salaryFlexible);
		setAnonymous(fetchProfile.anonymous);
	}, []);

	useEffect(() => {
		if (
			!isloading &&
			profileData !== undefined &&
			profileData.fetchProfile.isProfileComplete !== true
		) {
			storeProfileData(profileData);
		}
	}, [isloading, profileData, storeProfileData]);

	// Step related methods
	const handleNext = () => {
		if (current < 2) {
			setCurrent(current + 1);
		}
	};

	const handlePrevious = () => {
		setCurrent(current - 1);
	};

	const renderComponent = () => {
		switch (current) {
			case 0:
				return <AboutForm handleNext={handleNext} />;
			case 1:
				return (
					<Skills handleNext={handleNext} handlePrevious={handlePrevious} />
				);
			case 2:
				return <Expectations handlePrevious={handlePrevious} />;
		}
	};

	return (
		<OnBoardingLayout>
			<Row style={{ padding: '0 7px' }}>
				<Col xs={24} lg={{ span: 16, offset: 4 }}>
					<div className="onboarding-container">
						<Steps
							current={current}
							labelPlacement="vertical"
							direction="horizontal"
						>
							<Step title="About you" description="" />
							<Step title="Skills" description="" />
							<Step title="Expectations" description="" />
						</Steps>
						{renderComponent()}
					</div>
				</Col>
			</Row>
		</OnBoardingLayout>
	);
};
