import React, {
  useCallback,
  useEffect,
  useState,
  FunctionComponent,
} from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { useLocation, Link } from 'react-router-dom';
import { Row, Col, Result, Spin, Space, message, Button } from 'antd';

//types
import { Queries as myAccountQueries } from '../../Common/queries/myAccount';

//api clients
import { huntGraphQLClientWithAuth } from '../../api/apollo';

//assets
import success from '../../Common/assets/green-tick.svg';
import failed from '../../Common/assets/failed.svg';

interface HuntCandidateResendVerificationEmailInput {
  email: String;
}

const Mutations = {
  sendVerificationEmail: gql`
    mutation HuntCandidateResendVerificationEmail(
      $data: HuntCandidateResendVerificationEmailInput!
    ) {
      huntCandidateResendVerificationEmail(data: $data)
    }
  `,
};

export const HuntVerifyEmail: FunctionComponent = () => {
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const [huntCandidateResendVerificationEmail] = useMutation<{
    data: HuntCandidateResendVerificationEmailInput;
  }>(Mutations.sendVerificationEmail);

  const {
    data: profileData,
    refetch,
    loading: isLoading,
  } = useQuery(myAccountQueries.getMeAccount);

  const parsedObj = Object.fromEntries(new URLSearchParams(location.search));

  const handleVerify = useCallback(async () => {
    try {
      await huntGraphQLClientWithAuth.mutate({
        mutation: gql`
          mutation VerifyHuntCandidateEmail(
            $data: HuntCandidateVerifyEmailInput!
          ) {
            huntCandidateVerifyEmail(data: $data)
          }
        `,
        variables: {
          data: {
            huntCandidateId: parseInt(parsedObj.huntCandidateId),
            verificationToken: parsedObj.token,
          },
        },
      });
      refetch();

      setVerified(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  }, [parsedObj.huntCandidateId, parsedObj.token]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);

  const isProfileComplete =
    profileData && profileData.fetchProfile.isProfileComplete;

  const reSendVerificationEmail = async () => {
    const { fetchProfile } = profileData;

    try {
      const payload = {
        email: fetchProfile.email,
      };

      const data = await huntCandidateResendVerificationEmail({
        variables: { data: payload },
      });
      if (data) {
        message.success('Verification email sent', 2);
      }
    } catch (e) {
      e.graphQLErrors.forEach((error) => {
        message.error(
          "We couldn't resend a verification email. Please contact support.",
          3
        );
      });
    }
  };
  if (loading) {
    return (
      <div className='app-area'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Space>
            <Spin size='large' /> <h2>Verifying your email</h2>
          </Space>
        </div>
      </div>
    );
  }

  return (
    <div className='app-area'>
      <Row className='bg-c1 align-center' align='middle' justify='center'>
        <Col xs={24} lg={24} xl={12}>
          {verified ? (
            <Result
              icon={<img src={success} />}
              title={
                <h2 style={{ fontSize: 32, fontWeight: 'bold' }}>
                  Your email is verified!
                </h2>
              }
              subTitle={
                isProfileComplete ? (
                  <h6
                    style={{
                      fontSize: 16,
                      fontWeight: 'normal',
                      color: '#0C203A',
                      opacity: 1,
                    }}
                  >
                    Login to your account to see the changes
                  </h6>
                ) : (
                  <h6
                    style={{
                      fontSize: 16,
                      fontWeight: 'normal',
                      color: '#0C203A',
                      opacity: 1,
                    }}
                  >
                    Your profile in incomplete. Please fill up your information
                  </h6>
                )
              }
              extra={
                isProfileComplete
                  ? [
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: 50,
                        }}
                      >
                        <Link to='/'>
                          <Button
                            type='primary'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 226,
                              height: 50,
                              backgroundColor: '#0C4BB5',
                              opacity: 1,
                              color: '#ffff',
                            }}
                          >
                            Login into your account
                          </Button>
                        </Link>
                      </div>,
                    ]
                  : [
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: 50,
                        }}
                      >
                        <Link to='/create-profile'>
                          <Button
                            type='primary'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 226,
                              height: 50,
                              backgroundColor: '#0C4BB5',
                              opacity: 1,
                              color: '#ffff',
                            }}
                          >
                            Complete your profile
                          </Button>
                        </Link>
                      </div>,
                    ]
              }
            />
          ) : (
            <Result
              icon={<img src={failed} />}
              title={
                <h2 style={{ fontSize: 32, fontWeight: 'bold' }}>
                  We were not able to verify your email
                </h2>
              }
              subTitle={
                <h6
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: '#0C203A',
                    opacity: 1,
                  }}
                >
                  Either the link is expired or the URL you entered is invalid
                </h6>
              }
              extra={[
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 50,
                  }}
                >
                  <div style={{ margin: '10px 0' }}>
                    <Button
                      type='primary'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 226,
                        height: 50,
                        backgroundColor: '#0C4BB5',
                        opacity: 1,
                        color: '#ffff',
                      }}
                      onClick={reSendVerificationEmail}
                    >
                      Resend verification email
                    </Button>
                  </div>
                  <div style={{ margin: '10px 0' }}>
                    <Link to='/'>
                      <span
                        style={{
                          color: '#0C203A',
                          opacity: 1,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        Go back home
                      </span>
                    </Link>
                  </div>
                </div>,
              ]}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
