import React from 'react';
import { Row, Col, Space, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../Common/containers/Layout';

const CVSubmitted = () => {
	const history = useHistory();

	return (
		<Layout>
			<Row
				justify="center"
				align="middle"
				style={{ minHeight: 'calc(100vh - 100px)' }}
			>
				<Col xs={24} lg={10} xl={12}>
					<div
						style={{
							margin: '0 2rem',
							textAlign: 'center',
						}}
					>
						<Space direction="vertical" size="large">
							<p style={{ fontSize: 16, color: '#0C203A', opacity: 1 }}>
								Your CV has been submitted and our team is manually reviewing
								your application. This won't take long.
							</p>

							<p style={{ fontSize: 16, color: '#0C203A', opacity: 1 }}>
								Until then, you can browse jobs recommended for you.
							</p>
						</Space>
					</div>
					<div
						style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
					>
						<Button
							type="primary"
							size="large"
							className="primarycta"
							style={{ width: 148, height: 50 }}
							onClick={() => history.push('/')}
						>
							Browse jobs
						</Button>
					</div>
				</Col>
			</Row>
		</Layout>
	);
};
export default CVSubmitted;
