import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, message } from 'antd';
import { Formik, FormikErrors } from 'formik';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { PatchProfileResponse } from '../../Common/types/candidate.type';
import { PatchProfilePayload } from '../../Common/types/settings.type';
import { PatchProfileMutations } from '../../Common/queries/myAccount';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  phoneNumber: string;
}

interface PatchProfileInput {
  phoneNumber: string;
}

function EditPhoneNumberModal({ open, setOpen, phoneNumber }: IProps) {
  const [patchProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: PatchProfilePayload }
  >(PatchProfileMutations.CandidatePatchProfile);

  const validate = (values): FormikErrors<PatchProfileInput> => {
    const errors: FormikErrors<PatchProfileInput> = {};

    if (!values.phoneNumber) errors.phoneNumber = 'Required';
    if (values.phoneNumber.length < 10)
      errors.phoneNumber = 'Phone number must be 10 digits';

    return errors;
  };

  const submit = async (values) => {
    const payload = {
      phone: values.phoneNumber,
    };
    try {
      await patchProfile({ variables: { data: payload } });
      if (!isProfileUpdating) {
        message.success('Phone number updated Successfully', 3);
        setOpen(false);
      }
    } catch (error) {
      message.error(error.message, 3);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        phoneNumber: phoneNumber,
        country: {
          countryCode: '',
          dialCode: '',
          format: '',
          name: '',
        },
      }}
      validate={validate}
      onSubmit={submit}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <Modal
          key='edit-phone-number-modal'
          centered
          visible={open}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          <h3
            style={{
              fontSize: '24px',
              color: '#071421',
              padding: '16px 20px 0px 16px',
            }}
          >
            Edit settings
          </h3>
          <div style={{ padding: '0px 20px 0px 20px' }}>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '32px',
                }}
              >
                <Form.Item
                  validateStatus={errors.phoneNumber ? 'error' : 'success'}
                  help={errors.phoneNumber}
                  required
                >
                  <label
                    htmlFor='phoneNumber'
                    style={{
                      fontSize: '16px',
                      marginTop: '16px',
                      fontWeight: 400,
                      color: '#071421',
                      marginBottom: '8px',
                    }}
                  >
                    Phone Number
                  </label>

                  <PhoneInput
                    containerStyle={{ marginTop: '16px' }}
                    country={values.country.countryCode}
                    value={values.phoneNumber}
                    onChange={(value, country) => {
                      setFieldValue('country', country);
                      setFieldValue('phoneNumber', value);
                    }}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '24px',
                  marginTop: '30px',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                    height: '48px',
                    background: '#FFF1EB',
                    borderColor: '#FFF1EB',
                  }}
                  className='secondary-btn'
                  type='default'
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: '100%', height: '48px' }}
                  type='primary'
                  htmlType='submit'
                  loading={isProfileUpdating}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

export default EditPhoneNumberModal;
