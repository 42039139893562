import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';

import { Form, Input, Button, message, Row, Col } from 'antd';
import { Formik, FormikErrors } from 'formik';
import { Link, useHistory } from 'react-router-dom';

//layout
import Layout from '../Common/containers/AuthLayout';

// stores
import { tokenStore } from '../Common/stores/token.store';

//types
import { SignInInput, SignInResponse } from '../Common/types/auth.types';

//queries
import { SignInMutations } from '../Common/queries/auth';

//assets
import eyeInVisible from '../Common/assets/hide.svg';
import eyeVisible from '../Common/assets/show.svg';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const SignInInputInitValues: SignInInput = {
  email: '',
  password: '',
};

const SignIn: FunctionComponent = () => {
  const setToken = tokenStore((state) => state.setToken);
  const [signIn, { loading }] = useMutation<
    SignInResponse,
    { data: SignInInput }
  >(SignInMutations.CandidateSignIn);

  const history = useHistory();

  // Validate sign in form
  const validate = (values): FormikErrors<SignInInput> => {
    const errors: FormikErrors<SignInInput> = {};

    // Validate Email
    if (!values.email) {
      errors.email = 'Required';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Invalid Email Address';
    }

    // Password
    if (!values.password) {
      errors.password = 'Required';
    }
    if (values.password && values.password.length < 6) {
      errors.password = 'Your password should have at least 6 characters';
    }

    return errors;
  };

  const submitForm = async (credentials: SignInInput) => {
    try {
      const { data } = await signIn({ variables: { data: credentials } });
      setToken(data.huntCandidateSignIn.token);
      history.push('/');
    } catch (e) {
      e.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    }
  };

  return (
    <Layout>
      <div className='container'>
        <Row className='justify-center'>
          <Col xs={24} md={10} lg={8} xl={8}>
            <div className='inner-card'>
              <h3 className='heading-section'>Welcome back!</h3>
              <Formik
                initialValues={SignInInputInitValues}
                validate={validate}
                onSubmit={submitForm}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <Form onFinish={handleSubmit} layout='vertical' colon={false}>
                    <Form.Item
                      label={
                        <label style={{ fontVariant: 'normal' }}>
                          Email address
                        </label>
                      }
                      help={errors.email}
                      validateStatus={errors.email ? 'error' : undefined}
                    >
                      <Input
                        type='email'
                        name='email'
                        className='form-input'
                        value={values.email}
                        onChange={handleChange}
                        placeholder='example@email.com'
                        size='large'
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <label style={{ fontVariant: 'normal' }}>
                          Password
                        </label>
                      }
                      name='password'
                      help={errors.password}
                      validateStatus={errors.password ? 'error' : undefined}
                      style={{ marginTop: 15 }}
                    >
                      <Input.Password
                        name='password'
                        className='form-input'
                        value={values.password}
                        onChange={handleChange}
                        type='password'
                        size='large'
                        iconRender={(visible) =>
                          visible ? (
                            <img src={eyeVisible} alt='eye-visible' />
                          ) : (
                            <img src={eyeInVisible} alt='eye-invisible' />
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item colon={false} style={{ marginTop: 30 }}>
                      <Row>
                        <Button
                          type='primary'
                          htmlType='submit'
                          loading={loading}
                          size='large'
                          className='primarycta'
                          block
                        >
                          Sign in
                        </Button>
                        <div style={{ margin: '20px 0' }}>
                          {'Forgot password? '}
                          <Link
                            to='/forgot-password'
                            style={{ fontWeight: 600 }}
                          >
                            Reset here
                          </Link>
                        </div>
                      </Row>
                    </Form.Item>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={0}>
            <div
              style={{
                margin: 0,
                fontSize: 14,
                display: 'flex',
                justifyContent: 'left',
                marginLeft: 10,
              }}
            >
              New to Rooster?
              <Link
                to='/create-account'
                style={{ marginLeft: 10, fontWeight: 600 }}
              >
                &nbsp;Register now
              </Link>
            </div>
          </Col>
          <Col xs={0} lg={24}>
            <div
              style={{
                margin: '0 20px',
                fontSize: 14,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {'New to Rooster?'}
              <Link
                to='/create-account'
                style={{ marginLeft: '0.2em', fontWeight: 600 }}
              >
                &nbsp;Register now
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default SignIn;
