export function capitalizeFirstLetter(string) {
  // Split the input string into words using whitespace as the delimiter
  const words = string.split(' ');

  // Initialize an empty array to store the capitalized words
  const capitalizedWords = [];

  // Iterate through each word in the array and capitalize it
  for (const word of words) {
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedWords.push(capitalizedWord);
  }

  // Join the capitalized words back into a single string with spaces between them
  const resultString = capitalizedWords.join(' ');

  return resultString;
}

export function capitalizeEachWordInArray(array) {
  return array.map((word) => capitalizeFirstLetter(word));
}

export function formatPhoneNumber(phone: string): string {
  // Convert the number to a string
  const numStr = phone.toString();

  // Extract parts of the number
  const part1 = numStr.substring(0, 2); // First two digits
  const part2 = numStr.substring(2, 4); // Next two digits
  const part3 = numStr.substring(4, 7); // Next three digits
  const part4 = numStr.substring(7); // Remaining digits

  // Format and return the phone number
  return `+${part1} (${part2}) ${part3} ${part4}`;
}
