const optionEmploymentType = [
	{
		value: "full-time",
		label: "Full-Time",
	},
	{
		value: "internship",
		label: "Internship"
	},
	{
		value: "part-time",
		label: "Part-Time",
	},
	{
		value: "casual",
		label: "Casual",
	},
	{
		value: "contract",
		label: "Contract",
	},
	{
		value: "volunteer",
		label: "Volunteer",
	}
];

export const getEmploymentTypeString = (type: string): string => {
	const found = optionEmploymentType.find((option) => option.value === type);
	if (found) return found.label;
	return type;
};