import { gql } from '@apollo/client';

export const Queries = {
  getMeAccount: gql`
    query FetchProfile {
      fetchProfile {
        id
        firstName
        lastName
        profileUrl
        email
        phone
        isProfileComplete
        verified
      }
    }
  `,
};

export const FetchProfileQueries = {
  FetchProfile: gql`
    query FetchProfile {
      fetchProfile {
        id
        email
        firstName
        lastName
        profileUrl
        phone
        location
        country
        admin1
        admin2
        locality
        placeId
        resumeUrl
        isProfileComplete
        skills {
          skill
          noOfYears
        }
        preferredWorkStyle
        currentlyEmployed
        startDate
        jobSearchStatus
        expectedSalaryAmount
        expectedSalaryCurrency
        expectedSalaryFrequency
        salaryFlexible
        verified
        pastResponsibilities
        approved
        primaryRole
        secondaryRoles
        anonymous
        geoLocation {
          lat
          lon
        }
        jobsApplied
      }
    }
  `,
};

export const PatchProfileMutations = {
  CandidatePatchProfile: gql`
    mutation HuntCandidatePatchProfile($data: HuntCandidatePatchProfileInput!) {
      huntCandidatePatchProfile(data: $data) {
        id
        email
        firstName
        lastName
        phone
        location
        country
        admin1
        admin2
        locality
        placeId
        resumeUrl
        skills {
          skill
          noOfYears
        }
        preferredWorkStyle
        currentlyEmployed
        startDate
        jobSearchStatus
        expectedSalaryAmount
        expectedSalaryCurrency
        expectedSalaryFrequency
        salaryFlexible
        verified
        pastResponsibilities
        approved
        primaryRole
        secondaryRoles
        anonymous
        geoLocation {
          lat
          lon
        }
      }
    }
  `,
};

export const UpdateProfileMutations = {
  CandidateUpdateProfile: gql`
    mutation HuntCandidateUpdateProfile(
      $data: HuntCandidateUpdateProfileInput!
    ) {
      huntCandidateUpdateProfile(data: $data) {
        id
        firstName
        lastName
        location
        isProfileComplete
        verified
      }
    }
  `,
};
