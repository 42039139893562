import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

// assets
import fullLogo from '../assets/rooster-logo-black.svg';
import mobileLogo from '../assets/rooster_Logo_mobile.svg';

interface HeaderProps {
  children?: ReactNode;
}

export const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { children } = props;

  return (
    <header>
      <div className='container'>
        <div
          className='nav'
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Row>
            <Col xs={0} sm={0} md={0} lg={8}>
              <Link to='/'>
                <img src={fullLogo} alt='Rooster.Careers' height={30} />
              </Link>
            </Col>
            <Col xs={4} sm={6} md={6} lg={0}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to='/'>
                  <img src={mobileLogo} alt='Rooster.Careers' height={30} />
                </Link>
              </div>
            </Col>
          </Row>
          {children}
        </div>
      </div>
    </header>
  );
};
