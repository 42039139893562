import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { Formik, FormikErrors } from 'formik';

// constants
import { SIZE } from '../Common/themes';

//queries
import { ForgotPasswordMutations } from '../Common/queries/auth';

//types
import {
	ForgotPasswordInput,
	ForgotPasswordResponse,
} from '../Common/types/auth.types';

//layout
import AuthLayout from '../Common/containers/AuthLayout';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const ForgotPassword: FunctionComponent = () => {
	const history = useHistory();

	const [forgotPassword] = useMutation<
		ForgotPasswordResponse,
		{ data: ForgotPasswordInput }
	>(ForgotPasswordMutations.forgotPassword);

	const validate = (
		values: ForgotPasswordInput,
	): FormikErrors<ForgotPasswordInput> => {
		const errors: FormikErrors<ForgotPasswordInput> = {};
		if (!values.email) {
			errors.email = 'Required';
		} else if (!emailRegex.test(values.email)) {
			errors.email = 'Invalid Email Address';
		}
		return errors;
	};

	const submitForm = async (credentials: ForgotPasswordInput) => {
		try {
			await forgotPassword({ variables: { data: credentials } });
			message.success('Email sent. Please check your email.', 3);
			setTimeout(async () => {
				history.push('/sign-in');
			}, 100);
		} catch (e) {
			if (e.graphQLErrors) {
				e.graphQLErrors.forEach((error) => {
					message.error(error.message, 3);
				});
			} else {
				message.error(e.message, 3);
			}
		}
	};

	return (
		<AuthLayout>
			<div className="container">
				<Row className="justify-center">
					<Col xs={24} sm={14} md={10} lg={10} xl={8}>
						<div className="inner-card">
							<h2 className="heading-section">Reset your password?</h2>
							<p>
								{
									"Please enter your email address. We'll email you a link to reset your password."
								}
							</p>
							<Formik
								initialValues={{
									email: '',
								}}
								validate={validate}
								onSubmit={submitForm}
							>
								{({
									values,
									errors,
									handleChange,
									handleSubmit,
									isSubmitting,
								}) => (
									<Form onFinish={handleSubmit} colon={false} layout="vertical">
										<Form.Item
											label="Email"
											help={errors.email}
											validateStatus={errors.email ? 'error' : ''}
										>
											<Input
												type="email"
												name="email"
												size="large"
												className="form-input"
												value={values.email}
												onChange={handleChange}
											/>
										</Form.Item>
										<Form.Item>
											<div style={{ marginTop: 25 }}>
												<Button
													type="primary"
													size="large"
													htmlType="submit"
													loading={isSubmitting}
													className="primarycta"
													style={{
														borderRadius: 5,
														width: '100%',
													}}
												>
													Reset Password
												</Button>
											</div>
										</Form.Item>
									</Form>
								)}
							</Formik>
						</div>
					</Col>
				</Row>
			</div>
		</AuthLayout>
	);
};

export default ForgotPassword;
