import React, { FunctionComponent } from 'react';

// store
import { tokenStore } from '../../stores/token.store';

import { HeaderWithoutAuth } from './WithoutAuth';
import { HeaderWithAuth } from './WithAuth';

export const Header: FunctionComponent = () => {
	const token = tokenStore((state) => state.token);

	if (token) return <HeaderWithAuth />;

	return <HeaderWithoutAuth />;
};
