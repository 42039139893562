import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  message,
  Row,
  Col,
  Radio,
  Form,
  Select,
  Checkbox,
  Spin,
  Typography,
  InputNumber,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

//constants
import { currencies } from '../../Common/constants';

//types
import {
  CandidateUpdateProfileInput,
  CandidateUpdateProfileResponse,
} from '../../Common/types/candidate.type';

//stores
import { onBoardingStore } from '../../Common/stores/onboarding.store';

//queries
import { UpdateProfileMutations } from '../../Common/queries/myAccount';

//assets
import arrowDown from '../../Common/assets/dropdown-arrow-down.svg';

const { Option } = Select;
const { Text } = Typography;

const DEFAULT_INPUT_SIZE = 'large';
const DEFAULT_BUTTON_SIZE = 'large';

interface Props {
  handlePrevious: () => void;
}

export const Expectations: FunctionComponent<Props> = ({ handlePrevious }) => {
  const history = useHistory();
  const [isRedirect, setIsRedirect] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const {
    location,
    resumeUrl,
    primaryRole,
    multipleRoles,
    pastResponsibilities,
    skills: selectedSkills,
    preferredWorkStyle,
    currentlyEmployed,
    jobSearch,
    startDate,
    expectedSalaryAmount,
    expectedSalaryCurrency,
    expectedSalaryFrequency,
    salaryFlexible,
    anonymous,
    setPreferredWorkStyle,
    setCurrentlyEmployed,
    setJobSearch,
    setStartDate,
    setExpectedSalaryAmount,
    setExpectedSalaryCurrency,
    setExpectedSalaryFrequency,
    setSalaryFlexible,
    setAnonymous,
    resetOnboardingStore,
  } = onBoardingStore((state) => state);

  const [candidateUpdateProfile] = useMutation<
    CandidateUpdateProfileResponse,
    { data: CandidateUpdateProfileInput }
  >(UpdateProfileMutations.CandidateUpdateProfile);

  const handleFinishOnboarding = async () => {
    // removing the 'key' to match the API skills structure.
    selectedSkills.map((skill) => delete skill.key);

    try {
      const payload = {
        location: location.formatted_address,
        country: location.country,
        admin1: location.admin_1,
        admin2: location.admin_2,
        locality: location.locality,
        geoLocation: { lat: location.location_lat, lon: location.location_lng },
        resumeUrl: resumeUrl,
        skills: selectedSkills,
        preferredWorkStyle: preferredWorkStyle,
        currentlyEmployed: currentlyEmployed === 'yes' ? true : false,
        startDate: startDate,
        jobSearchStatus: jobSearch,
        expectedSalaryAmount: expectedSalaryAmount,
        expectedSalaryCurrency: expectedSalaryCurrency,
        expectedSalaryFrequency: expectedSalaryFrequency,
        salaryFlexible: salaryFlexible,
        pastResponsibilities: pastResponsibilities,
        primaryRole: primaryRole,
        secondaryRoles: multipleRoles,
        anonymous: anonymous,
      };

      const { data } = await candidateUpdateProfile({
        variables: { data: payload },
      });
      if (data.huntCandidateUpdateProfile) {
        const { verified } = data.huntCandidateUpdateProfile;
        resetOnboardingStore();
        setIsRedirect(true);

        if (!verified) {
          history.push('/email-verification');
        } else {
          setTimeout(async () => {
            history.push('/');
          }, 500);
        }
      }
    } catch (e) {
      e.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    }
  };

  const handleWorkStyle = (e) => {
    const { name, value } = e.target;

    if (name === 'preferredWorkStyle') {
      setPreferredWorkStyle(value);
    } else if (name === 'currentlyEmployed') {
      setCurrentlyEmployed(value);
    } else {
      setJobSearch(value);
    }
  };

  const step03Validation = () => {
    if (
      preferredWorkStyle &&
      jobSearch &&
      startDate &&
      expectedSalaryCurrency &&
      expectedSalaryFrequency &&
      expectedSalaryAmount > 1
    )
      return true;
    return false;
  };

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleExpectedSalary = (val) => {
    setExpectedSalaryAmount(val);
  };

  const handleSelectedCurrency = (val) => {
    setExpectedSalaryCurrency(val);
  };

  const handleSelectedSalaryFrequency = (val) => {
    setExpectedSalaryFrequency(val);
  };

  return (
    <Row className='inner-onboarding-container'>
      <Col xs={24} lg={{ span: 18, offset: 3 }}>
        <div
          style={{
            display: 'flex',
            // justifyContent: 'center',
            marginBottom: 25,
          }}
        >
          <p style={{ color: '#0C203A', opacity: 0.6 }}>
            Help companies know what you are looking for
          </p>
        </div>
      </Col>
      <Col xs={24} lg={{ span: 16, offset: 3 }}>
        <Form
          layout='vertical'
          name='expectation'
          onFinish={handleFinishOnboarding}
        >
          <Form.Item
            className='profile-radio'
            label='Preferred work style'
            style={{ marginTop: 20 }}
          >
            <Radio.Group
              className='radio-group'
              name='preferredWorkStyle'
              value={preferredWorkStyle}
              onChange={handleWorkStyle}
              style={{ marginBottom: 20 }}
            >
              <Radio value='At Office'>In-office</Radio>
              <Radio value='Flexible'>Flexible</Radio>
              <Radio value='Remote Only'>Remote only</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='Are you currently employed?'
            className='profile-radio'
            style={{ marginTop: 15 }}
          >
            <Radio.Group
              name='currentlyEmployed'
              value={currentlyEmployed}
              onChange={handleWorkStyle}
              className='radio-group'
              style={{ marginBottom: 20 }}
            >
              <Radio value='yes'>Yes</Radio>
              <Radio value='no'>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='Where are you on your job search?'
            className='profile-radio'
            style={{ marginTop: 15 }}
          >
            <Radio.Group
              name='jobSearch'
              className='rg-job-search'
              value={jobSearch}
              onChange={handleWorkStyle}
              style={{ marginBottom: 15 }}
            >
              <Radio value='active'>I'm Actively looking</Radio>
              <Radio value='casual'>Casually browsing</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='If you are asked to join by a company, when can you start?'
            className='profile'
            style={{ marginTop: 15 }}
          >
            <Select
              value={startDate}
              placeholder='Immediately'
              size={DEFAULT_INPUT_SIZE}
              onSelect={handleStartDate}
              style={{ marginBottom: 10 }}
              suffixIcon={<img className='suffixIcon' src={arrowDown} />}
            >
              <Option value='immediately'>Immediately</Option>
              <Option value='1-week'>1 Week</Option>
              <Option value='2-weeks'>2 Weeks</Option>
              <Option value='1-month'>Within a month</Option>
              <Option value='2-months'>Within 2 months</Option>
              <Option value='3-months'>Within 3 months</Option>
            </Select>
          </Form.Item>
          <Row gutter={[22, 22]}>
            <Col xs={24} lg={24} xl={12}>
              <Form.Item label='Salary expectations' className='profile'>
                <InputNumber
                  id='expectedSalaryAmount'
                  value={expectedSalaryAmount === 0 ? 1 : expectedSalaryAmount}
                  style={{ width: '100%' }}
                  size={DEFAULT_INPUT_SIZE}
                  decimalSeparator={'.'}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={handleExpectedSalary}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={12} xl={4}>
              <Form.Item label='Currency' className='profile'>
                <Select
                  showSearch
                  value={expectedSalaryCurrency}
                  onSelect={handleSelectedCurrency}
                  optionFilterProp='label'
                  size={DEFAULT_INPUT_SIZE}
                  options={currencies}
                  suffixIcon={<img className='suffixIcon' src={arrowDown} />}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={12} xl={8}>
              <Form.Item label='Paid Every' className='profile'>
                <Select
                  size={DEFAULT_INPUT_SIZE}
                  value={expectedSalaryFrequency}
                  onSelect={handleSelectedSalaryFrequency}
                  suffixIcon={<img className='suffixIcon' src={arrowDown} />}
                >
                  <Option value='hour'>Hour</Option>
                  <Option value='day'>Day</Option>
                  <Option value='week'>Week</Option>
                  <Option value='2-weeks'>2 Weeks</Option>
                  <Option value='month'>Month</Option>
                  <Option value='year'>Year</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item style={{ marginBottom: 10, marginTop: 0 }}>
              <Checkbox
                className='expect-checkbox'
                checked={salaryFlexible}
                onChange={(e) => {
                  setSalaryFlexible(e.target.checked);
                }}
                style={{
                  color: '#0C203A',
                  opacity: 1,
                  fontVariant: 'normal',
                }}
              >
                I am flexible on salary expectation
              </Checkbox>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name='anonymous'
              className='expect-checkbox'
              style={{ marginBottom: 10, marginTop: 0 }}
            >
              <Checkbox
                checked={anonymous}
                onChange={(e) => {
                  setAnonymous(e.target.checked);
                }}
              >
                <Text
                  style={{
                    color: '#0C203A',
                    opacity: 1,
                    fontVariant: 'normal',
                  }}
                >
                  Stay anonymous
                </Text>
              </Checkbox>
            </Form.Item>
          </Row>
          <Form.Item colon={false} style={{ marginTop: 40 }}>
            <Row>
              <Col
                xs={24}
                lg={24}
                xl={24}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  marginBottom: '3.5rem',
                }}
              >
                <div>
                  <Button
                    type='primary'
                    htmlType='submit'
                    size={DEFAULT_BUTTON_SIZE}
                    disabled={!step03Validation()}
                  >
                    Get started {isRedirect && <Spin indicator={antIcon} />}
                  </Button>
                </div>
                <div>
                  <Button
                    type='primary'
                    size={DEFAULT_BUTTON_SIZE}
                    ghost
                    onClick={handlePrevious}
                  >
                    Previous
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
