import React from 'react';
import { Row, Col, Skeleton } from 'antd';

export const SettingsSkeleton = () => {
	return (
		<Row>
			<Col lg={{ span: 10, offset: 7 }}>
				<div style={{ marginTop: 100 }}>
					<div style={{ marginBottom: 40 }}>
						<Skeleton.Avatar size="large" shape="circle" />
					</div>

					<div style={{ width: '500px' }}>
						{[...new Array(2)].map((value, index) => (
							<Row gutter={[8, 32]}>
								<Col>
									<div style={{ width: '500px' }}>
										<Skeleton active paragraph={{ rows: 2, width: [500] }} />
									</div>
								</Col>
							</Row>
						))}
					</div>
					<div>
						<Skeleton.Button active size="large" />
					</div>
				</div>
			</Col>
		</Row>
	);
};
