import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

// helpers
import {
  handleTokenError,
  InternalGraphQLError,
} from '../Common/helpers/error';

import { tokenStore } from '../Common/stores/token.store';

const HUNT_GRAPHQL_ENDPOINT = process.env.REACT_APP_HUNT_SERVICE || '';
const CORE_GRAPHQL_ENDPOINT = process.env.REACT_APP_CORE_GRAPHQL_API || '';

const httpLink = (uri: string) => createHttpLink({ uri });

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const tmp = [...graphQLErrors];
    handleTokenError(tmp as InternalGraphQLError[]);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = tokenStore.getState().token;
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const createClient = (uri, useAuth = true, useErrorHandling = true) =>
  new ApolloClient({
    link: ApolloLink.from([
      ...(useErrorHandling ? [errorLink] : []),
      ...(useAuth ? [authLink] : []),
      httpLink(uri),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            me: {
              merge: true,
            },
          },
        },
      },
    }),
  });

// Hunt service clients
export const huntGraphQLClientWithAuth = createClient(
  HUNT_GRAPHQL_ENDPOINT,
  true,
  true
);
export const huntGraphQLClientWithoutAuth = createClient(
  HUNT_GRAPHQL_ENDPOINT,
  false,
  false
);
// Core service clients
export const coreGraphQLClientWithAuth = createClient(
  CORE_GRAPHQL_ENDPOINT,
  true,
  true
);
export const coreGraphQLClientWithoutAuth = createClient(
  CORE_GRAPHQL_ENDPOINT,
  false,
  false
);
