import { gql } from '@apollo/client';

export const getHuntRequestQueries = {
	HuntRequests: gql`
		query FetchHuntRequests {
			fetchHuntRequests {
				id
				huntCandidate {
					id
					email
					firstName
					lastName
					phone
					location
					country
					admin1
					admin2
					locality
					placeId
					geoLocation {
						lat
						lon
					}
					resumeUrl
					skills {
						skill
						noOfYears
					}
					preferredWorkStyle
					currentlyEmployed
					startDate
					jobSearchStatus
					expectedSalaryAmount
					expectedSalaryCurrency
					expectedSalaryFrequency
					salaryFlexible
					verified
					pastResponsibilities
					primaryRole
					secondaryRoles
					anonymous
				}
				job {
					id
					title
					description
					active
					jobType
					location
					department
					createdAt
					updatedAt
					tags
					hiringManagerId
					createdBy
					remote
					formattedAddress
					country
					admin1
					admin2
					locality
					placeId
					class
					subclass
					metaApplicationVersion
					disabled
					demo
					minSalary
					maxSalary
					salaryCurrency
					salaryFrequency
					geoLocation {
						lat
						lon
					}
				}
				company {
					id
					name
					logoUrl
				}
				requestedBy
				requestedAt
				respondedAt
				status
			}
		}
	`,
};

export const updateHuntRequestAppQueries = {
	UpdateHuntRequests: gql`
		mutation updateHuntRequestApp($data: UpdateHuntRequestAppInput!) {
			updateHuntRequestApp(data: $data) {
				id
				status
			}
		}
	`,
};
