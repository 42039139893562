import React, { FunctionComponent, useState } from 'react';
import { gql } from '@apollo/client';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

import { coreGraphQLClientWithoutAuth as client } from '../../api/apollo';

const loadingSpin = (
  <LoadingOutlined style={{ fontSize: 24, marginRight: '1rem' }} spin />
);

interface UploadProps {
  disabled?: boolean;
  allowFileTypes?: string;
  onFileUpload: (fileUrl: string, fileName: string) => void;
  onFileUploadError?: (e: Error) => void;
  folder: string;
}

const Upload: FunctionComponent<UploadProps> = ({
  disabled,
  allowFileTypes,
  onFileUpload,
  onFileUploadError,
  folder,
}: UploadProps) => {
  const [loading, setLoading] = useState(false);

  const generateFileName = (file) => {
    let timestamp = new Date().getTime();
    return `${timestamp}_${file.name.replace(/ /gi, '')}`;
  };

  // Events
  const onResumeUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        setLoading(true);
        const { data } = await client.mutate({
          mutation: gql`
            mutation GetSignedUrl($data: PreSignUrlInput!) {
              getSignedUrl(data: $data) {
                signedRequest
                url
              }
            }
          `,
          variables: {
            data: {
              fileName: generateFileName(file),
              contentType: file.type,
              folder: folder ? folder : 'unknown',
            },
          },
        });

        const uploadURL = data.getSignedUrl.signedRequest;
        const fileURL = data.getSignedUrl.url;

        var options = {
          headers: {
            'Content-Type': file.type,
          },
        };

        try {
          await axios.put(uploadURL, file, options);
          if (onFileUpload) onFileUpload(fileURL, file.name);
          setLoading(false);
        } catch (uploadError) {
          if (onFileUploadError) onFileUploadError(uploadError);
          setLoading(false);
        }
      } catch (e) {
        if (onFileUploadError) onFileUploadError(e);
      }
    }
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      {loading ? loadingSpin : null}
      <input
        disabled={loading || disabled}
        className='form-input'
        style={{ flex: 1 }}
        type='file'
        accept={allowFileTypes}
        name='fileUpload'
        onChange={onResumeUpload}
      />
    </div>
  );
};

export default Upload;
