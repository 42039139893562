import { gql } from "@apollo/client";

// GraphQL
export const Queries = {
	getApplicationStatus: gql`
    query FetchApplicationStatus{
        fetchHuntCandidateApplicationStatuses {
          email
          createdAt
          candidateCommentCount
          latestStatusUpdate {
            status
            substatus
            occurredAt
          }
          job {
            id
            title
            company {
              name
              logoUrl
              id
            }
          }
          source
        }
      }
	`,
};