import React from 'react';
import editIcon from '../../Common/assets/edit.svg';

function ProfileInformationPanel({ header, values, labels, onEditClick }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '25px',
        }}
      >
        <h5 style={{ fontSize: '20px', fontWeight: 500 }}>{header}</h5>
        <div
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={onEditClick}
        >
          <img src={editIcon} alt='edit' />
          <span
            style={{
              color: '#F8793A',
              fontSize: '16px',
              fontWeight: 400,
            }}
          >
            Edit
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1rem',
          marginBottom: '20px',
          gap: '16px',
        }}
      >
        <div
          style={{
            width: '35%',
            textAlign: 'end',
            color: '#9BA1A6',
            fontSize: '16px',
            fontWeight: 200,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {labels.map((label, index) => (
              <span key={index}>
                {header === 'Skills' ? `Skill #${index + 1}` : label}
              </span>
            ))}
          </div>
        </div>
        <div
          style={{
            width: '65%',
            color: '#071421',
            fontSize: '16px',
            fontWeight: 200,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {values.map((value: any, index) => (
              <>
                {index === 4 && value !== null && value?.length > 1 ? (
                  <div style={{ display: 'flex' }}>
                    <span>{value.join(', ')}</span>
                  </div>
                ) : (
                  <span key={index}>
                    {header === 'Job preferences' && value === true ? (
                      'Employed'
                    ) : header === 'Job preferences' && value === false ? (
                      'Not employed'
                    ) : header === 'General information' && value === null ? (
                      '-'
                    ) : header === 'Skills' ? (
                      <>
                        {value.skill}
                        <div
                          style={{
                            width: '8px',
                            height: '8px',
                            background: '#CDD0D2',
                            borderRadius: '50%',
                            margin: '0 5px',
                            display: 'inline-block',
                          }}
                        />
                        {value.noOfYears} years
                      </>
                    ) : (
                      value
                    )}
                  </span>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileInformationPanel;
