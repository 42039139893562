import { gql } from "@apollo/client";

// GraphQL
export const Queries = {
    fetchRoles: gql`
              query FetchRoles($data: DesignationSearchInput!) {
                  designationSearch(data: $data) {
                      noOfHits
                      hits
                  }
              }
          `,
};
