import create from 'zustand';

type ProgressStoreState = {
	percent: number;
	setPercent: (percent: number) => void;
	RemovePercent: (percent: number) => void;
};

export const ProgressStore = create<ProgressStoreState>((set) => ({
	percent: 0,
	setPercent: (percent) =>
		set((state) => ({ percent: state.percent + percent })),
	RemovePercent: (percent) => set({ percent: 0 }),
}));
