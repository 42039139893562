import { Modal, Form, Input, Button, Select, Spin, message } from 'antd';
import { Field, Formik } from 'formik';
import React from 'react';
import deleteSkill from '../../Common/assets/delete-cross.svg';
import { PlusOutlined } from '@ant-design/icons';
import {
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../../Common/types/candidate.type';
import { useMutation } from '@apollo/client';
import { PatchProfileMutations } from '../../Common/queries/myAccount';
// import * as Yup from 'yup';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSkillsSearch: (value: string) => void;
  isSkillsLoading: boolean;
  skills: Object[];
  searchSkills: string[];
}

function EditJobPreferenceInfoModal({
  open,
  setOpen,
  handleSkillsSearch,
  isSkillsLoading,
  skills,
  searchSkills,
}: IProps) {
  const { Option } = Select;
  const handleCancel = () => {
    setSkillsList(skills);
    setOpen(false);
  };
  const [skillsList, setSkillsList] = React.useState<any>(skills);
  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  const onSubmit = async () => {
    const data = {
      skills: skillsList.map((skill: any) => ({
        skill: skill.skill,
        noOfYears: skill.noOfYears,
      })),
    };
    try {
      const response = await candidateUpdateProfile({
        variables: { data },
      });
      if (!isProfileUpdating) {
        message.success('Profile updated Successfully', 3);
      }
    } catch (error) {
      error.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    } finally {
      setOpen(false);
    }
  };
  const noOfYears = ['< 1', ...new Array(20), '20 +'].map((value, index) => (
    <Option key={index} value={value ? value : index}>
      {value ? value : index}
    </Option>
  ));
  return (
    <Modal
      key='general-info-modal'
      centered
      visible={open}
      onCancel={handleCancel}
      footer={null}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3
          style={{
            fontSize: '24px',
            color: '#071421',
            padding: '16px 20px 0px 16px',
          }}
        >
          Edit skills
        </h3>
      </div>
      <div
        style={{
          padding: '0 20px 0px 20px',
          maxHeight: '420px',
          overflow: 'auto',
        }}
      >
        {skillsList?.map((skill: any, index: number) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: '16px',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                color: '#6A7279',
                fontWeight: 200,
              }}
            >
              Skill #{index + 1}
            </span>
            <div style={{ display: 'flex' }}>
              <Select
                value={skill.skill}
                showSearch
                style={{ width: 400 }}
                placeholder='Pick a skill'
                optionFilterProp='children'
                filterOption={false}
                notFoundContent={isSkillsLoading ? <Spin size='small' /> : null}
                onSearch={(val) => {
                  handleSkillsSearch(val);
                }}
                size='middle'
                onChange={(e) => {
                  const newSkillsList = [...skillsList];
                  newSkillsList.splice(index, 1, {
                    __typename: skill.__typename,
                    skill: e,
                    noOfYears: skill.noOfYears,
                  });

                  setSkillsList(newSkillsList);
                }}
              >
                {searchSkills?.map((skill) => (
                  <Select.Option key={skill} value={skill}>
                    {skill}
                  </Select.Option>
                ))}
              </Select>

              <Select
                style={{ width: '80px', marginLeft: '10px' }}
                placeholder='No. of years'
                showArrow={false}
                onSelect={(value) => {
                  const newSkillsList = [...skillsList];
                  newSkillsList.splice(index, 1, {
                    __typename: skill.__typename,
                    skill: skill.skill,
                    noOfYears: String(value),
                  });
                  setSkillsList(newSkillsList);
                }}
                value={
                  skill.noOfYears === 0 || skill.noOfYears === ''
                    ? 1
                    : skill.noOfYears
                }
              >
                {noOfYears}
              </Select>

              {skillsList.length > 3 && (
                <Button
                  style={{
                    background: '#FFF1EB',
                    borderColor: '#FFF1EB',
                    marginLeft: '16px',
                  }}
                  className='secondary-btn'
                  type='default'
                  onClick={() => {
                    const newSkillsList = [...skillsList];
                    newSkillsList.splice(index, 1);
                    setSkillsList(newSkillsList);
                  }}
                >
                  <img
                    src={deleteSkill}
                    alt='delete'
                    style={{ stroke: '#f7671e', color: '#f7671e' }}
                  />
                </Button>
              )}
            </div>
          </div>
        ))}
        <div>
          {skillsList.length < 4 ? (
            <Button
              style={{
                fontWeight: 600,
                color: '#f7671e',
                padding: 0,
                marginTop: '15px',
              }}
              type='link'
              htmlType='submit'
              onClick={() => {
                setSkillsList([
                  ...skillsList,
                  { __typename: 'HuntCandidateSkill', skill: '' },
                ]);
              }}
            >
              <PlusOutlined
                style={{
                  fontSize: '16px',
                  color: '#f7671e',
                  strokeWidth: '30',
                  stroke: '#f7671e',
                }}
              />
              Add another skill
            </Button>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            gap: '24px',
            marginTop: '30px',
          }}
        >
          <Button
            style={{
              width: '100%',
              height: '48px',
              background: '#FFF1EB',
              borderColor: '#FFF1EB',
            }}
            className='secondary-btn'
            type='default'
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%', height: '48px' }}
            type='primary'
            loading={isProfileUpdating}
            onClick={onSubmit}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditJobPreferenceInfoModal;
