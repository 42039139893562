import create from 'zustand';
import { persist } from 'zustand/middleware';
import {
  coreGraphQLClientWithAuth,
  coreGraphQLClientWithoutAuth,
  huntGraphQLClientWithAuth,
  huntGraphQLClientWithoutAuth,
} from '../../api/apollo';

type Token = string;

type TokenStoreState = {
  token?: Token;

  setToken: (token: Token) => void;
  resetToken: () => void;
};

export const tokenStore = create<TokenStoreState>(
  persist(
    (set) => ({
      token: undefined,

      setToken: (token: Token) => {
        set({
          token,
        });
      },

      resetToken: async () => {
        set({
          token: undefined,
        });
        // Explicitly remove from local storage
        localStorage.removeItem('rooster-talent-token-store');

        await Promise.all([
          huntGraphQLClientWithAuth.cache.reset(),
          huntGraphQLClientWithoutAuth.cache.reset(),
          coreGraphQLClientWithAuth.cache.reset(),
          coreGraphQLClientWithoutAuth.cache.reset(),
        ]);
      },
    }),
    {
      name: 'rooster-talent-token-store',
    }
  )
);
