import React, { FunctionComponent } from 'react';
import { useQuery, useMutation } from '@apollo/client';

// containers
import Layout from '../../Common/containers/Layout';
import JobSearch from '../containers/JobSearch';

// partials
import Loading from '../../Common/partials/Loading';

// Queries
import { FetchProfileQueries } from '../../Common/queries/myAccount';

// types
import { FetchProfileResponse } from '../../Common/types/candidate.type';
import { JobSearchFilters } from '../types';

export const Jobs: FunctionComponent = () => {
	const {
		data: profileData,
		loading: isloading,
		refetch: refetchProfile,
	} = useQuery<FetchProfileResponse>(FetchProfileQueries.FetchProfile);

	const profile = profileData ? profileData.fetchProfile : null;

	let remoteFilter: boolean | undefined = undefined;

	if (profile) {
		if (profile.preferredWorkStyle === 'Remote Only') remoteFilter = true;
		if (profile.preferredWorkStyle === 'At Office') remoteFilter = false;
	}

	return (
		<Layout>
			{isloading ? (
				<Loading />
			) : (
				<JobSearch
					appliedForJobs={profile?.jobsApplied}
					refetchProfile={refetchProfile}
					initialFilters={
						{
							query: [profile?.primaryRole],
							limit: 20,
							page: 1,
							filters: {
								tags: [],
								remote: remoteFilter,
								location: {
									lat: profile?.geoLocation.lat,
									lon: profile?.geoLocation.lon,
									location: profile?.location,
									radius: 50,
								},
								salary: {
									min: profile?.expectedSalaryAmount,
									max: null,
									frequency: profile?.expectedSalaryFrequency,
									currency: profile?.expectedSalaryCurrency,
									includeUndeclared: true,
								},
							},
						} as JobSearchFilters
					}
				/>
			)}
		</Layout>
	);
};
