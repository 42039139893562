import React, { FunctionComponent, useCallback } from 'react';
import { debounce } from 'lodash';
import { Checkbox, Tag, Divider, Slider, Form, Row, Col, Select } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';

// types
import {
  JobSearchFacets,
  JobSearchFilters,
  SalaryRangeResults,
  JobType,
} from '../types';
import { SkillSearchResponse } from '../../Common/types/skills.type';
import { Skill, SearchInput } from '../../Common/types/onBoarding.type';

//Queries
import { Queries as CommonQueries } from '../../Common/queries/skills';
import { Queries as SalaryQueries } from '../../Common/queries/salary';

// helpers
import { getEmploymentTypeString } from '../helpers';

const { Option } = Select;

interface JobFaucetFiltersProps {
  facets: JobSearchFacets;
  filters: JobSearchFilters;
  onChange: (filters: JobSearchFilters) => void;
}

export const JobFaucetFilters: FunctionComponent<JobFaucetFiltersProps> = ({
  filters,
  facets,
  onChange,
}: JobFaucetFiltersProps) => {
  const [loadSkillSuggestions, { data: skillSuggestions }] = useLazyQuery<
    SkillSearchResponse,
    SearchInput
  >(CommonQueries.fetchSkills);

  const { data: currencyResults } = useQuery<SalaryRangeResults>(
    SalaryQueries.fetchSalaryRanges
  );

  const SearchSkills = (searchTerm: string) => {
    console.log('Debounced');
    loadSkillSuggestions({
      variables: {
        data: { searchTerm },
      },
    });
  };

  const debouncedSkillSearch = useCallback(debounce(SearchSkills, 200), []);

  let curCurrencyRangeIndex =
    currencyResults && filters.filters.salary
      ? currencyResults.fetchAllMaxSalaries.findIndex(
          (range) => range.currencyCode === filters.filters.salary.currency
        )
      : -1;
  let curCurrencyRange =
    curCurrencyRangeIndex > -1
      ? currencyResults.fetchAllMaxSalaries[curCurrencyRangeIndex]
      : null;

  const getLocalMaxSalary = (): number => {
    return filters.filters.salary.max === null
      ? curCurrencyRange.maxSalariesLocal[
          filters.filters.salary.frequency === '2-week'
            ? 'biweek'
            : filters.filters.salary.frequency
        ]
      : filters.filters.salary.max;
  };

  console.log(`REMOTE = ${filters.filters.remote}`);

  return (
    <div className='secondary-background'>
      <Form layout='vertical'>
        {filters.filters.location && !filters.filters.location.isCountry ? (
          <div>
            <div>
              <h6>Within the Radius</h6>
              <span>{filters.filters.location.radius}km</span>
              <Slider
                value={filters.filters.location.radius}
                min={10}
                max={250}
                onChange={(value) => {
                  onChange({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      location: {
                        ...filters.filters.location,
                        radius: value,
                      },
                    },
                  });
                }}
              />
            </div>
            <Divider />
          </div>
        ) : null}
        <div>
          <h6>Skills</h6>
          <Select
            style={{ width: '100%' }}
            mode='tags'
            size='large'
            value={filters.filters.tags}
            options={
              skillSuggestions
                ? skillSuggestions.skillSearch.hits.map((skill) => ({
                    label: skill,
                    value: skill,
                  }))
                : []
            }
            onSearch={(val) => {
              debouncedSkillSearch(val);
            }}
            notFoundContent={null}
            onChange={(values) => {
              onChange({
                ...filters,
                filters: {
                  ...filters.filters,
                  tags: values as string[],
                },
              });
            }}
          />
        </div>
        <Divider />
        {facets.jobType && facets.jobType.length > 0 ? (
          <div>
            <div>
              <h6>Job Type</h6>
              <Checkbox.Group
                className='vertical-checkbox-group'
                onChange={(values) => {
                  onChange({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      jobTypes: values as JobType[],
                    },
                  });
                }}
              >
                {facets.jobType.map((type, i) => (
                  <Checkbox key={i} value={type.label}>
                    <span>{getEmploymentTypeString(type.label)}</span>
                    <Tag
                      style={{
                        backgroundColor: '#F8793A',
                        color: '#ffffff',
                        border: 0,
                      }}
                    >
                      {type.count}
                    </Tag>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
            <Divider />
          </div>
        ) : null}
        {facets.modality && facets.modality.length > 0 ? (
          <div>
            <div>
              <h6>Modality</h6>
              <Checkbox.Group
                className='vertical-checkbox-group'
                value={
                  filters.filters.remote === undefined
                    ? []
                    : filters.filters.remote
                    ? ['true']
                    : ['false']
                }
                onChange={(values) => {
                  let remote = undefined;

                  if (
                    values.indexOf('true') > -1 &&
                    values.indexOf('false') > -1
                  ) {
                    remote = undefined;
                  } else {
                    if (values.indexOf('true') > -1) remote = true;
                    if (values.indexOf('false') > -1) remote = false;
                  }

                  onChange({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      remote,
                    },
                  });
                }}
              >
                {facets.modality.map((type, i) => {
                  if (type.label === 'true') {
                    return (
                      <Checkbox key={i} value={type.label}>
                        <span>Remote</span>
                        <Tag
                          style={{
                            backgroundColor: '#F8793A',
                            color: '#ffffff',
                            border: 0,
                          }}
                        >
                          {type.count}
                        </Tag>
                      </Checkbox>
                    );
                  } else {
                    return (
                      <Checkbox key={i} value={type.label}>
                        <span>In-office</span>
                        <Tag
                          style={{
                            backgroundColor: '#F8793A',
                            color: '#ffffff',
                            border: 0,
                          }}
                        >
                          {type.count}
                        </Tag>
                      </Checkbox>
                    );
                  }
                })}
              </Checkbox.Group>
            </div>
            <Divider />
          </div>
        ) : null}
        {facets.country && facets.country.length > 0 ? (
          <div>
            <div>
              <h6>Country</h6>
              <Checkbox.Group
                className='vertical-checkbox-group'
                onChange={(values) => {
                  onChange({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      country:
                        values.length > 0 ? (values[0] as string) : undefined,
                    },
                  });
                }}
              >
                {facets.country.map((type, i) => (
                  <Checkbox key={i} value={type.label}>
                    <span>{type.label ? type.label : 'Anywhere'}</span>
                    <Tag
                      style={{
                        backgroundColor: '#F8793A',
                        color: '#ffffff',
                        border: 0,
                      }}
                    >
                      {type.count}
                    </Tag>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
            <Divider />
          </div>
        ) : null}
        {filters.filters && filters.filters.salary && curCurrencyRange ? (
          <div>
            <h6>Salary</h6>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label='Currency' colon={false}>
                  <Select
                    options={
                      currencyResults
                        ? currencyResults.fetchAllMaxSalaries.map((salary) => ({
                            value: salary.currencyCode,
                            label: salary.currencyCode,
                          }))
                        : []
                    }
                    showSearch
                    value={filters.filters.salary.currency}
                    onChange={(value) => {
                      onChange({
                        ...filters,
                        filters: {
                          ...filters.filters,
                          salary: {
                            ...filters.filters.salary,
                            currency: value,
                            min: 0,
                            max: null,
                          },
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Paid Every' colon={false}>
                  <Select
                    value={filters.filters.salary.frequency}
                    onChange={(value) => {
                      onChange({
                        ...filters,
                        filters: {
                          ...filters.filters,
                          salary: {
                            ...filters.filters.salary,
                            min: 0,
                            max: null,
                            frequency: value,
                          },
                        },
                      });
                    }}
                  >
                    <Option value='hour'>Hour</Option>
                    <Option value='day'>Day</Option>
                    <Option value='week'>Week</Option>
                    <Option value='2-week'>2 Weeks</Option>
                    <Option value='month'>Month</Option>
                    <Option value='year'>Year</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Slider
              range
              min={0}
              max={
                curCurrencyRange.maxSalariesLocal[
                  filters.filters.salary.frequency
                ]
              }
              value={[filters.filters.salary.min, getLocalMaxSalary()]}
              onChange={(values) => {
                const maxValue =
                  values[1] ===
                  curCurrencyRange.maxSalariesLocal[
                    filters.filters.salary.frequency
                  ]
                    ? null
                    : values[1];

                onChange({
                  ...filters,
                  filters: {
                    ...filters.filters,
                    salary: {
                      ...filters.filters.salary,
                      min: values[0],
                      max: maxValue,
                    },
                  },
                });
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem',
              }}
            >
              <span>
                {filters.filters.salary.currency}{' '}
                {filters.filters.salary.min.toLocaleString()}
              </span>
              <span>
                {filters.filters.salary.currency}{' '}
                {getLocalMaxSalary()
                  ? getLocalMaxSalary().toLocaleString()
                  : ''}
                {filters.filters.salary.max === null ? '+' : ''}
              </span>
            </div>
            <Checkbox
              checked={filters.filters.salary.includeUndeclared}
              onChange={(e) => {
                onChange({
                  ...filters,
                  filters: {
                    ...filters.filters,
                    salary: {
                      ...filters.filters.salary,
                      includeUndeclared: e.target.checked,
                    },
                  },
                });
              }}
            >
              Include if no salary specified
            </Checkbox>
          </div>
        ) : null}
      </Form>
    </div>
  );
};
