import React, { FunctionComponent, useCallback } from 'react';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { huntGraphQLClientWithAuth } from '../../api/apollo';
import { debounce } from 'lodash';

import LocationSearchInput from '../../Common/partials/LocationSearchInput';
import CVUpload from '../../Common/partials/CVUpload';

//stores
import { onBoardingStore } from '../../Common/stores/onboarding.store';

//queries
import { Queries as CommonQueries } from '../../Common/queries/roles';

//types
import { SearchInput } from '../../Common/types/onBoarding.type';
import { DesignationSearchResponse } from '../../Common/types/designation.type';
import { capitaliseString } from '../../Common/helpers';

const { Option } = Select;

interface Props {
  handleNext: () => void;
}
const DEFAULT_INPUT_SIZE = 'large';
const DEFAULT_BUTTON_SIZE = 'large';

export const AboutForm: FunctionComponent<Props> = ({ handleNext }) => {
  const {
    resumeUrl,
    location,
    primaryRole,
    multipleRoles,
    pastResponsibilities,
    setResumeUrl,
    setLocation,
    setPrimaryRole,
    setMultipleRoles,
    setPastResponsibilities,
  } = onBoardingStore((state) => state);

  const [loadDesignationSuggestions, { data, loading: isPrimaryRoleloading }] =
    useLazyQuery<DesignationSearchResponse, SearchInput>(
      CommonQueries.fetchRoles
    );

  const handleFileUpload = (uploadedUrl) => {
    setResumeUrl(uploadedUrl);
  };

  const handleLocationSearch = (selected) => {
    setLocation(selected);
  };

  const handleDesignationSearch = (searchTerm: string) => {
    loadDesignationSuggestions({
      variables: {
        data: { searchTerm },
      },
    });
  };

  const debouncedPrimaryRoleSearch = useCallback(
    debounce(handleDesignationSearch, 200),
    []
  );

  const debouncedMultipleRoleSearch = useCallback(
    debounce(handleDesignationSearch, 200),
    []
  );

  const handleSelectedRole = (value) => {
    setPrimaryRole(capitaliseString(value));
  };

  const handleSelectedMultipleRoles = (value) => {
    setMultipleRoles(value);
  };

  const handlePastResponsibilities = (e) => {
    const { value } = e.target;
    setPastResponsibilities(value);
  };

  const step01Validation = () => {
    return (
      resumeUrl &&
      location?.formatted_address !== undefined &&
      primaryRole &&
      multipleRoles &&
      pastResponsibilities
    );
  };

  return (
    <Row className='inner-onboarding-container'>
      <Col xs={24} lg={{ span: 20, offset: 2 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          <p style={{ color: '#0C203A', opacity: 0.6 }}>
            Fill in the following information so companies know who you are and
            what you are looking for.
          </p>
        </div>
      </Col>
      <Col xs={24} lg={{ span: 16, offset: 4 }}>
        <Form layout='vertical' name='about'>
          <Form.Item
            label='Upload your CV in PDF format'
            className='profile'
            required={true}
          >
            <CVUpload
              folder='talent-cvs'
              onFileUpload={handleFileUpload}
              type='dragger'
            />
          </Form.Item>
          <Form.Item
            label='Where do you live?'
            className='profile'
            style={{ marginTop: 20 }}
            required={true}
          >
            <LocationSearchInput
              value={location?.formatted_address}
              onLocationUpdated={handleLocationSearch}
              large
            />
          </Form.Item>
          <Form.Item
            label='Your primary role'
            className='profile'
            style={{ marginTop: 20 }}
            required={true}
          >
            <Select
              loading={isPrimaryRoleloading}
              showSearch
              value={primaryRole || undefined}
              className='select-roles'
              notFoundContent={null}
              placeholder='ex: Project Manager'
              showArrow={false}
              style={{ width: '100%' }}
              onSearch={(val) => debouncedPrimaryRoleSearch(val)}
              onChange={handleSelectedRole}
              size={DEFAULT_INPUT_SIZE}
              options={
                data
                  ? data.designationSearch.hits.map((designations) => ({
                      label: designations,
                      value: designations,
                    }))
                  : []
              }
              onBlur={() => huntGraphQLClientWithAuth.clearStore()}
            />
          </Form.Item>
          <Form.Item
            label='Other roles you are open to'
            className='profile'
            style={{ marginTop: 20, marginBottom: 22 }}
          >
            <Select
              loading={isPrimaryRoleloading}
              mode='multiple'
              value={multipleRoles}
              allowClear
              notFoundContent={null}
              className='select-roles'
              style={{ width: '100%' }}
              placeholder='You can add one or more roles'
              onSearch={(val) => debouncedMultipleRoleSearch(val)}
              onChange={handleSelectedMultipleRoles}
              size={DEFAULT_INPUT_SIZE}
              options={
                data
                  ? data.designationSearch.hits.map((designations) => ({
                      label: designations,
                      value: designations,
                    }))
                  : []
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <label
                style={{ marginBottom: 0, fontWeight: 600, paddingBottom: 0 }}
              >
                Briefly tell us about your professional history
              </label>
            }
            name='pastResponsibilities'
            // style={{ marginBottom: 0 }}
            required={true}
          >
            <span
              style={{
                fontSize: 14,
                color: '#0C203A',
                paddingTop: 0,
                marginBottom: 6,
                display: 'block',
                opacity: 0.6,
                fontWeight: 300,
              }}
            >
              Your responsibilities, achievements, and things you’re generally
              proud of
            </span>
            <Input.TextArea
              value={pastResponsibilities}
              className='form-input-about'
              rows={6}
              onChange={handlePastResponsibilities}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: 40 }}>
            <Row justify='end'>
              <Col xs={24} xl={24}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '3.5rem',
                  }}
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    size={DEFAULT_BUTTON_SIZE}
                    onClick={handleNext}
                    disabled={!step01Validation()}
                  >
                    Next: add your skills
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
