import React, { FunctionComponent, useState, useCallback } from 'react';
import { Form, Button, Select, Row, Col, Typography } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

//stores
import { onBoardingStore } from '../../Common/stores/onboarding.store';

//Queries
import { Queries as CommonQueries } from '../../Common/queries/skills';

// types
import { Skill, SearchInput } from '../../Common/types/onBoarding.type';
import { SkillSearchResponse } from '../../Common/types/skills.type';
import { capitaliseString } from '../../Common/helpers';

//assets
import deleteSkill from '../../Common/assets/delete-cross.svg';

const { Option } = Select;
const { Text } = Typography;

const DEFAULT_INPUT_SIZE = 'large';
const DEFAULT_BUTTON_SIZE = 'large';

interface Props {
  handleNext: () => void;
  handlePrevious: () => void;
}

export const Skills: FunctionComponent<Props> = ({
  handleNext,
  handlePrevious,
}) => {
  const [searchedValue, setSearchedValue] = useState('');

  const { skills: selectedSkills, setSkills: setSelectedSkills } =
    onBoardingStore((state) => state);

  const [loadSkillSuggestions, { data, loading: isSkillsloading }] =
    useLazyQuery<SkillSearchResponse, SearchInput>(CommonQueries.fetchSkills);

  const handleSearch = (value) => {
    setSearchedValue(value);
  };

  const handleSkillsSearch = (searchTerm: string) => {
    loadSkillSuggestions({
      variables: {
        data: { searchTerm },
      },
    });
  };

  const debouncedSkillSearch = useCallback(
    debounce(handleSkillsSearch, 200),
    []
  );

  const step02Validation = () => {
    if (selectedSkills.length === 0) {
      return false;
    }
    if (selectedSkills.length < 3) {
      return false;
    }
    for (let obj of selectedSkills) {
      if (!obj.skill) return false;
      if (!obj.noOfYears) return false;
    }

    return true;
  };

  const handleSelectedSkill = (value, key) => {
    setSearchedValue('');

    const index = selectedSkills.findIndex((obj) => obj.key === key);

    let tempSkill = {} as Skill;

    if (index === -1 || selectedSkills.length === 0) {
      tempSkill = { key: key, skill: value, ...selectedSkills[index] };

      setSelectedSkills([...selectedSkills, tempSkill]);
    } else {
      tempSkill = { ...selectedSkills[index], skill: value };

      setSelectedSkills([
        ...selectedSkills.slice(0, index),
        tempSkill,
        ...selectedSkills.slice(index + 1, selectedSkills.length),
      ]);
    }
  };

  const handleSelectedYears = (value, key) => {
    const index = selectedSkills.findIndex((obj) => obj.key === key);

    let tempSkill = {} as Skill;

    if (index === -1 || selectedSkills.length === 0) {
      tempSkill = { key: key, ...selectedSkills[index], noOfYears: value };

      setSelectedSkills([...selectedSkills, tempSkill]);
    } else {
      tempSkill = { ...selectedSkills[index], noOfYears: value };

      setSelectedSkills([
        ...selectedSkills.slice(0, index),
        tempSkill,
        ...selectedSkills.slice(index + 1, selectedSkills.length),
      ]);
    }
  };

  const handleDeleteSkill = (key, name, remove) => {
    remove(name);

    const index = selectedSkills.findIndex((obj) => parseInt(obj.key) === key);

    const tempSelectedSkills = [
      ...selectedSkills.slice(0, index),
      ...selectedSkills.slice(index + 1, selectedSkills.length),
    ];

    const updatedSelectedSkills = tempSelectedSkills.map((skill, i) => ({
      ...skill,
      key: i.toString(),
    }));

    // setSelectedSkills([
    // 	...selectedSkills.slice(0, index),
    // 	...selectedSkills.slice(index + 1, selectedSkills.length),
    // ]);

    setSelectedSkills(updatedSelectedSkills);
  };

  const getSkills = (key, skill) => {
    const skills =
      selectedSkills[
        selectedSkills.findIndex((obj) => parseInt(obj.key) === key)
      ];
    return skills && skills[skill];
  };

  const skills =
    data &&
    data.skillSearch.hits.map((skill, i) => (
      <Option key={i} value={skill}>
        {capitaliseString(skill)}
      </Option>
    ));

  let initialSkills = [];

  if (selectedSkills.length !== 0) {
    if (selectedSkills.length > 0 && selectedSkills.length < 3) {
      const emptySkillsCount = 3 - selectedSkills.length;

      if (emptySkillsCount > 0) {
        initialSkills = [...new Array(emptySkillsCount)].map((_, i) => ({
          fieldKey: i,
          isListField: true,
          key: i,
          name: i,
        }));
      }

      initialSkills = [
        ...initialSkills,
        ...selectedSkills.map((index) => ({
          fieldKey: index,
          isListField: true,
          key: index,
          name: index,
        })),
      ];
    } else {
      initialSkills = selectedSkills.map((index) => ({
        fieldKey: index,
        isListField: true,
        key: index,
        name: index,
      }));
    }
  } else {
    initialSkills = [
      { fieldKey: 0, isListField: true, key: 0, name: 0 },
      { fieldKey: 1, isListField: true, key: 1, name: 1 },
      { fieldKey: 2, isListField: true, key: 2, name: 2 },
    ];
  }

  //Get no of years
  const noOfYears = ['< 1', ...new Array(20), '20 +'].map((value, index) => (
    <Option key={index} value={value ? value : index}>
      {value ? value : index}
    </Option>
  ));

  const isAddAnotherSkillDisabled = (fieldsLength) => {
    if (selectedSkills.length === fieldsLength) {
      for (var skill of selectedSkills) {
        const skillLength = Object.keys(skill).length;

        if (skillLength !== 3) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  return (
    <Row className='inner-onboarding-container'>
      <Col xs={24} lg={{ span: 18, offset: 3 }}>
        <div
          style={{
            display: 'flex',
            // justifyContent: 'center',
            marginBottom: 35,
            paddingBottom: 5,
          }}
        >
          <p style={{ color: '#0C203A', opacity: 0.6 }}>
            Help companies find you and understand your strengths
          </p>
        </div>
      </Col>
      <Col xs={24} lg={{ span: 18, offset: 3 }}>
        <Text style={{ fontSize: 14, fontWeight: 600, marginBottom: 10 }}>
          Your skills
        </Text>
        <Form
          name='dynamic_form_nest_item'
          autoComplete='off'
          initialValues={{
            skills: initialSkills,
          }}
          style={{ marginTop: 10 }}
        >
          <Form.List name='skills'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={key} style={{ alignItems: 'baseline' }}>
                    <Col xs={12} lg={14} xl={14} style={{ marginRight: 22 }}>
                      <Form.Item
                        className='skills'
                        {...restField}
                        name={[name, 'skill']}
                        fieldKey={[fieldKey, 'skills']}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Select
                          loading={isSkillsloading}
                          showSearch
                          size={DEFAULT_INPUT_SIZE}
                          className='select-roles'
                          notFoundContent={null}
                          style={{ width: '100%' }}
                          placeholder='Pick a skill'
                          showArrow={false}
                          options={
                            data
                              ? data.skillSearch.hits.map((skill) => ({
                                  label: skill,
                                  value: skill,
                                }))
                              : []
                          }
                          onSearch={(val) => {
                            debouncedSkillSearch(val);
                          }}
                          onSelect={(value) => handleSelectedSkill(value, key)}
                          // onFocus={handleAutoSuggestSkills}
                          defaultValue={getSkills(key, 'skill')}
                        >
                          {skills}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={8} lg={7} xl={6}>
                      <Form.Item
                        className='skills'
                        {...restField}
                        name={[name, 'years']}
                        fieldKey={[fieldKey, 'noOfYears']}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Select
                          // showSearch
                          className='select-roles'
                          placeholder='No. of years'
                          size={DEFAULT_INPUT_SIZE}
                          showArrow={false}
                          onSelect={(value) =>
                            handleSelectedYears(value.toString(), key)
                          }
                          defaultValue={getSkills(key, 'noOfYears')}
                        >
                          {noOfYears}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={1} lg={3} xl={1}>
                      <Button
                        type='text'
                        disabled={fields.length <= 3}
                        onClick={() => {
                          handleDeleteSkill(key, name, remove);
                        }}
                      >
                        <img
                          src={deleteSkill}
                          alt='deleteSkill'
                          style={{
                            fontSize: '16px',
                            color: '#0C4BB5',
                            strokeWidth: '30',
                            stroke: '#0C4BB5',
                            opacity: 0.5,
                          }}
                        />
                      </Button>
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <div style={{ float: 'left' }}>
                    <Button
                      type='link'
                      className='addSkillcta'
                      onClick={() => {
                        add();
                      }}
                      style={{
                        width: '100%',
                        paddingLeft: 0,
                      }}
                      disabled={isAddAnotherSkillDisabled(fields.length)}
                    >
                      <PlusOutlined
                        style={{
                          fontSize: '16px',
                          color: '#F8793A',
                          strokeWidth: '30',
                          stroke: '#F8793A',
                        }}
                      />

                      <Text
                        style={{
                          color: '#F8793A',
                          fontWeight: 600,
                          verticalAlign: 'text-bottom',
                        }}
                      >
                        Add another skill
                      </Text>
                    </Button>
                  </div>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Row style={{ marginTop: 20 }}>
              <Col xs={24} lg={22}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    marginBottom: '3.5rem',
                  }}
                >
                  <div style={{ marginRight: 10 }}>
                    <Button
                      type='primary'
                      onClick={handleNext}
                      disabled={!step02Validation()}
                      htmlType='submit'
                      size={DEFAULT_BUTTON_SIZE}
                    >
                      Next: your expectations
                    </Button>
                  </div>
                  <div style={{ width: '25%' }}>
                    <Button
                      type='primary'
                      ghost
                      style={{ width: '100%' }}
                      onClick={handlePrevious}
                      size={DEFAULT_BUTTON_SIZE}
                    >
                      Previous
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
