import { gql } from "@apollo/client";

// GraphQL
export const Queries = {
    fetchSalaryRanges: gql`
        query FetchSalaryRanges {
          fetchAllMaxSalaries {
            index
            countryCode
            currencyCode
            maxSalaryUSD
            currencyName
            maxSalariesLocal {
              biweek
              week
              month
              year
              week
              day
              hour
            }
            maxSalariesUSD {
              biweek
              week
              month
              year
              week
              day
              hour
            }
          }
        }
    `,
};
