import { gql } from "@apollo/client";

// GraphQL
export const Queries = {
    fetchSkills: gql`
        query FetchSkills($data: SkillSearchInput!) {
          skillSearch(data: $data) {
                noOfHits
                hits
            }
        }
    `,
};
