import React from 'react';
import { Row, Col } from 'antd';

//layout
import Layout from '../../containers/Layout';
import OnBoardingLayout from '../../containers/OnBoardingLayout';

//skeleton
import { UserProfileSkeleton } from '../../partials/Skeletons/UserProfile';
import { SettingsSkeleton } from '../../partials/Skeletons/Settings';
import { RequestSkeleton } from '../../partials/Skeletons/Requests';

type PageProps = {
	page: string;
};

const FormSkeleton = ({ page }: PageProps) => {
	const renderComponent = () => {
		switch (page) {
			case 'profile':
				return <UserProfileSkeleton />;
			case 'settings':
				return <SettingsSkeleton />;
			case 'requests':
				return <RequestSkeleton />;
		}
	};

	return <Layout>{renderComponent()}</Layout>;
};

export default FormSkeleton;
