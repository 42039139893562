import React, { FunctionComponent, ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';

// store
import { tokenStore } from '../stores/token.store';

// partials
import { FullScreen } from '../partials/FullScreen';

//types
import { Queries as myAccountQueries } from '../queries/myAccount';

interface LayoutProps {
  company?: {
    id: number;
    beta: boolean;
  };
  account?: {
    first_name: string;
    last_name: string;
    profile_url: string;
  };
  backgroundColor?: string;

  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = (props: LayoutProps) => {
  const { children } = props;
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 40, fontWeight: 800 }} spin />
  );

  const token = tokenStore((state) => state.token);
  const {
    data: profileData,
    loading: isLoading,
    error,
  } = useQuery(myAccountQueries.getMeAccount);

  // Early returns for token and error checks
  if (!token || error) {
    message.error('Your session has ended.');
    return <Redirect to='/sign-in' />;
  }

  // Early return for loading state
  if (isLoading) {
    return (
      <FullScreen>
        <div
          style={{
            display: 'flex',
            height: 'calc(100vh - 80px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin indicator={antIcon} size='large' />
        </div>
      </FullScreen>
    );
  }

  // Early returns for profile data checks
  if (profileData?.fetchProfile) {
    const { fetchProfile } = profileData;

    if (!fetchProfile.isProfileComplete)
      return <Redirect to='/create-profile' />;
    if (!fetchProfile.verified) return <Redirect to='/email-verification' />;
  } else {
    return <Redirect to='/sign-in' />;
  }

  return (
    <div className='layout' id='main-scroll-area'>
      <FullScreen>{children}</FullScreen>
    </div>
  );
};

export default Layout;
