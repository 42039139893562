import React, { FunctionComponent } from "react";
import { Header } from "../../partials/Header";

export const HeaderWithoutAuth: FunctionComponent = () => {
	return (
		<Header>
			<div style={{ height: 48, width: 1 }} />
		</Header>
	);
};
