import React, {
	ReactNode,
	FunctionComponent,
	useRef,
	useState,
	useEffect,
} from 'react';
import { Progress } from 'antd';

import { ProgressStore } from '../../Common/stores/progress.store';

interface FullScreenProps {
	children: ReactNode;
	className?: string;
}

export const FullScreen: FunctionComponent<FullScreenProps> = (
	props: FullScreenProps,
) => {
	const { children, className } = props;
	const percent = ProgressStore((state) => state.percent);
	const containerRef = useRef<HTMLDivElement>(null);
	const [topPosition, setTopPosition] = useState(80);

	useEffect(() => {
		if (containerRef !== null && containerRef.current !== null) {
			const topPosition = containerRef.current.getBoundingClientRect().top;
			setTopPosition(topPosition);
		}
	}, []);

	return (
		<>
			{percent !== 0 && (
				<Progress
					status="active"
					percent={percent}
					showInfo={false}
					size="small"
					style={{ display: 'flex', position: 'sticky', top: 0, zIndex: 100 }}
					className="progress-bar"
					strokeWidth={5}
				/>
			)}

			<div
				ref={containerRef}
				className="container"
				style={{
					height: `calc(100vh - ${topPosition}px)`,
          //overflowX: 'visible',
					//overflowY: 'scroll',
					display: 'block',
				}}
			>
				<div className="inner-container">{children}</div>
			</div>
		</>
	);
};
