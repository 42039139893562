import React, { FunctionComponent, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

import Layout from '../Common/containers/Layout';

// stores
import { tokenStore } from '../Common/stores/token.store';

const SignOut: FunctionComponent = () => {
  const client = useApolloClient();

  useEffect(() => {
    // Reset token
    // tokenStore().resetToken();
    tokenStore.getState().resetToken();

    // Clear Apollo store
    client.clearStore();

    // Shutdown Intercom
    window['Intercom']('shutdown');

    setTimeout(() => {
      window.location.href = '/sign-in';
    }, 1000);
  }, [client]);

  return (
    <Layout>
      <p>Logging out, please wait...</p>
    </Layout>
  );
};

export default SignOut;
