import React, { FunctionComponent } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Row, Col, Card, Avatar, Tag } from 'antd';

// clients
import { coreGraphQLClientWithAuth } from '../../api/apollo';

// containers
import Layout from '../../Common/containers/Layout';

// partials
import Loading from '../../Common/partials/Loading';
import { JobStatus } from '../partials/JobStatus';

// Queries
import { Queries } from '../../Common/queries/status';

// types
import { Candidate } from '../../Common/types/candidate.type';
/*
import { FetchProfileResponse } from '../../Common/types/candidate.type';
import { JobSearchFilters } from '../types';
*/

interface FetchApplicationStatusResponse {
  fetchHuntCandidateApplicationStatuses: Partial<Candidate>[];
}

export const ApplicationStatus: FunctionComponent = () => {
  const {
    data: statusData,
    loading: isloading,
    refetch,
  } = useQuery<FetchApplicationStatusResponse>(Queries.getApplicationStatus, {
    client: coreGraphQLClientWithAuth,
  });

  console.log(statusData);

  if (isloading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      <Row>
        <Col xs={0} lg={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 2px',
              margin: '20px 0',
            }}
          >
            <div style={{ fontSize: '18px' }}>
              {statusData.fetchHuntCandidateApplicationStatuses.length}{' '}
              Applications
            </div>
          </div>
        </Col>
        <Col xs={24} lg={0}>
          <div
            style={{
              fontSize: '18px',
              alignItems: 'center',
              padding: '0 2px',
              margin: '20px 0 0',
            }}
          >
            {statusData.fetchHuntCandidateApplicationStatuses.length}{' '}
            Applications
          </div>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col flex={1}>
          {statusData.fetchHuntCandidateApplicationStatuses.map(
            (candidate, i) => (
              <Row style={{ marginBottom: '1rem' }} key={i}>
                <Col flex={1}>
                  <JobStatus candidate={candidate} />
                </Col>
              </Row>
            )
          )}
        </Col>
      </Row>
    </Layout>
  );
};
