import React, { FunctionComponent, ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

// store
import { tokenStore } from '../stores/token.store';

// partials
import { FullScreen } from '../partials/FullScreen';

//types
import { Queries as myAccountQueries } from '../queries/myAccount';

interface LayoutProps {
	company?: {
		id: number;
		beta: boolean;
	};
	account?: {
		first_name: string;
		last_name: string;
		profile_url: string;
	};
	backgroundColor?: string;

	children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = (props: LayoutProps) => {
	const { children } = props;

	const token = tokenStore((state) => state.token);

	const { data: profileData, loading: isloading } = useQuery(
		myAccountQueries.getMeAccount,
	);

	const antIcon = (
		<LoadingOutlined style={{ fontSize: 40, fontWeight: 800 }} spin />
	);

	if (!token) {
		return <Redirect to="/sign-in" />;
	}

	if (isloading) {
		return (
			<div
				style={{
					display: 'flex',
					height: 'calc(100vh - 80px)',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Spin indicator={antIcon} size="large" />
			</div>
		);
	}
	const { fetchProfile } = profileData;

	if (fetchProfile.isProfileComplete) {
		return <Redirect to="/application" />;
	}

	return (
		<div className="layout" style={{ backgroundColor: '#FFFFFF', opacity: 1 }}>
			<FullScreen>{children}</FullScreen>
		</div>
	);
};

export default Layout;
