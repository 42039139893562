import axios from "axios";

const baseURL = () => {
	const apiUrl = process.env.REACT_APP_JOB_SEARCH_HOST
		? process.env.REACT_APP_JOB_SEARCH_HOST
		: "https://staging-api.rooster.jobs/jobSearch/";
	return apiUrl;
};

const getInstance = () => {
	return axios.create({
		baseURL: baseURL(),
	});
};

const handleResponse = (response) => {
	return {
		body: response && response.data ? response.data.body : null,
		errors: response && response.data ? response.data.errors : {},
		status: response && response.status ? response.status : 500,
		message: response && response.data ? response.data.message : "",
	};
};

const handleError = (errorObject) => {
	console.log(errorObject);
	const data = errorObject.response
		? errorObject.response.data
		: { data: { status: 500, message: errorObject.message } };
	throw new Error(data.message);
};

export const RoosterJobSearch = {
	searchJobs: (filters: unknown) => {
		let url = `${baseURL()}jobs/search`;
		return getInstance().post(url, filters).then(handleResponse).catch(handleError);
	},
}