import React, { useState, FunctionComponent } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import axios from 'axios';
import { Button, message, Row, Col, Typography, Upload } from 'antd';

//store
import { onBoardingStore } from '../../Common/stores/onboarding.store';
import { ProgressStore } from '../../Common/stores/progress.store';

//types
import { RcFile } from 'antd/lib/upload';

//assets
import uploadIcon from '../assets/document-upload.svg';
import {
  FetchProfileResponse,
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../types/candidate.type';
import {
  FetchProfileQueries,
  PatchProfileMutations,
} from '../queries/myAccount';

const { Dragger } = Upload;
const { Text } = Typography;

const generateFileName = (file) => {
  let timestamp = new Date().getTime();
  return `${timestamp}_${file.name.replace(/ /gi, '')}`;
};

const Mutations = {
  getSignedUrl: gql`
    mutation GetSignedUrl($data: GetSignedUrlInput!) {
      getSignedUrl(data: $data) {
        signedRequest
        url
      }
    }
  `,
};

interface GetSignedUrlInput {
  fileName: string;
  contentType: string;
  folder: string;
}

interface GetSignedUrlResponse {
  getSignedUrl: {
    url: string;
    signedRequest: string;
  };
}

interface CVUploadProps {
  folder: string;
  onFileUpload: (url: string) => void;
  type: 'dragger' | 'icon';
}

const DEFAULT_BUTTON_SIZE = 'large';

const CVUpload: FunctionComponent<CVUploadProps> = (props: CVUploadProps) => {
  const { fileList, setFileList, resetFile } = onBoardingStore(
    (state) => state
  );
  const { setPercent, RemovePercent } = ProgressStore((state) => state);

  const [isUploading, setIsUploading] = useState(false);

  const [getSignedUrl] = useMutation<
    GetSignedUrlResponse,
    { data: GetSignedUrlInput }
  >(Mutations.getSignedUrl);

  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  const {
    data: profileData,
    loading: isloading,
    refetch,
  } = useQuery<FetchProfileResponse>(FetchProfileQueries.FetchProfile);

  const updateCV = async (fileURl: string) => {
    const payload = {
      resumeUrl: fileURl,
    };

    try {
      await candidateUpdateProfile({
        variables: { data: payload },
      });
      if (!isProfileUpdating) {
        refetch();
        message.success('Resume Uploaded');
      }
    } catch (e) {
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((error) => {
          message.error(error.message, 3);
        });
      } else {
        message.error(e.message, 3);
      }
    }
  };

  const customRequest = async (e: {
    file: RcFile;
    onSuccess: (arg0: { [key: string]: unknown }, arg1: RcFile) => void;
    onError: (error: Error, response?: any, file?: RcFile) => void;
    onProgress: (response: object, file: RcFile) => void;
  }) => {
    const file = e.file;

    if (file.type !== 'application/pdf') {
      e.onError(new Error('Only PDF files are allowed.'), null, file);
      resetFile();
      message.error('Only PDF files are allowed.', 4);
      return;
    }

    setIsUploading(true);

    const uploadingFile = {
      uid: file.uid,
      type: file.type,
      name: file.name,
      size: file.size,
    };

    setFileList([uploadingFile]);

    if (file) {
      try {
        const resp = await getSignedUrl({
          variables: {
            data: {
              fileName: generateFileName(file),
              contentType: file.type,
              folder: props.folder,
            },
          },
        });
        setPercent(10);
        const uploadURL = resp.data.getSignedUrl.signedRequest;
        const fileURL = resp.data.getSignedUrl.url;

        const options = {
          headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read',
          },
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setPercent(percent);
            if (percent === 100) {
              setTimeout(() => setPercent(0), 1000);
            }
            e.onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          },
        };

        await axios.put(uploadURL, file, options);

        e.onSuccess({}, file);
        RemovePercent(0);
        message.destroy();
        if (props.onFileUpload) {
          props.onFileUpload(fileURL);
          await updateCV(fileURL);
        }

        setIsUploading(false);
      } catch (err) {
        message.error(err.message, 4);
        setIsUploading(false);
      }
    }
  };

  return (
    <>
      {props.type === 'dragger' ? (
        <>
          <Dragger
            accept={'application/pdf'}
            customRequest={customRequest}
            className="dragger"
            multiple={false}
            height={140}
            fileList={
              fileList.length !== 0 && [
                {
                  uid: fileList.length !== 0 && fileList[0].uid,
                  type: fileList.length !== 0 && fileList[0].type,
                  name: fileList.length !== 0 && fileList[0].name,
                  size: fileList.length !== 0 && fileList[0].size,
                },
              ]
            }
          >
            <Row justify="center">
              <Col xs={0} xl={24}>
                <div
                  style={{
                    padding: '1.6rem 0rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 5,
                  }}
                >
                  <div style={{ marginBottom: 5 }}>
                    <span
                      style={{
                        color: '#9EA6B0',
                        opacity: 1,
                        fontSize: 16,
                        fontWeight: 300,
                      }}
                    >
                      Drop your files here or &nbsp;
                    </span>
                    <span
                      style={{
                        fontWeight: 600,
                        color: '#F8793A',
                        fontSize: 16,
                      }}
                    >
                      browse
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: '#9EA6B0',
                        opacity: 1,
                        fontSize: 14,
                        fontWeight: 300,
                      }}
                    >
                      Maximum file size: 10MB
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={0}>
                <div
                  style={{
                    padding: '1.6rem 0rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 5,
                  }}
                >
                  <div style={{ marginBottom: 5 }}>
                    <span
                      style={{
                        fontWeight: 600,
                        color: '#0C4BB5',
                        fontSize: 16,
                      }}
                    >
                      browse
                    </span>
                    <span
                      style={{
                        color: '#9EA6B0',
                        opacity: 1,
                        fontSize: 16,
                        fontWeight: 300,
                        fontVariant: 'normal',
                      }}
                    >
                      &nbsp; from files
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: '#9EA6B0',
                        opacity: 1,
                        fontSize: 14,
                        fontWeight: 300,
                      }}
                    >
                      Maximum file size: 10MB
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Dragger>
        </>
      ) : (
        <Upload
          accept={'application/pdf'}
          multiple={false}
          customRequest={customRequest}
          showUploadList={false}
        >
          <Button
            type="link"
            icon={<img src={uploadIcon} />}
            size={DEFAULT_BUTTON_SIZE}
            ghost
            className="btnUpload"
            loading={isUploading}
            style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}
          >
            <Text style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>
              Upload new
            </Text>
          </Button>
        </Upload>
      )}
    </>
  );
};

export default CVUpload;
