import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Row, Col, Button, message, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

//types
import { Queries as myAccountQueries } from '../queries/myAccount';

//assets
import verifyEmail from '../assets/verifyEmail.svg';

interface HuntCandidateResendVerificationEmailInput {
	email: String;
}

const Mutations = {
	sendVerificationEmail: gql`
		mutation HuntCandidateResendVerificationEmail(
			$data: HuntCandidateResendVerificationEmailInput!
		) {
			huntCandidateResendVerificationEmail(data: $data)
		}
	`,
};

const EmailVerification: FunctionComponent = () => {
	const [counter, setCounter] = useState(60);
	const [loading, setLoading] = useState(false);

	const timer = useRef(null);

	const [huntCandidateResendVerificationEmail] = useMutation<{
		data: HuntCandidateResendVerificationEmailInput;
	}>(Mutations.sendVerificationEmail);

	const {
		data: profileData,
		loading: isLoading,
		refetch,
	} = useQuery(myAccountQueries.getMeAccount);

	useEffect(() => {
		refetch();
	}, []);

	const startCounter = () => {
		if (!timer.current) {
			timer.current =
				counter > 0 &&
				setInterval(() => setCounter((prevCount) => prevCount - 1), 1000);
		} else {
			clearInterval(timer.current);
			timer.current = null;
			setCounter(60);
		}
	};
	if (counter == 0) {
		startCounter();
	}

	if (isLoading) {
		return (
			<div className="app-area">
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<Spin />
				</div>
			</div>
		);
	}

	if (profileData && profileData.fetchProfile.verified) {
		return <Redirect to="/" />;
	}

	const onResendVerification = async () => {
		const { fetchProfile } = profileData;
		startCounter();
		setLoading(true);
		try {
			const payload = {
				email: fetchProfile.email,
			};

			const data = await huntCandidateResendVerificationEmail({
				variables: { data: payload },
			});
			if (data) {
				setLoading(false);
				message.success('Verification email sent', 2);
			}
		} catch (e) {
			e.graphQLErrors.forEach((error) => {
				message.error(
					"We couldn't resend a verification email. Please contact support.",
					3,
				);
			});
		}
	};

	return (
		<div className="app-area">
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					// padding: '2rem 0rem',
				}}
			>
				<div
					style={{
						maxWidth: 500,
					}}
				>
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							textAlign: 'center',
							flexDirection: 'column',
						}}
					>
						<img
							src={verifyEmail}
							alt="verify"
							style={{
								maxWidth: 300,
							}}
						/>
						<h2 style={{ padding: '0.6rem 0rem' }}>Please Verify Your Email</h2>
						<div style={{ padding: '0.6rem 1rem' }}>
							<p
								style={{
									marginBottom: 2,
								}}
							>
								You've submitted your application successfully!
							</p>
							<p
								style={{
									marginBottom: 10,
								}}
							>
								Please click on the verification link we've sent to you so we
								know you're you. Please make sure you check your spam folder.
							</p>
						</div>
						<Row
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: 20,
							}}
						>
							<Col>
								<Button
									type="primary"
									size="large"
									disabled={counter !== 60 && counter !== 0}
									loading={loading}
									onClick={onResendVerification}
								>
									Resend Verification Email{' '}
									{counter !== 60 && counter !== 0 ? 'in ' + counter : ''}
								</Button>
							</Col>
							<Col>
								<div
									style={{
										margin: '20px 0',
										fontSize: 14,
										display: 'flex',
										justifyContent: 'left',
									}}
								>
									having trouble with your account?
									<Link to="/sign-out" style={{ fontWeight: 600 }}>
										&nbsp;Sign out
									</Link>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailVerification;
