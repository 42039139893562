import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const LocationSearchInput = ({ onLocationUpdated, value }) => {
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [previousSelectedPlace, setPreviousSelectedPlace] = useState(null);
  // Save Cache
  const saveCachedResults = (query, results) => {
    const item = {
      timestamp: new Date().getTime(),
      data: results,
    };
    localStorage.setItem(
      `cachedLocationResults:${query}`,
      JSON.stringify(item)
    );
    clearExpiredCachedResults();
  };
  // Get Cache
  const getCachedResults = (query) => {
    const cachedResults = localStorage.getItem(
      `cachedLocationResults:${query}`
    );
    return cachedResults ? JSON.parse(cachedResults) : null;
  };
  // Delete Cache if they are 7 days old
  const clearExpiredCachedResults = () => {
    const keys = Object.keys(localStorage);
    const now = new Date().getTime();
    keys.forEach((key) => {
      if (key.startsWith("cachedLocationResults:")) {
        const cachedResult = JSON.parse(localStorage.getItem(key));
        const cachedTimestamp = cachedResult.timestamp;
        const ageInDays = (now - cachedTimestamp) / (1000 * 60 * 60 * 24);
        if (ageInDays > 7) {
          localStorage.removeItem(key);
        }
      }
    });
  };
  //
  useEffect(() => {
    if (value === "") {
      setSelectedPlace(null);
    }
  }, [value]);
  //
  useEffect(() => {
    if (value !== "" && selectedPlace === null) {
      setSelectedPlace({ label: value });
    }
  }, [value]);

  console.log(selectedPlace, previousSelectedPlace);
  const isCountry = (place) => {
    if (place && place.value && place.value.types) {
      return place.value.types.includes("country");
    }
    console.log(place);
    return false;
  };
  //
  const handlePlaceChange = async (place) => {
    setSelectedPlace(place);

    if (place && place.label) {
      const cachedResults = getCachedResults(place.label);
      if (cachedResults) {
        onLocationUpdated({
          lat: cachedResults.data.lat,
          lon: cachedResults.data.lng,
          radius: 50,
          formatted_address: place.label,
          isCountry: isCountry(place),
        });
      } else if (!isCountry(place)) {
        try {
          const results = await geocodeByAddress(place.label);
          const { lat, lng } = await getLatLng(results[0]);
          saveCachedResults(place.label, { lat, lng });

          onLocationUpdated({
            lat: lat,
            lon: lng,
            radius: 50,
            formatted_address: place.label,
            isCountry: isCountry(place),
          });
        } catch (error) {
          console.error("Error geocoding address:", error);
        }
      } else {
        // If the place is a country, call onLocationUpdated without lat and lon
        onLocationUpdated({
          formatted_address: place.label,
          isCountry: isCountry(place),
        });
      }
    }
  };

  const handleInputFocus = () => {
    setPreviousSelectedPlace(selectedPlace);
    setSelectedPlace(null);
  };
  const handleInputBlur = () => {
    if (!selectedPlace && value) {
      setSelectedPlace(previousSelectedPlace);
    }
  };
  //
  return (
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_API_KEY}
      selectProps={{
        value: selectedPlace,
        onChange: handlePlaceChange,
        placeholder: "Anywhere",
        className: "ant-input ant-input-lg",
        styles: {
          menu: (provided, state) => ({
            ...provided,
            display:
              state.selectProps.options &&
              state.selectProps.options.length === 0
                ? "none"
                : "block",
          }),
          control: (provided, state) => ({
            ...provided,
            background: "none",
            borderColor: state.isFocused ? "" : "",
            borderWidth: "0px",
            boxShadow: state.isFocused ? "0 0 0 0px" : "",
            "&:hover": {
              borderColor: state.isFocused ? "" : "",
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#CED2D7",
          }),
          input: (provided) => ({
            ...provided,
            color: "#585958",
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            display: "none",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            display: "none",
          }),
          separator: (provided) => ({
            ...provided,
            display: "none",
          }),
        },
        onMenuOpen: handleInputFocus,
        onMenuClose: handleInputBlur,
      }}
      withSessionToken={true}
      minLengthAutocomplete={3}
      debounce={400}
      autocompletionRequest={{
        componentRestrictions: { country: [] }, // No restriction by default
        types: ["(regions)"], // Restricts results to regions (countries, cities, etc.)
      }}
    />
  );
};

export default LocationSearchInput;
