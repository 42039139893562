import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";

// stores
import { tokenStore } from "./Common/stores/token.store";

async function main(){
	// This fixes zustand persit bug where there's a delay in reading from local storage
	// This delay was causing AuthLayouts to break since the first render wouldn't have user or tokens in the AuthStore
	const tokenFromStorage = localStorage.getItem("rooster-talent-token-store");

	if (tokenFromStorage) {
		const parsed = JSON.parse(tokenFromStorage);
		if (parsed && parsed.state && parsed.state.token) {
			tokenStore.setState({ token: parsed.state.token });
		}
	}

	ReactDOM.render(<Routes />, document.getElementById("root"));
}

main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
