import React, { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import useOnclickOutside from 'react-cool-onclickoutside';

import { Link, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Dropdown,
  Avatar,
  Menu,
  Typography,
  Drawer,
  Divider,
} from 'antd';
import { Fade as Hamburger } from 'hamburger-react';

import logout from '../../assets/logout.svg';
import settings from '../../assets/setting.svg';

import { Header } from '../../partials/Header';

//queries
import { Queries } from '../../queries/myAccount';

//assets
import arrowDown from '../../assets/arrow-down.svg';
import arrowUp from '../../assets/arrow-up.svg';
import useRefreshStore from '../../stores/refresh.store';

const { Text } = Typography;

interface FetchProfile {
  fetchProfile: {
    id: number;
    firstName: string;
    lastName: string;
    profileUrl?: string;
    email: string;
    isProfileComplete: boolean;
    verified: boolean;
  };
}

export const HeaderWithAuth: FunctionComponent = () => {
  const [toggleMobileNav, setToggleMobileNav] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { pathname } = useLocation();

  const style = {
    transform: isDropdownVisible ? 'rotate(-180deg)' : '',
    transition: 'transform 500ms ease', // smooth transition
  };

  const checkIsActive = (route) => {
    return pathname === route;
  };

  const { error, data, loading, refetch } = useQuery<FetchProfile>(
    Queries.getMeAccount
  );
  const refreshState = useRefreshStore((state) => state.refreshState);

  useEffect(() => {
    refetch();
  }, [refreshState]);

  const menuItems = [
    {
      route: '/',
      label: 'Jobs',
      isEnabled: true,
    },
    {
      route: '/requests',
      label: 'Requests',
      isEnabled: true,
    },
    {
      route: '/status',
      label: 'Applications',
      isEnabled: true,
    },
    {
      route: '/application',
      label: 'Profile',
      isEnabled: data && data.fetchProfile.isProfileComplete,
    },
  ];

  const settingsMenu = ({ beta }) => (
    <Menu style={{ backgroundColor: '#fff' }}>
      <Menu.Item className='desktop-nav'>
        <Link to='/settings' onClick={toggleChange}>
          <img
            src={settings}
            alt='logout'
            style={{ fontSize: '16px', color: '#F8793A' }}
            width={18}
          />
          <span style={{ padding: 10, color: '#071421' }}>Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item className='desktop-nav'>
        <Link to='/sign-out' onClick={toggleChange}>
          <img
            src={logout}
            alt='logout'
            style={{ fontSize: '16px', color: '#F8793A' }}
            width={18}
          />
          <span style={{ padding: 10, color: '#071421' }}>Sign out</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const ref = useOnclickOutside(() => {
    setIsDropdownVisible(!isDropdownVisible);
  });

  const toggleMobileNavChange = () => {
    setToggleMobileNav(!toggleMobileNav);
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const toggleChange = () => {
    setToggle(false);
    setIsDropdownVisible(false);
  };

  const handleDropDownClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  if (loading || !data) return <Header />;
  if (error) return <h4>Error</h4>;
  const { fetchProfile } = data;

  return (
    <Header>
      <Row
        style={{
          width: '100%',
        }}
      >
        <Col xs={{ span: 24 }} lg={0}>
          {fetchProfile.verified ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ margin: '0 7px' }}>
                <Hamburger
                  toggled={isMobileMenuVisible}
                  toggle={toggleMobileNavChange}
                  color='#071421'
                  direction='right'
                  rounded={false}
                  size={20}
                />
              </div>
              <div>
                <Avatar
                  size={35}
                  src={fetchProfile.profileUrl ? fetchProfile.profileUrl : null}
                >
                  {fetchProfile.firstName.length > 0
                    ? fetchProfile.firstName[0]
                    : ''}
                </Avatar>
              </div>
            </div>
          ) : (
            <div style={{ height: 48, width: 1 }} />
          )}
        </Col>

        <Col xs={0} lg={{ span: 24 }}>
          {fetchProfile.verified ? (
            <Row justify={'end'}>
              <Col>
                <ul style={{ justifyContent: 'flex-start', marginRight: 25 }}>
                  {menuItems.map((item, i) => (
                    <li
                      key={i}
                      style={{
                        // color: `${checkIsActive(item.route) ? 'red' : 'green'}`,
                        pointerEvents: item.isEnabled ? 'auto' : 'none',
                        opacity: item.isEnabled ? 1 : 0.6,
                        padding: '0 4px',
                      }}
                    >
                      <Link
                        to={item.route}
                        className={
                          checkIsActive(item.route) ? 'active' : 'inactive'
                        }
                      >
                        {item.label}{' '}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col onClick={handleDropDownClick}>
                {/* <Row ref={ref}> */}
                <Row>
                  <Col xs={0} lg={24}>
                    <Row>
                      <Col
                        style={{
                          padding: '0 7px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: '#FFF1EB',
                            color: '#F8793A',
                          }}
                          size={35}
                          src={
                            fetchProfile.profileUrl
                              ? fetchProfile.profileUrl
                              : null
                          }
                        >
                          {fetchProfile.firstName.length > 0
                            ? fetchProfile.firstName[0]
                            : ''}
                        </Avatar>
                      </Col>
                      <Col>
                        <Row
                          style={{
                            display: 'flex',
                          }}
                        >
                          <Col
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Text
                              style={{
                                display: 'inline-block',
                                color: '#071421',
                              }}
                            >
                              {`${fetchProfile.firstName} ${fetchProfile.lastName}`}
                            </Text>
                            <Text
                              type='secondary'
                              style={{
                                color: '#9BA1A6',
                                fontSize: 12,
                                display: 'inline-block',
                              }}
                            >
                              {fetchProfile.email}
                            </Text>
                          </Col>
                          <Col
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Dropdown
                              placement='bottomRight'
                              overlay={settingsMenu({ beta: true })}
                              trigger={['click']}
                              visible={isDropdownVisible}
                              className='profile-dropdown'
                            >
                              <a
                                href=' '
                                style={{
                                  pointerEvents: fetchProfile.isProfileComplete
                                    ? 'auto'
                                    : 'none',
                                  opacity: fetchProfile.isProfileComplete
                                    ? 1
                                    : 0.6,
                                }}
                              >
                                {/* {!isDropdownVisible ? (
																	<img src={arrowDown} alt="arrow-down"  />
																) : (
																	<img src={arrowUp} alt="arrow-up" />
																)} */}
                                <img
                                  src={arrowDown}
                                  alt='arrow-down'
                                  style={style}
                                />
                              </a>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <div style={{ height: 40, width: 1 }} />
          )}
        </Col>
      </Row>
      <Drawer
        placement='top'
        closable={false}
        visible={isMobileMenuVisible}
        style={{
          top: isMobileMenuVisible ? 56 : 0,
          zIndex: 0,
        }}
        maskClosable={isMobileMenuVisible}
        onClose={toggleMobileNavChange}
        drawerStyle={{ backgroundColor: 'white' }}
        className='drawer-style'
      >
        <Row>
          <Col>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                listStyle: 'none',
                padding: 0,
                marginBottom: 0,
                fontWeight: 400,
              }}
            >
              {menuItems.map((item, i) => (
                <li
                  key={i}
                  style={{
                    pointerEvents: item.isEnabled ? 'auto' : 'none',
                    opacity: item.isEnabled ? 1 : 0.6,
                    padding: '6px 0',
                    fontWeight: 400,
                  }}
                >
                  <Link
                    to={item.route}
                    onClick={toggleMobileNavChange}
                    className={
                      checkIsActive(item.route) ? 'active' : 'inactive'
                    }
                    style={{
                      color: `${
                        checkIsActive(item.route) ? '#F8793A' : '#071421'
                      }`,
                    }}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Divider
          className='divider-style'
          style={{ backgroundColor: '#6A7279', opacity: '0.2' }}
        />
        <Row>
          <Col>
            <div style={{ marginBottom: 16 }}>
              <Link
                to='/settings'
                onClick={toggleMobileNavChange}
                style={{ color: '#071421' }}
              >
                <img
                  src={settings}
                  alt='logout'
                  style={{ fontSize: '16px', color: '#071421' }}
                  width={18}
                />
                <span style={{ padding: 10 }}>Settings</span>
              </Link>
            </div>
            <div>
              <Link
                to='/sign-out'
                onClick={toggleMobileNavChange}
                style={{ color: '#071421' }}
              >
                <img
                  src={logout}
                  alt='logout'
                  style={{ fontSize: '16px', color: '#ffffff' }}
                  width={18}
                />
                <span style={{ padding: 10 }}>Sign out</span>
              </Link>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Drawer>
    </Header>
  );
};
