// src/routes.js

import React, { FunctionComponent } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ApolloProvider } from '@apollo/client';

// Styles
import './Common/styles/App.less';

// import Rooster from "./api/rooster";

// Onboarding Routes
import GetStarted from './Onboarding/pages/GetStarted';
import { SignUp } from './Onboarding/pages/SignUp';
import { CreateCandidateProfile } from './Onboarding/pages/CreateCandidateProfile';

// Authentication Routes
import SignIn from './Auth/SignIn';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import UpdatePassword from './Auth/UpdatePassword';
import SignOut from './Auth/SignOut';
import { HuntVerifyEmail } from '../src/Onboarding/pages/hunt-verify-email';
import EmailVerification from '../src/Common/partials/EmailVerification';
import CVSubmitted from '../src/Requests/pages/cvSubmitted';

//Jobs
import { Jobs } from './Jobs/pages/Jobs';
import { ApplicationStatus } from './Status/pages/ApplicationStatus';

//Requests
import Requests from './Requests/pages/requests';

//User Profile
import UserProfile from '../src/Profile/pages/UserProfile';
import Settings from '../src/Profile/partials/settings';

// Container
import { Header } from './Common/containers/Headers';

import Page404 from '../src/Common/partials/404-page';

import { huntGraphQLClientWithAuth } from './api/apollo';

const history = createBrowserHistory();

const MainRoutes: FunctionComponent = () => {
  return (
    <ApolloProvider client={huntGraphQLClientWithAuth}>
      <Router history={history} forceRefresh={true}>
        <Header />
        <Switch>
          {/* jobs */}
          <Route path='/' component={Jobs} exact />

          {/* Requests */}
          <Route path='/requests' component={Requests} exact />
          <Route path='/status' component={ApplicationStatus} exact />
          <Route path='/cv-submitted' component={CVSubmitted} exact />

          {/* Application */}
          <Route path='/application' component={UserProfile} exact />

          {/* settings */}
          <Route path='/settings' component={Settings} exact />

          {/* Onboarding Routes */}
          <Route path='/get-started' component={GetStarted} exact />
          <Route
            path='/create-account/:referralCode?'
            component={SignUp}
            exact
          />
          <Route
            path='/create-profile'
            component={CreateCandidateProfile}
            exact
          />

          {/* Authentication Routes */}
          <Route path='/sign-in' component={SignIn} exact />
          <Route path='/forgot-password' component={ForgotPassword} exact />
          <Route path='/hunt-reset-password' component={ResetPassword} exact />
          <Route path='/update-password' component={UpdatePassword} exact />
          <Route path='/hunt-verify-email' component={HuntVerifyEmail} exact />
          <Route path='/hunt-verify-email' component={HuntVerifyEmail} exact />
          <Route
            path='/email-verification'
            component={EmailVerification}
            exact
          />

          <Route path='/sign-out' component={SignOut} exact />

          <Route path='*'>
            <Page404 />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default MainRoutes;
