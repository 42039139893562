import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Button, Checkbox, Divider, Tag } from 'antd';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

import {
  ArrowRightOutlined,
  DownOutlined,
  CheckCircleFilled,
  UpOutlined,
  DollarOutlined,
} from '@ant-design/icons';

import '../styles/index.less';

// types
import { Job } from '../types';

// helpers
import { getEmploymentTypeString } from '../helpers';

// assets
import jobTypeIcon from '../assets/job-type-icon.svg';
import locationIcon from '../assets/location-icon.svg';
import modalityIcon from '../assets/modality-icon.svg';

const BoardURL = process.env.REACT_APP_PUBLIC_JOB_BOARD_HOST
  ? process.env.REACT_APP_PUBLIC_JOB_BOARD_HOST
  : '';

interface JobItemProps {
  job: Partial<Job>;
  selected: boolean;
  noApply?: boolean;
  hideApply?: boolean;
  alreadyApplied?: boolean;
  onCompanySelect?: (string) => void;
  onSelect?: (number) => void;
  onUnselect?: (number) => void;
  onApply?: (number) => void;
}

export const JobItem: FunctionComponent<JobItemProps> = ({
  noApply,
  job,
  selected,
  hideApply,
  alreadyApplied,
  onSelect,
  onUnselect,
  onApply,
  onCompanySelect,
}: JobItemProps) => {
  const [expanded, setExpanded] = useState<Boolean>(false);

  return (
    <div className='job-item'>
      <Row>
        <Col span={24} lg={24} xl={16}>
          <div className='job-header'>
            <img src={job.company_logo_url} />
            <div className='job-header-info'>
              <a
                href=''
                className='job-title'
                onClick={(e) => {
                  setExpanded(!expanded);
                  e.preventDefault();
                }}
              >
                <h5>{job.title}</h5>
              </a>
              <Button
                type='link'
                className='company'
                style={{ margin: 0, padding: 0 }}
                onMouseDown={() => {
                  if (onCompanySelect) onCompanySelect(job.company_name);
                }}
              >
                <h6>
                  {job.company_name}{' '}
                  {job.is_verified ? (
                    <CheckCircleFilled
                      style={{ color: '#3581F7', marginLeft: '0.2rem' }}
                    />
                  ) : null}
                </h6>
              </Button>
            </div>
          </div>
          <div className='data-row job-class'>
            <span>
              {job.class} {'>'} {job.subclass}
            </span>
          </div>
          <div className='data-row'>
            <span className='item'>
              <img
                src={jobTypeIcon}
                style={{ marginRight: '0.6rem' }}
                alt='Job Type Icon'
              />
              <span>{getEmploymentTypeString(job.job_type)}</span>
            </span>
            {job.remote ? (
              <span className='item'>
                <img
                  src={modalityIcon}
                  style={{ marginRight: '0.6rem' }}
                  alt='Job Modality Icon'
                />
                <span>Remote</span>
              </span>
            ) : null}

            {job.location ? (
              <span className='item'>
                <img
                  src={locationIcon}
                  style={{ marginRight: '0.6rem' }}
                  alt='Location Icon'
                />
                <span>{job.location}</span>
              </span>
            ) : null}
            {job.min_salary && job.max_salary ? (
              <span className='item'>
                <DollarOutlined style={{ marginRight: '0.6rem' }} />
                <span>
                  {job.salary_currency} {job.min_salary.toLocaleString()} -{' '}
                  {job.max_salary.toLocaleString()}/{job.salary_frequency}
                </span>
              </span>
            ) : null}
          </div>
        </Col>
        {!noApply && !alreadyApplied ? (
          <Col span={8} xs={24} xl={8} className='action-right-section'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Checkbox
                  style={{ marginRight: '0.6rem' }}
                  checked={selected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onSelect(job.id);
                    } else {
                      onUnselect(job.id);
                    }
                  }}
                >
                  Select
                </Checkbox>
                <Button
                  type='link'
                  disabled={hideApply}
                  onMouseDown={() => {
                    if (onApply) onApply(job.id);
                  }}
                >
                  Apply <ArrowRightOutlined style={{ marginLeft: '0.4rem' }} />
                </Button>
              </div>
              <div style={{ marginRight: '15px' }}>
                {job.updated_at && job.updated_at ? (
                  <div className='posted-on-label'>
                    <span>{`Posted on: ${moment(job.updated_at).format(
                      'DD/MM/YYYY'
                    )}`}</span>
                  </div>
                ) : null}
              </div>
            </div>

            {/*expanded ? (
              <a 
                href="" 
                onClick={(e) => {
                  setExpanded(false);
                  e.preventDefault();
                }}>Show Less <UpOutlined /></a>
            ) : (
              <a 
                href="" 
                onClick={(e) => {
                  setExpanded(true);
                  e.preventDefault();
                }}
              >Show More <DownOutlined /></a>
              )*/}
          </Col>
        ) : alreadyApplied ? (
          <Col flex={1}>
            <div style={{ textAlign: 'right' }}>
              <Tag className='primary-tag'>Already applied</Tag>
            </div>
            <div style={{ paddingRight: '9px' }}>
              {job.updated_at && job.updated_at ? (
                <div className='posted-on-label'>
                  <span>{`Posted on: ${moment(job.updated_at).format(
                    'DD/MM/YYYY'
                  )}`}</span>
                </div>
              ) : null}
            </div>
          </Col>
        ) : null}
      </Row>
      {expanded ? (
        <div>
          <Divider />
          <h5 style={{ lineHeight: 1.6 }}>
            {job.company_name} is Hiring a{' '}
            <span
              className='blue'
              style={{ fontWeight: 'inherit', fontSize: 'inherit' }}
            >
              {job.title}
            </span>
          </h5>
          <div style={{ padding: '0.6rem 0rem' }}>
            {job.tags.map((tag, i) => (
              <Tag className='primary-tag' key={i}>
                {tag}
              </Tag>
            ))}
          </div>
          <div style={{ padding: '1rem 0rem' }}>
            <ReactMarkdown
              className='description'
              source={job.description}
              escapeHtml={true}
            />
          </div>
          <div
            style={{
              padding: '1rem 0rem 0rem 0rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Button
              type='link'
              style={{ margin: 0, padding: 0 }}
              onMouseDown={() => {
                if (onCompanySelect) onCompanySelect(job.company_name);
              }}
            >
              See more jobs at {job.company_name}{' '}
              <ArrowRightOutlined style={{ marginLeft: '0.4rem' }} />
            </Button>
            {!noApply ? (
              <Button
                type='primary'
                size='large'
                disabled={hideApply}
                style={{ backgroundColor: '#F7671E', border: 0 }}
                onMouseDown={() => {
                  if (onApply) onApply(job.id);
                }}
              >
                Apply for this job
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
