import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Input, Button, Select, message } from 'antd';
import { Formik, FormikErrors } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { DesignationSearchResponse } from '../../Common/types/designation.type';
import { SearchInput } from '../../Common/types/onBoarding.type';
import { Queries as DesignationQuery } from '../../Common/queries/roles';
import {
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../../Common/types/candidate.type';
import { PatchProfileMutations } from '../../Common/queries/myAccount';
import { debounce } from 'lodash';
import LocationSearchInput from '../../Common/partials/LocationSearchInput';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  geoLocation: {
    lat: number;
    lon: number;
  };
  country;
  primaryRole: string;
  otherRoles: string[];
  bio: string;
  handleDesignationSearch: (value: string) => void;
  isDesignationsloading: boolean;
  designations: string[] | undefined;
}

interface PatchProfileInput {
  firstName: string;
  lastName: string;
  location: string;
  primaryRole: string;
  otherRoles: string[];
  bio: string;
}

function EditGeneralInfoModal({
  open,
  setOpen,
  firstName,
  lastName,
  email,
  location,
  geoLocation,
  country,
  primaryRole,
  otherRoles,
  bio,
  handleDesignationSearch,
  isDesignationsloading,
  designations,
}: IProps) {
  // validator function
  const validate = (
    values: PatchProfileInput
  ): FormikErrors<PatchProfileInput> => {
    const errors: FormikErrors<PatchProfileInput> = {};

    if (!values.firstName) errors.firstName = 'Required';
    if (!values.lastName) errors.lastName = 'Required';
    if (!values.location) errors.location = 'Required';
    if (!values.primaryRole) errors.primaryRole = 'Required';
    if (!values.bio) errors.bio = 'Required';

    return errors;
  };

  const [designation, setDesignation] = useState<string[]>(otherRoles);

  const debouncedPrimaryRolesSearch = useCallback(
    debounce(handleDesignationSearch, 200),
    []
  );

  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        location: values.location,
        country: values.country || country,
        primaryRole: values.primaryRole,
        secondaryRoles: designation,
        pastResponsibilities: values.bio,
      };

      await candidateUpdateProfile({
        variables: { data: payload },
      });

      if (!isProfileUpdating) {
        message.success('Profile updated Successfully', 3);
      }
    } catch (error) {
      error.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    } finally {
      setOpen(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        email: email,
        location: location,
        country: country ? country : '',
        location_lat: geoLocation ? geoLocation.lat : 0,
        location_lng: geoLocation ? geoLocation.lon : 0,
        primaryRole: primaryRole,
        otherRoles: otherRoles,
        bio: bio,
      }}
      validate={validate}
      // validationSchema={inputValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        values,
        setFieldValue,
        handleChange,
        handleSubmit,
        resetForm,
      }) => {
        const handleCancel = () => {
          setOpen(false);
          resetForm();
        };

        return (
          <Modal
            key='general-info-modal'
            centered
            visible={open}
            onCancel={handleCancel}
            footer={null}
          >
            <h3
              style={{
                fontSize: '24px',
                color: '#071421',
                padding: '16px 20px 0px 16px',
              }}
            >
              Edit general information
            </h3>
            <Form
              onFinish={handleSubmit}
              style={{ padding: '0 20px 0px 20px' }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '24px',
                  marginTop: '32px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Form.Item
                    style={{
                      margin: '0px',
                      padding: '0px',
                    }}
                    validateStatus={errors.firstName ? 'error' : 'success'}
                    help={errors.firstName}
                    required
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        color: '#6A7279',
                        fontWeight: 200,
                      }}
                    >
                      First name
                    </span>

                    <Input
                      name='firstName'
                      style={{
                        fontSize: '16px',
                        background: '#ffff',
                      }}
                      onChange={handleChange}
                      value={values.firstName}
                      size='middle'
                    />
                  </Form.Item>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Form.Item
                    style={{
                      margin: '0px',
                      padding: '0px',
                    }}
                    validateStatus={errors.lastName ? 'error' : 'success'}
                    help={errors.lastName}
                    required
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        color: '#6A7279',
                        fontWeight: 200,
                      }}
                    >
                      Last name
                    </span>
                    <Input
                      name='lastName'
                      style={{
                        fontSize: '16px',
                        background: '#ffff',
                      }}
                      onChange={handleChange}
                      value={values.lastName}
                      size='middle'
                    />
                  </Form.Item>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <span
                  style={{
                    fontSize: '16px',
                    color: '#6A7279',
                    fontWeight: 200,
                  }}
                >
                  Email address
                </span>
                <Input
                  name='email'
                  type='email'
                  disabled
                  style={{
                    fontSize: '16px',
                    background: '#ffff',
                  }}
                  onChange={handleChange}
                  value={values.email}
                  size='middle'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <Form.Item
                  style={{
                    margin: '0px',
                    padding: '0px',
                  }}
                  validateStatus={errors.location ? 'error' : 'success'}
                  help={errors.location}
                  required
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#6A7279',
                      fontWeight: 200,
                    }}
                  >
                    Location
                  </span>
                  <LocationSearchInput
                    value={values.location}
                    large
                    onLocationUpdated={(locationData) => {
                      setFieldValue('location', locationData.formatted_address);
                      setFieldValue('country', locationData.country);
                      setFieldValue('locality', locationData.locality);
                      setFieldValue('location_lat', locationData.location_lat);
                      setFieldValue('location_lng', locationData.location_lng);
                    }}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <Form.Item
                  style={{
                    margin: '0px',
                    padding: '0px',
                  }}
                  validateStatus={errors.primaryRole ? 'error' : 'success'}
                  help={errors.primaryRole}
                  required
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#6A7279',
                      fontWeight: 200,
                    }}
                  >
                    Primary role
                  </span>
                  <Select
                    loading={isDesignationsloading}
                    showSearch
                    value={values.primaryRole}
                    notFoundContent={null}
                    className='select-roles'
                    style={{ width: '100%' }}
                    size='large'
                    placeholder='Ex: Software Engineer'
                    showArrow={false}
                    options={designations?.map((designations) => ({
                      label: designations,
                      value: designations,
                    }))}
                    onSearch={(val) => {
                      debouncedPrimaryRolesSearch(val);
                    }}
                    onChange={(value) => {
                      setFieldValue('primaryRole', value);
                    }}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <Form.Item
                  style={{
                    margin: '0px',
                    padding: '0px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#6A7279',
                      fontWeight: 200,
                    }}
                  >
                    Other roles
                  </span>
                  <Select
                    loading={isDesignationsloading}
                    mode='multiple'
                    defaultValue={designation}
                    size='large'
                    allowClear
                    notFoundContent={null}
                    className='select-roles'
                    style={{ width: '100%' }}
                    placeholder='You can add one or more roles'
                    options={designations?.map((designations) => ({
                      label: designations,
                      value: designations,
                    }))}
                    onSearch={(val) => {
                      handleDesignationSearch(val);
                    }}
                    onChange={(value) => {
                      // designation.push(value);
                      setDesignation(value);
                    }}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <Form.Item
                  style={{
                    margin: '0px',
                    padding: '0px',
                  }}
                  validateStatus={errors.bio ? 'error' : 'success'}
                  help={errors.bio}
                  required
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#6A7279',
                      fontWeight: 200,
                    }}
                  >
                    Bio
                  </span>
                  <Input.TextArea
                    rows={4}
                    name='bio'
                    style={{
                      fontSize: '16px',
                      background: '#ffff',
                    }}
                    onChange={handleChange}
                    value={values.bio}
                    size='middle'
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '24px',
                  marginTop: '30px',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                    height: '48px',
                    background: '#FFF1EB',
                    borderColor: '#FFF1EB',
                  }}
                  className='secondary-btn'
                  type='default'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: '100%', height: '48px' }}
                  type='primary'
                  htmlType='submit'
                  loading={isProfileUpdating}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}

export default EditGeneralInfoModal;
