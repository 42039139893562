import React, { FunctionComponent, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ImgCrop from 'antd-img-crop';
import { Upload, message } from 'antd';

// Types
import { RcFile } from 'antd/lib/upload';
import { ImageFile } from '../types/files.types';
import { UploadFile } from 'antd/lib/upload/interface';
import { ProgressStore } from '../../Common/stores/progress.store';

// Styles
import 'antd/es/modal/style';
import 'antd/es/slider/style';

//assets
import editIcon from '../assets/edit.svg';
import {
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../types/candidate.type';
import { PatchProfileMutations } from '../queries/myAccount';
import useRefreshStore from '../stores/refresh.store';

const generateFileName = (file) => {
  let timestamp = new Date().getTime();
  return `${file.name}_${timestamp}`;
};

const Mutations = {
  getSignedUrl: gql`
    mutation GetSignedUrl($data: GetSignedUrlInput!) {
      getSignedUrl(data: $data) {
        signedRequest
        url
      }
    }
  `,
};

interface GetSignedUrlInput {
  fileName: string;
  contentType: string;
  folder: string;
}

interface GetSignedUrlResponse {
  getSignedUrl: {
    url: string;
    signedRequest: string;
  };
}

interface ImageUploadProps {
  width: number;
  height: number;
  folder: string;
  uploadButtonText?: string;
  showUploadList?: boolean;
  type: 'icon';
  fileList: UploadFile[];

  onFileUpload?: (file: ImageFile | null) => void;
}

const ImageUpload: FunctionComponent<ImageUploadProps> = (
  props: ImageUploadProps
) => {
  const { width, height, folder, onFileUpload, showUploadList = true } = props;

  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { setPercent, RemovePercent } = ProgressStore((state) => state);
  const setRefreshState = useRefreshStore((state) => state.setRefreshState);
  const refreshState = useRefreshStore((state) => state.refreshState);

  const history = useHistory();

  const updateProfileImage = async (fileURL) => {
    const payload = {
      profileUrl: fileURL,
    };

    try {
      await candidateUpdateProfile({
        variables: { data: payload },
      });
      if (!isProfileUpdating) {
        message.success('Profile updated Successfully', 3);
        setRefreshState(!refreshState);
      }
    } catch (e) {
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((error) => {
          message.error(error.message, 3);
        });
      } else {
        message.error(e.message, 3);
      }
    }
  };

  const [getSignedUrl] = useMutation<
    GetSignedUrlResponse,
    { data: GetSignedUrlInput }
  >(Mutations.getSignedUrl);

  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  const customRequest = async (e: {
    file: RcFile;
    onSuccess: (arg0: { [key: string]: unknown }, arg1: RcFile) => void;
    onError: (error: Error, response?: any, file?: RcFile) => void;
    onProgress: (response: object, file: RcFile) => void;
  }) => {
    const file = e.file;

    setIsUploading(!isUploading);

    const uploadingFile = {
      uid: file.uid,
      type: file.type,
      name: file.name,
      size: file.size,
    };
    setFileList([uploadingFile]);

    if (file) {
      try {
        const resp = await getSignedUrl({
          variables: {
            data: {
              fileName: generateFileName(file),
              contentType: file.type,
              folder: props.folder,
            },
          },
        });
        setPercent(10);
        const uploadURL = resp.data.getSignedUrl.signedRequest;
        const fileURL = resp.data.getSignedUrl.url;

        const options = {
          headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read',
          },
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setPercent(percent);
            if (percent === 100) {
              setTimeout(() => setPercent(0), 1000);
            }
            e.onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          },
        };

        await axios.put(uploadURL, file, options);

        e.onSuccess({}, file);
        RemovePercent(0);
        message.destroy();
        if (props.onFileUpload) {
          props.onFileUpload({ file: file, imageUrl: fileURL });
          await updateProfileImage(fileURL);
        }

        setIsUploading(!isUploading);
      } catch (err) {
        message.error(err.message, 4);
        setIsUploading(isUploading);
      }
    }
  };

  return (
    <ImgCrop aspect={width / height}>
      <Upload
        accept='image/*'
        multiple={false}
        customRequest={customRequest}
        showUploadList={showUploadList}
        fileList={
          fileList.length !== 0 && [
            {
              uid: fileList.length !== 0 && fileList[0].uid,
              type: fileList.length !== 0 && fileList[0].type,
              name: fileList.length !== 0 && fileList[0].name,
              size: fileList.length !== 0 && fileList[0].size,
            },
          ]
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '23px',
            height: '23px',
            margin: 0,
            cursor: 'pointer',
            position: 'absolute',
            left: '55px',
            right: 0,
            lineHeight: '34px',
            top: '60px',
            opacity: 1,
            backgroundColor: 'white',
            borderRadius: '50%',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.45)',
          }}
        >
          <img src={editIcon} alt='edit' />
        </div>
      </Upload>
    </ImgCrop>
  );
};

export default ImageUpload;
