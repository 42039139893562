import React, { useState } from 'react';
import { Row, Col, Card, Divider, Button, Tag, Typography } from 'antd';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { TransitionGroup } from 'react-transition-group';

//assets
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import jobType from '../../Common/assets/job-type.svg';
import modality from '../../Common/assets/modality.svg';
import location from '../../Common/assets/location.svg';
import reject from '../../Common/assets/reject-cross.svg';
import accept from '../../Common/assets/accept-tick.svg';
import time from '../../Common/assets/time-icon.svg';

//types
import { FetchHuntRequest } from '../../Common/types/requests.type';

type Props = {
  requestId: number;
  request: FetchHuntRequest;
  handleRequestUpdate: (request: FetchHuntRequest, status: boolean) => void;
};

const { Text, Paragraph } = Typography;

const RequestMobile = ({ requestId, request, handleRequestUpdate }: Props) => {
  const [expanded, setExpanded] = useState<Boolean>(false);

  return (
    <Card className='request-card-mobile'>
      {/* card header */}
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: 16 }}
      >
        <Col
          style={{
            display: 'flex',
          }}
        >
          <img
            alt='company_logo'
            src={request.company.logoUrl}
            width={45}
            height={45}
          />
          <div style={{ marginLeft: 12, alignItems: 'flex-start' }}>
            <Text
              strong
              style={{ color: '#F7671E', fontSize: 14, marginRight: 10 }}
            >
              {request.company.name}
            </Text>
            is requesting to interview you for the following position.
          </div>
        </Col>
        <Divider
          style={{ backgroundColor: '#0C203A', opacity: 0.2 }}
          className='divider-style'
        />
        {/* card Body */}

        <Col>
          <Text strong style={{ fontSize: 18 }}>
            {request.job.title}
          </Text>
          <Paragraph
            style={{ fontSize: 14, marginTop: 8 }}
          >{`${request.job.department} > ${request.job.class}`}</Paragraph>
        </Col>
        <Col style={{ marginBottom: 8 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', minWidth: 169 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt='job-type' src={jobType} width={12} height={12} />
                <Text
                  style={{
                    color: '#0C203A',
                    opacity: 0.6,
                    marginLeft: 8,
                    fontSize: 14,
                  }}
                >
                  {request.job.jobType}
                </Text>
              </div>
              {!request.job.remote && (
                <div
                  style={{
                    marginLeft: 14,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img alt='modality' src={modality} width={12} height={12} />
                  <Text
                    style={{
                      color: '#0C203A',
                      opacity: 0.6,
                      marginLeft: 8,
                      fontSize: 14,
                    }}
                  >
                    Remote
                  </Text>
                </div>
              )}
            </div>
            <div
              style={{
                marginLeft: 14,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img alt='location' src={location} width={12} height={12} />
              <Text
                style={{
                  color: '#0C203A',
                  opacity: 0.6,
                  marginLeft: 8,
                  fontSize: 14,
                }}
              >
                {request.job.formattedAddress}
              </Text>
            </div>
          </div>
        </Col>
        <Col xs={24} style={{ marginBottom: 24 }}>
          <Text style={{ fontSize: 14, opacity: 0.6, color: '#0C203A' }}>
            <img src={time} />
            <span style={{ marginLeft: 10 }}>
              {moment(request.requestedAt).fromNow()}
            </span>
          </Text>
        </Col>
        <Col xs={24} style={{ marginBottom: 20 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 20 }}>
              <Button
                type='primary'
                style={{
                  width: '118px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => handleRequestUpdate(request, true)}
              >
                <img src={accept} alt='accept' />
                <Text
                  style={{
                    color: ' #FFFFFF',
                    opacity: 1,
                    fontWeight: 600,
                    marginLeft: 10,
                    fontSize: 16,
                  }}
                >
                  Accept
                </Text>
              </Button>
            </div>
            <div>
              <Button
                type='link'
                style={{
                  width: '70px',
                  height: '23px',
                  color: '#ED1C24',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => handleRequestUpdate(request, false)}
              >
                <img src={reject} alt='accept' />
                <Text
                  style={{
                    color: '#ED1C24',
                    opacity: 1,
                    fontWeight: 600,
                    marginLeft: 10,
                    fontSize: 16,
                  }}
                >
                  Reject
                </Text>
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className='clickToExpand'>
            {!expanded ? (
              <a
                href=''
                onClick={(e) => {
                  setExpanded(true);
                  e.preventDefault();
                }}
              >
                Click to expand <DownOutlined style={{ marginLeft: 10 }} />
              </a>
            ) : (
              <a
                href=''
                onClick={(e) => {
                  setExpanded(false);
                  e.preventDefault();
                }}
              >
                Click to collapse <UpOutlined style={{ marginLeft: 10 }} />
              </a>
            )}
          </div>
        </Col>
      </Row>
      <TransitionGroup
        component={null}
        childFactory={(child) =>
          React.cloneElement(child, { classNames: 'collapsible-panel' })
        }
      >
        {expanded ? (
          <div className='collapse' style={{ paddingTop: 10 }}>
            <Divider />
            <h5>
              {request.job.company} is Hiring a{' '}
              <span
                className='blue'
                style={{ fontWeight: 'inherit', fontSize: 'inherit' }}
              >
                {request.job.title}
              </span>
            </h5>
            <div style={{ padding: '0.6rem 0rem' }}>
              {request.job.tags.map((tag, i) => (
                <Tag className='primary-tag' key={i}>
                  {tag}
                </Tag>
              ))}
            </div>
            <div style={{ padding: '1rem 0rem' }}>
              <ReactMarkdown
                source={request.job.description}
                escapeHtml={true}
              />
            </div>
            <div
              style={{
                padding: '1rem 0rem 0rem 0rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            ></div>
          </div>
        ) : null}
      </TransitionGroup>
    </Card>
  );
};
export default RequestMobile;
