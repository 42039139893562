export const capitaliseString = (str) => {
	let words = str.split(" ");
	let parsedWords = [];
	for (let word of words) {
		if (word.length > 0) word = word[0].toUpperCase() + word.slice(1, word.length);
		else word = "";

		parsedWords.push(word);
	}
	return parsedWords.join(" ");
};

export const delay = (seconds) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, seconds * 1000);
	});
};