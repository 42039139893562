import React, { FunctionComponent, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

// store
import { tokenStore } from '../stores/token.store';

interface AuthLayoutProps {
	children: ReactNode;
}

const AuthLayout: FunctionComponent<AuthLayoutProps> = (
	props: AuthLayoutProps,
) => {
	const { children } = props;

	const token = tokenStore((state) => state.token);

	if (token) {
		return <Redirect to="/" />;
	}

	return (
		<div className="app-area" style={{ backgroundColor: '#ffff', opacity: 1 }}>
			<div className="main-area">{children}</div>
		</div>
	);
};

export default AuthLayout;
