import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Form, Input, Switch, Divider, message } from 'antd';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { coreGraphQLClientWithoutAuth } from '../../api/apollo';
import { huntGraphQLClientWithAuth as huntGraphQLClient } from '../../api/apollo';

// types
import { Job, CustomQuestion } from '../types';

import { Queries as CoreQueries } from '../../Common/queries/core';
import { ApplicationMutations } from '../../Common/queries/application';

// partials
import { JobItem } from '../partials/JobItem';
import Upload from '../../Common/partials/Upload';
import Loading from '../../Common/partials/Loading';

interface JobApplicationProps {
  jobId: number;
  onSubmitted?: (jobId) => void;
  skipJob?: (jobId) => void;
}

export const JobApplication: FunctionComponent<JobApplicationProps> = ({
  jobId,
  onSubmitted,
  skipJob,
}: JobApplicationProps) => {
  const [job, setJob] = useState<Job>();
  const [customQuestions, setCustomQuestions] = useState<CustomQuestion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const LoadJobDetails = async () => {
    try {
      const { data } = await coreGraphQLClientWithoutAuth.query({
        query: CoreQueries.getJob,
        variables: {
          id: jobId,
        },
        fetchPolicy: 'network-only',
      });

      const curJob = data.fetchOneJobPublic;

      if (curJob) {
        setJob({
          id: curJob.id,
          title: curJob.title,
          description: curJob.description,
          company_id: curJob.company.id,
          company_name: curJob.company.name,
          company_logo_url: curJob.company.logoUrl,
          class: curJob.class,
          subclass: curJob.subclass,
          job_type: curJob.jobType,
          remote: curJob.remote,
          location: curJob.location,
          tags: curJob.tags,
        } as Job);
        setCustomQuestions(curJob.customQuestions);
        setLoading(false);
      }
    } catch (e) {
      console.log(e.message);
      message.error(e.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    LoadJobDetails();
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loading />;

  const handleRedirect = () => {
    window.location.href = `https://staging-board.rooster.jobs/jobs/${jobId}`;
  };

  return (
    <div>
      <Formik
        initialValues={{
          jobId,
          customQuestionsAnswers: customQuestions.map((question) => ({
            question: question.question,
            answer: question.type === 'BOOLEAN' ? 'No' : '',
            type: question.type,
          })),
        }}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          try {
            await huntGraphQLClient.mutate({
              mutation: ApplicationMutations.HuntCandidateApplication,
              variables: {
                data: {
                  jobId: values.jobId,
                  customQuestionsAnswers: values.customQuestionsAnswers,
                },
              },
            });
            if (onSubmitted) onSubmitted(jobId);
          } catch (e) {
            message.error(e.message, 4);
          }

          console.log(values);
        }}
        validate={(values) => {
          let errors = {};

          // validate custom questions
          for (let i = 0; i < customQuestions.length; i += 1) {
            if (
              customQuestions[i].type !== 'ASSESSMENT' &&
              customQuestions[i].required &&
              !values.customQuestionsAnswers[i].answer
            ) {
              errors[`customQuestionsAnswers.${i}.answer`] = 'Required';
            }
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          submitForm,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form
            onFinish={handleSubmit}
            style={{ marginBottom: '1rem' }}
            colon={false}
          >
            {job && customQuestions ? (
              <div>
                <JobItem
                  selected={false}
                  hideApply={true}
                  job={job}
                  noApply={true}
                />
                <div style={{ paddingTop: '1rem' }}>
                  {customQuestions
                    .filter((question) => question.type !== 'VIDEO')
                    .filter((question) => question.type !== 'ASSESSMENT')
                    .map((question, index) => (
                      <Form.Item
                        label={question.question}
                        key={index}
                        style={{ display: 'block' }}
                        required={question.required}
                        help={errors[`customQuestionsAnswers.${index}.answer`]}
                        validateStatus={
                          errors[`customQuestionsAnswers.${index}.answer`]
                            ? 'error'
                            : ''
                        }
                      >
                        {question.type === 'SHORT_TEXT' ? (
                          <Input
                            name={`customQuestionsAnswers.${index}.answer`}
                            onChange={handleChange}
                            value={values.customQuestionsAnswers[index].answer}
                          />
                        ) : null}

                        {question.type === 'LONG_TEXT' ? (
                          <Input.TextArea
                            rows={4}
                            name={`customQuestionsAnswers.${index}.answer`}
                            onChange={handleChange}
                            value={values.customQuestionsAnswers[index].answer}
                          />
                        ) : null}

                        {question.type === 'BOOLEAN' ? (
                          <Switch
                            onChange={(checked) => {
                              setFieldValue(
                                `customQuestionsAnswers.${index}.answer`,
                                checked ? 'Yes' : 'No'
                              );
                            }}
                            checked={
                              values.customQuestionsAnswers[index].answer ===
                              'Yes'
                                ? true
                                : false
                            }
                          />
                        ) : null}

                        {question.type === 'FILE' ? (
                          <Upload
                            folder='custom_question'
                            onFileUpload={(fileURL, fileName) => {
                              console.log(fileURL, fileName);
                              setFieldValue(
                                `customQuestionsAnswers.${index}.answer`,
                                fileURL
                              );
                            }}
                          />
                        ) : null}

                        {question.type === 'URL' ? (
                          <Input
                            name={`customQuestionsAnswers.${index}.answer`}
                            onChange={handleChange}
                            value={values.customQuestionsAnswers[index].answer}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                </div>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='link'
                    onMouseDown={() => {
                      if (skipJob) skipJob(jobId);
                    }}
                  >
                    Skip
                  </Button>
                  {customQuestions.some(
                    (question) => question.type === 'VIDEO'
                  ) ? (
                    <Button
                      type='primary'
                      loading={isSubmitting}
                      // htmlType='submit'
                      onClick={handleRedirect}
                    >
                      Proceed the application
                    </Button>
                  ) : (
                    <Button
                      type='primary'
                      loading={isSubmitting}
                      htmlType='submit'
                    >
                      Submit Application
                    </Button>
                  )}
                </div>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};
