import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { Formik, FormikErrors } from 'formik';

//layout
import AuthLayout from '../Common/containers/AuthLayout';

//types
import {
	ResetPasswordInput,
	ResetPasswordResponse,
} from '../Common/types/auth.types';

//queries
import { ResetPasswordMutations } from '../Common/queries/auth';

interface ResetPasswordForm extends ResetPasswordInput {
	confirmPassword: string;
}

const ResetPassword: FunctionComponent = () => {
	const [resetPassword] = useMutation<
		ResetPasswordResponse,
		{ data: ResetPasswordInput }
	>(ResetPasswordMutations.resetPassword);

	const history = useHistory();
	const location = useLocation();

	const resetParamsFromQueryString = (
		queryStr: string,
	): { huntCandidateId: number; resetToken: string } => {
		const parsedObj = Object.fromEntries(new URLSearchParams(queryStr));
		return {
			huntCandidateId: parseInt(parsedObj['huntCandidateId']),
			resetToken: parsedObj['resetToken'],
		};
	};

	const initialValues: ResetPasswordForm = {
		...resetParamsFromQueryString(location.search),
		newPassword: undefined,
		confirmPassword: undefined,
	};

	const validate = (
		values: ResetPasswordForm,
	): FormikErrors<ResetPasswordForm> => {
		const errors: FormikErrors<ResetPasswordForm> = {};
		// Password
		if (!values.newPassword) {
			errors.newPassword = 'Required';
		}
		if (values.newPassword && values.newPassword.length < 8) {
			errors.newPassword = 'Your password should have at least 8 characters';
		}

		if (!values.confirmPassword) {
			errors.confirmPassword = 'Required';
		}

		if (
			values.confirmPassword &&
			values.newPassword !== values.confirmPassword
		) {
			errors.confirmPassword = "Oops... Your passwords don't match";
		}
		return errors;
	};

	const submitForm = async (values: ResetPasswordForm) => {
		try {
			const passwordInput: ResetPasswordInput = {
				huntCandidateId: values.huntCandidateId,
				resetToken: values.resetToken,
				newPassword: values.newPassword,
			};
			await resetPassword({ variables: { data: passwordInput } });
			message.success('Your password has been reset successfully!', 3);
			history.push('/sign-in');
		} catch (e) {
			if (e.graphQLErrors) {
				e.graphQLErrors.forEach((error) => {
					message.error(error.message, 3);
				});
			} else {
				message.error(e.message, 3);
			}
		}
	};

	return (
		<AuthLayout>
			<div className="container">
				<Row className="justify-center">
					<Col xs={24} sm={14} md={10} lg={10} xl={8}>
						<div className="inner-card">
							<h2 className="heading-section">Reset your password</h2>
							<Formik
								initialValues={initialValues}
								validate={validate}
								onSubmit={submitForm}
							>
								{({
									values,
									errors,
									handleChange,
									handleSubmit,
									isSubmitting,
								}) => (
									<Form onFinish={handleSubmit} colon={false} layout="vertical">
										<Form.Item
											label="New Password"
											help={errors.newPassword}
											validateStatus={errors.newPassword ? 'error' : ''}
										>
											<Input
												name="newPassword"
												onChange={handleChange}
												type="password"
												value={values.newPassword}
												size="large"
												className="form-input"
											/>
										</Form.Item>
										<Form.Item
											label="Confirm Password"
											help={errors.confirmPassword}
											validateStatus={errors.confirmPassword ? 'error' : ''}
										>
											<Input
												name="confirmPassword"
												onChange={handleChange}
												type="password"
												value={values.confirmPassword}
												size="large"
												className="form-input"
											/>
										</Form.Item>

										<div style={{ marginTop: 25 }}>
											<Button
												block
												type="primary"
												htmlType="submit"
												size="large"
												className="primarycta"
												style={{
													borderRadius: 5,
													width: '100%',
												}}
												loading={isSubmitting}
											>
												Reset Password
											</Button>
										</div>
									</Form>
								)}
							</Formik>
							<Row>
								<Col xs={24} md={24} lg={24} xl={24}>
									<div
										style={{
											padding: '1rem 0rem',
											marginTop: '3rem',
											textAlign: 'center',
										}}
									>
										Already have an account? &nbsp;
										<Link to="/sign-in" style={{ fontWeight: 600 }}>
											Sign in
										</Link>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
		</AuthLayout>
	);
};

export default ResetPassword;
