import React from 'react';
import { Row, Col, Skeleton } from 'antd';

export const UserProfileSkeleton = () => {
	return (
		<Row className="justify-center">
			<Col xs={24} lg={12} xl={12}>
				<div className="onboarding-card">
					{[...new Array(4)].map((value, index) => (
						<Row gutter={[8, 32]}>
							<Col>
								<div style={{ width: '500px' }}>
									<Skeleton active paragraph={{ rows: 2, width: [500] }} />
								</div>
							</Col>
						</Row>
					))}
				</div>
			</Col>
			<Col xs={24} lg={12} xl={12}>
				<div className="onboarding-card">
					<Row
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '0.5rem 0 0',
						}}
					>
						<div style={{ width: 600, height: 600 }}>
							<Skeleton.Input active size={'large'} />
						</div>
					</Row>
				</div>
			</Col>
		</Row>
	);
};
