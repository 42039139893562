import React from 'react';
import { Row, Col, Skeleton } from 'antd';

export const RequestSkeleton = () => {
	return (
		<Row>
			<Col xs={0} lg={24}>
				<div style={{ marginTop: 80 }}>
					<div>
						{[...new Array(3)].map((value, index) => (
							<Row gutter={[8, 40]}>
								<Col xs={0} lg={24}>
									<Skeleton
										title={{ width: 400 }}
										active
										paragraph={{ rows: 3, width: ['100%', '100%'] }}
									/>
								</Col>
							</Row>
						))}
					</div>
				</div>
			</Col>
			<Col xs={24} lg={0}>
				<div style={{ marginTop: 50, marginRight: 10, marginLeft: 10 }}>
					{[...new Array(3)].map((value, index) => (
						<Row gutter={[8, 48]}>
							<Col xs={24} lg={0}>
								<div>
									<Skeleton
										title={{ width: 200 }}
										active
										paragraph={{ rows: 3, width: ['100%', '100%', '100%'] }}
									/>
								</div>
							</Col>
						</Row>
					))}
				</div>
			</Col>
		</Row>
	);
};
