import React, { FunctionComponent } from "react";
import { Button, Carousel } from "antd";

import Layout from "../../Common/containers/AuthLayout";

// Sagas
import { Link } from "react-router-dom";

// constants
import { SIZE } from "../../Common/themes";

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center' as 'center',
  background: '#364d79',
};

const GetStarted: FunctionComponent = () => {
	return(
		<Layout>
			<div className="container">
				<div className="card">
					<h2 className="onboarding-headline centered">Create your profile and find your next opportunity</h2>
          <div>
          <Carousel autoplay>
              <div>
                <div></div>
                <div><p className="centered">We’ll share your details anonymously with potential employers</p></div>
              </div>
              <div>
                <h3 style={contentStyle}>2</h3>
              </div>
              <div>
                <h3 style={contentStyle}>3</h3>
              </div>
              <div>
                <h3 style={contentStyle}>4</h3>
              </div>
            </Carousel>
          </div>
					<div style={{ padding: "1rem 0rem"}}>
						<Link to="/create-account">
							<Button 
								size={SIZE}
								type="primary"
								block
							>Get Started</Button>
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default GetStarted;
  