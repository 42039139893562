import { Modal, Form, Input, Button, Select, message } from 'antd';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import {
  HuntCandidatePatchProfileInput,
  PatchProfileResponse,
} from '../../Common/types/candidate.type';
import { useMutation } from '@apollo/client';
import { PatchProfileMutations } from '../../Common/queries/myAccount';
// import * as Yup from 'yup';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workStyle: string;
  employmentStatus: boolean;
  jobSearchStatus: string;
  noticePeriod: string;
}

function EditJobPreferenceInfoModal({
  open,
  setOpen,
  workStyle,
  employmentStatus,
  jobSearchStatus,
  noticePeriod,
}: IProps) {
  const [candidateUpdateProfile, { loading: isProfileUpdating }] = useMutation<
    PatchProfileResponse,
    { data: HuntCandidatePatchProfileInput }
  >(PatchProfileMutations.CandidatePatchProfile);

  // useStates for every select field
  const [workStyleFieldValue, setWorkStyleFieldValue] = useState(workStyle);
  const [employmentStatusFieldValue, setEmploymentStatusFieldValue] =
    useState(employmentStatus);
  const [jobSearchStatusFieldValue, setJobSearchStatusFieldValue] =
    useState(jobSearchStatus);
  const [noticePeriodFieldValue, setNoticePeriodFieldValue] =
    useState(noticePeriod);

  const handleCancel = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const data = {
      preferredWorkStyle: workStyleFieldValue,
      currentlyEmployed: employmentStatusFieldValue,
      jobSearchStatus: jobSearchStatusFieldValue,
      startDate: noticePeriodFieldValue,
    };
    try {
      const response = await candidateUpdateProfile({
        variables: { data },
      });
      if (!isProfileUpdating) {
        message.success('Profile updated Successfully', 3);
      }
    } catch (error) {
      error.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    } finally {
      setOpen(false);
    }
  };
  return (
    <Modal
      key='general-info-modal'
      centered
      visible={open}
      onCancel={handleCancel}
      footer={null}
    >
      <h3
        style={{
          fontSize: '24px',
          color: '#071421',
          padding: '16px 20px 0px 16px',
        }}
      >
        Edit job preferences
      </h3>
      <div style={{ padding: '0 20px 0px 20px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: '#6A7279',
              fontWeight: 200,
            }}
          >
            Work style
          </span>
          <Select
            defaultValue={
              workStyleFieldValue === 'At Office'
                ? 'In-office'
                : workStyleFieldValue
            }
            style={{
              width: '100%',
              marginTop: '8px',
              fontSize: '16px',
              background: '#ffff',
            }}
            size='middle'
            onChange={(value) => setWorkStyleFieldValue(value)}
          >
            <Select.Option value='At Office'>At office</Select.Option>
            <Select.Option value='Flexible'>Flexible</Select.Option>
            <Select.Option value='Remote Only'>Remote Only</Select.Option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: '#6A7279',
              fontWeight: 200,
            }}
          >
            Employment status
          </span>
          <Select
            defaultValue={
              // employmentStatusFieldValue ? 'Employed' : 'Not employed'
              employmentStatusFieldValue === true ? 'Employed' : 'Not employed'
            }
            style={{
              width: '100%',
              marginTop: '8px',
              fontSize: '16px',
              background: '#ffff',
            }}
            size='middle'
            onChange={(value: string) => {
              const number = parseInt(value);
              number === 1
                ? setEmploymentStatusFieldValue(true)
                : setEmploymentStatusFieldValue(false);
            }}
            options={[
              {
                value: 1,
                label: 'Employed',
              },
              {
                value: 0,
                label: 'Not employed',
              },
            ]}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: '#6A7279',
              fontWeight: 200,
            }}
          >
            Job search status
          </span>
          <Select
            defaultValue={
              jobSearchStatus === 'active'
                ? `I'm Actively looking`
                : 'Casually browsing'
            }
            style={{
              width: '100%',
              marginTop: '8px',
              fontSize: '16px',
              background: '#ffff',
            }}
            size='middle'
            options={[
              {
                value: 'active',
                label: `I'm Actively looking`,
              },
              {
                value: 'casual',
                label: 'Casually browsing',
              },
            ]}
            onChange={(value: string) => setJobSearchStatusFieldValue(value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: '#6A7279',
              fontWeight: 200,
            }}
          >
            Notice period
          </span>
          <Select
            defaultValue={noticePeriod}
            style={{
              width: '100%',
              marginTop: '8px',
              fontSize: '16px',
              background: '#ffff',
            }}
            size='middle'
            options={[
              {
                value: 'immediately',
                label: 'Immediately',
              },
              {
                value: '1-week',
                label: '1 Week',
              },
              {
                value: '2-weeks',
                label: '2 Weeks',
              },
              {
                value: '1-month',
                label: 'Within a month',
              },
              {
                value: '2-months',
                label: 'Within 2 months',
              },
              {
                value: '3-months',
                label: 'Within 3 months',
              },
            ]}
            onChange={(value) => setNoticePeriodFieldValue(value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            gap: '24px',
            marginTop: '30px',
          }}
        >
          <Button
            style={{
              width: '100%',
              height: '48px',
              background: '#FFF1EB',
              borderColor: '#FFF1EB',
            }}
            className='secondary-btn'
            type='default'
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%', height: '48px' }}
            type='primary'
            htmlType='submit'
            onClick={onSubmit}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditJobPreferenceInfoModal;
