import React, { FunctionComponent, useState, useEffect } from 'react';

import { Form, Row, Col, Button, Select, Divider } from 'antd';
import {
  SearchOutlined,
  EnvironmentOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

// partials
import LocationSearchInput from '../../Common/partials/SearchLocationInput';

//  assets
import searchIcon from '../assets/search-icon.svg';
import locationIcon from '../assets/location-icon.svg';

// types
import { Location } from '../types';

interface JobTopFiltersProps {
  filters?: { query: string[]; location: Location };
  onSubmit(query: string[], location: Location): void;
}

export const JobTopFilters: FunctionComponent<JobTopFiltersProps> = (
  props: JobTopFiltersProps
) => {
  const { filters, onSubmit } = props;
  const [location, setLocation] = useState<Location>(
    filters ? filters.location : null
  );
  const [query, setQuery] = useState<string[]>(
    filters ? filters.query : ([] as string[])
  );

  useEffect(() => {
    setLocation(filters ? filters.location : null);
    setQuery(filters ? filters.query : []);
  }, [filters]);

  return (
    <div
      style={{
        padding: '1rem 1.4rem',
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        marginTop: '1rem',
      }}
      className='shadow-area'
    >
      <Form
        onFinish={() => {
          onSubmit(query, location);
        }}
      >
        <Row gutter={[16, 0]}>
          <Col
            xl={11}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className='mobile-space-between'
          >
            <div className='ghost-select with-right-border'>
              <img src={searchIcon} className='icon' />
              <Select
                placeholder='Job title or keywords'
                size='large'
                mode='tags'
                open={false}
                style={{ width: '100%' }}
                maxTagCount={2}
                value={query}
                notFoundContent={null}
                onChange={(val) => {
                  setQuery(val);
                }}
              />
              {query && query.length > 0 ? (
                <Button
                  type='link'
                  style={{ color: '#0C203A' }}
                  onClick={() => setQuery([])}
                >
                  Clear
                </Button>
              ) : null}
            </div>
          </Col>
          <Col
            xl={11}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className='mobile-space-between'
          >
            <div className='ghost-select'>
              <img src={locationIcon} className='icon' />
              <LocationSearchInput
                onLocationUpdated={(locationData) => {
                  setLocation(locationData);
                }}
                value={location ? location.location : ''}
              />

              {location ? (
                <Button
                  type='link'
                  style={{ color: '#0C203A' }}
                  onClick={() => setLocation(null)}
                >
                  Clear
                </Button>
              ) : null}
            </div>
          </Col>
          <Col flex={1} lg={24} md={24} sm={24} xs={24} className='search-row'>
            <Button
              type='primary'
              size='large'
              block
              htmlType='submit'
              style={{
                height: 50,
                fontWeight: 600,
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
