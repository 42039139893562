import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { debounce } from 'lodash';
import { Row, Col, Button, Spin } from 'antd';
import { Set } from 'immutable';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CloseOutlined,
  LoadingOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';

// API
import { RoosterJobSearch } from '../../api/rooster.search';

// types
import { Job, JobSearchFilters, JobSearchFacets } from '../types';

// partials
import { JobTopFilters } from '../partials/JobTopFilters';
import { JobFaucetFilters } from '../partials/JobFaucetFilters';
import { JobItem } from '../partials/JobItem';

// assets
import filterIcon from '../../Common/assets/filter-icon.svg';
import emptyPlaceholder from '../../Common/assets/empty.svg';

// containers
import { ApplyForJob } from './ApplyForJob';

const defaultSalary = {
  min: 0,
  max: null,
  frequency: 'month',
  currency: 'LKR',
  includeUndeclared: true,
};

const Loading: FunctionComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '4rem 0rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

interface JobSearchProps {
  initialFilters?: JobSearchFilters;
  appliedForJobs: number[];
  refetchProfile: () => void;
}

const JobSearch: FunctionComponent<JobSearchProps> = ({
  initialFilters,
  appliedForJobs,
  refetchProfile,
}: JobSearchProps) => {
  const [jobResultCount, setJobResultCount] = useState<number>();
  const [jobFacets, setJobFacets] = useState<JobSearchFacets>();
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [selectedJobIds, setSelectedJobIds] = useState<Set<number>>(Set([]));
  const [showApplicationForm, setShowApplicationForm] =
    useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useState<JobSearchFilters>(
    initialFilters
      ? initialFilters
      : ({
          query: [],
          limit: 20,
          page: 1,
          filters: {
            salary: defaultSalary,
          },
        } as JobSearchFilters)
  );

  const LoadJobResults = async (filters) => {
    if (!filters.page || filters.page === 1) setLoading(true);

    try {
      const data = await RoosterJobSearch.searchJobs({
        ...filters,
        filters: !filters.filters
          ? null
          : {
              ...filters.filters,
              location:
                filters.filters.location && !filters.filters.location.isCountry
                  ? {
                      ...filters.filters.location,
                      radius: `${filters.filters.location.radius}km`,
                    }
                  : undefined,
              country:
                filters.filters.location && filters.filters.location.isCountry
                  ? filters.filters.location.formatted_address
                  : undefined,
            },
      });

      if (!filters.page || filters.page === 1) {
        setJobResults(data.body.data);
      } else {
        setJobResults([...jobResults, ...data.body.data]);
      }
      setJobResultCount(data.body.count);

      setJobFacets(data.body.facets);
      setLoading(false);
    } catch (e) {
      // console.log(e);
    }
  };

  const DebouncedLoadJobResults = useCallback(debounce(LoadJobResults, 500), [
    jobResults,
    setJobResults,
  ]);

  useEffect(() => {
    LoadJobResults(searchParams);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div style={{ margin: '2rem 0rem 2rem 0rem' }}>
        <JobTopFilters
          filters={{
            query: searchParams.query,
            location: searchParams.filters.location,
          }}
          onSubmit={(query, location) => {
            const filters = {
              query: query,
              filters: {
                salary: initialFilters.filters.salary,
                location: location,
              },
              limit: 20,
              page: 1,
            };

            setSearchParams(filters);
            setLoading(true);
            LoadJobResults(filters);
          }}
        />
      </div>
      <Row gutter={24}>
        {!loading ? (
          <Col
            xs={24}
            style={{
              padding: '0.6rem 2rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            className='mobile-only'
          >
            <h6 style={{ marginBottom: 0 }}>
              {jobResultCount} jobs recommended for you
            </h6>
            {!showFilters ? (
              <Button
                type='link'
                style={{ color: '#F7671E', fontWeight: 500 }}
                onMouseDown={() => setShowFilters(true)}
              >
                <img src={filterIcon} style={{ marginRight: '0.4rem' }} />{' '}
                Filters
              </Button>
            ) : (
              <Button
                type='link'
                style={{ color: '#F7671E', fontWeight: 500 }}
                onMouseDown={() => setShowFilters(false)}
              >
                <CloseOutlined style={{}} /> Close
              </Button>
            )}
          </Col>
        ) : null}
        <Col lg={0} xs={12}>
          {jobFacets && showFilters ? (
            <JobFaucetFilters
              facets={jobFacets}
              filters={searchParams}
              onChange={(filters: JobSearchFilters) => {
                const curFilters = {
                  ...filters,
                  page: 1,
                };
                setSearchParams(curFilters);
                DebouncedLoadJobResults(curFilters);
              }}
            />
          ) : null}
        </Col>
        <Col lg={6} xs={0}>
          {jobFacets ? (
            <JobFaucetFilters
              facets={jobFacets}
              filters={searchParams}
              onChange={(filters: JobSearchFilters) => {
                const curFilters = {
                  ...filters,
                  page: 1,
                };
                setSearchParams(curFilters);
                DebouncedLoadJobResults(curFilters);
              }}
            />
          ) : null}
        </Col>
        <Col lg={18} xs={24}>
          {loading ? (
            <Loading />
          ) : (
            <div
              id='scrollable-job-list'
              style={{ height: '100%', overflow: 'auto' }}
            >
              {jobResultCount ? (
                <Row
                  style={{ marginBottom: '0.8rem' }}
                  className='mobile-only-padding'
                >
                  <Col
                    flex={1}
                    xs={0}
                    style={{ display: 'flex', alignItems: 'center' }}
                    className='hide-mobile'
                  >
                    <h5>{jobResultCount} Jobs</h5>
                  </Col>
                </Row>
              ) : null}
              {selectedJobIds.size > 0 ? (
                <div className='fixed-bottom-horizontally-centered'>
                  <div className='selection-area'>
                    <span style={{ marginRight: '0.8rem', fontSize: '18px' }}>
                      {selectedJobIds.size} Selected
                    </span>
                    <div>
                      <Button
                        type='link'
                        onMouseDown={() =>
                          setSelectedJobIds(selectedJobIds.clear())
                        }
                      >
                        Clear All
                      </Button>
                      <Button
                        type='primary'
                        size='large'
                        onMouseDown={() => setShowApplicationForm(true)}
                        style={{ backgroundColor: '#F7671E', border: 0 }}
                      >
                        Apply to All <ArrowRightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
              <InfiniteScroll
                className='jobs-list'
                scrollableTarget='main-scroll-area'
                dataLength={jobResults.length}
                next={() => {
                  console.log(jobResults);
                  setSearchParams({
                    ...searchParams,
                    page: searchParams.page + 1,
                  });

                  DebouncedLoadJobResults({
                    ...searchParams,
                    page: searchParams.page + 1,
                  });
                }}
                hasMore={jobResults.length < jobResultCount}
                loader={<Loading />}
              >
                {jobResults.length > 0 ? (
                  jobResults.map((job, i) => (
                    <JobItem
                      key={i}
                      selected={selectedJobIds.has(job.id)}
                      hideApply={selectedJobIds.size > 0}
                      job={job}
                      alreadyApplied={appliedForJobs.indexOf(job.id) > -1}
                      onCompanySelect={(companyName) => {
                        console.log(companyName);
                        const filters: JobSearchFilters = {
                          query: [companyName],
                          limit: 20,
                          page: 1,
                          filters: {
                            salary: defaultSalary,
                          },
                        };

                        setSearchParams(filters);
                        setLoading(true);
                        LoadJobResults(filters);
                      }}
                      onSelect={(id) => {
                        setSelectedJobIds(selectedJobIds.add(id));
                      }}
                      onUnselect={(id) => {
                        setSelectedJobIds(selectedJobIds.remove(id));
                      }}
                      onApply={(id) => {
                        setSelectedJobIds(selectedJobIds.clear().add(id));
                        setShowApplicationForm(true);
                      }}
                    />
                  ))
                ) : (
                  <div className='empty-state'>
                    <img
                      src={emptyPlaceholder}
                      style={{ width: 250, marginBottom: '2rem' }}
                    />
                    <h3>
                      We couldn't find any jobs matching your search criteria
                    </h3>
                    <p>Change your search parameters and try again</p>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          )}
        </Col>
      </Row>
      {showApplicationForm ? (
        <ApplyForJob
          onClose={() => {
            setSelectedJobIds(selectedJobIds.clear());
            setShowApplicationForm(false);
            refetchProfile();
          }}
          jobIds={selectedJobIds}
        />
      ) : null}
    </div>
  );
};

export default JobSearch;
