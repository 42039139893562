export interface ImageMappingType {
  [referralCode: string]: string;
}

const imageMapping: ImageMappingType = {
  'dp-education':
    'https://rooster-static-hosting-staging.s3.ap-southeast-1.amazonaws.com/referral-code-imgs/dp-education.jpg',
  'swivel-group':
    'https://rooster-static-hosting-staging.s3.ap-southeast-1.amazonaws.com/referral-code-imgs/swivel-group.jpeg',
  rooster:
    'https://rooster-static-hosting-staging.s3.ap-southeast-1.amazonaws.com/referral-code-imgs/rooster-drawing.jpg',
};

export default imageMapping;
