import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Tag } from "antd";

// styles
import "./stages.scss";

export type HiringStatus = "screening" | "qualified" | "interviews" | "offer" | "hired" | "offerDeclined" | "rejected";

export const getStatusColor = (status: HiringStatus):string => {
	switch (status) {
	case "screening":
		return "#C4C4C4";
	case "qualified":
		return "#717171";
	case "interviews":
		return "#4F6EDA";
	case "offer":
		return "#71D5EB";
	case "hired":
		return "#56BB6C";
	case "rejected":
		return "#DB3832";
	case "offerDeclined":
		return "#E9C509";
	default:
		return "#C4C4C4";
	}
};

export const getStatusLabel = (status: HiringStatus):string => {
	switch (status) {
	case "screening":
		return "Screening";
	case "qualified":
		return "Qualified";
	case "interviews":
		return "Interviews";
	case "offer":
		return "Offer";
	case "hired":
		return "Hired";
	case "rejected":
		return "Rejected";
	case "offerDeclined":
		return "Offer Declined";
	default:
		return "Screening";
	}
};


export const getSubStatusLabel = (status: HiringStatus, subStatus?: string):string => {
	switch (status) {
	case "screening":
		return "Screening";
	case "qualified":
		return "Qualified";
	case "interviews":
		return subStatus;
	case "offer":
		return "Offer";
	case "hired":
		return "Hired";
	case "rejected":
		return "Rejected";
	case "offerDeclined":
		return "Offer Declined";
	default:
		return "Screening";
	}
};

interface InterviewStageSummaryTileProps{
  jobId: number;
  stage: HiringStatus;
  count: number;
}

export const InterviewStageSummaryTile: FunctionComponent<InterviewStageSummaryTileProps> = (props: InterviewStageSummaryTileProps) => {
	return (
		<div className="mini-card">
			<Link to={`/job/${props.jobId}/hiring-pipeline/grid`} className={`stage-label-${props.stage}`}>
				<span className="metric">{props.count ? props.count : "-"}</span>
				<b>{getStatusLabel(props.stage)}</b>
			</Link>
		</div>
	);
};

interface InterviewStageFlagProps{
  stage: HiringStatus;
}

export const InterviewStageFlag: FunctionComponent<InterviewStageFlagProps> = (props: InterviewStageFlagProps) => {
	return (
		<div style={{
			width: 10,
			height: 10,
			borderRadius: 5,
			backgroundColor: getStatusColor(props.stage),
		}}></div>
	);
};

interface InterviewStageTagProps{
  status: HiringStatus;
  count: number;
}

export const InterviewStageTag: FunctionComponent<InterviewStageTagProps> = (props: InterviewStageTagProps) => {
	const { status, count } = props;
	return (
		<Tag color={getStatusColor(status)} style={{ opacity: 0.8 }}>{count}</Tag>
	);
};


interface CandidateStatusTagProps{
  status: HiringStatus;
  substatus: string;
}


export const CandidateStatusTag: FunctionComponent<CandidateStatusTagProps> = (props: CandidateStatusTagProps) => {
	const { status, substatus } = props;
	return (
		<Tag color={getStatusColor(status)} style={{ textTransform: "capitalize" }}>{getSubStatusLabel(status, substatus)}</Tag>
	);
};