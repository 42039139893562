import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';

import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { Formik, FormikErrors } from 'formik';

//Phone input
import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import '../../Common/styles/phoneNumber.less';

// stores
import { tokenStore } from '../../Common/stores/token.store';

//layout
import AuthLayout from '../../Common/containers/AuthLayout';

// types
import {
  SignUpInput,
  SignUpResponse,
  SignUpRequestPayload,
} from '../../Common/types/auth.types';

//assets
import eyeInVisible from '../../Common/assets/hide.svg';
import eyeVisible from '../../Common/assets/show.svg';

//queries
import { SignUpMutations } from '../../Common/queries/auth';

import imageMapping, { ImageMappingType } from '../ImageMapping';
import '../styles/index.less';

interface Payload {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  referralCode?: string;
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const SignUp: FunctionComponent = () => {
  const { referralCode } = useParams<{
    referralCode?: keyof ImageMappingType;
  }>();
  const imageUrl = referralCode
    ? imageMapping[
        referralCode.includes('dp-education') ? 'dp-education' : referralCode
      ]
    : null;

  const setToken = tokenStore((state) => state.setToken);
  const [signUp, { loading }] = useMutation<
    SignUpResponse,
    { data: SignUpRequestPayload }
  >(SignUpMutations.HuntCandidateSignUp);
  const history = useHistory();
  const validate = (values: SignUpInput): FormikErrors<SignUpInput> => {
    const errors: FormikErrors<SignUpInput> = {};

    // Contact Name
    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    // Validate Email
    if (!values.email) {
      errors.email = 'Required';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Invalid Email Address';
    }

    if (!values.phone) {
      errors.phone = 'Required';
    } else if (values.phone.length < 10) {
      errors.phone = 'Phone number cannot be less than 10';
    } else if (
      isValidPhoneNumber(
        values.phone,
        values.country.countryCode.toUpperCase() as CountryCode
      ) !== true
    ) {
      errors.phone = "Phone number doesn't match with the country";
    }

    if (!values.password || values.password.length < 8) {
      // Password
      errors.password = 'Your password should have at least 8 characters';
    }

    return errors;
  };

  const submitForm = async (values: SignUpInput) => {
    try {
      const payload: Payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        password: values.password,
      };
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      const { data } = await signUp({ variables: { data: payload } });

      setToken(data.huntCandidateSignUp.token);
      history.push('/create-profile');
    } catch (e) {
      e.graphQLErrors.forEach((error) => {
        message.error(error.message, 3);
      });
    }
  };

  return (
    <AuthLayout>
      <div className='container'>
        <Row className='justify-center'>
          <Col xs={24} sm={14} md={10} lg={10} xl={8}>
            <div className='inner-card'>
              {imageUrl && (
                <div className='referral-image-container'>
                  <img
                    src={imageUrl}
                    alt='Referral'
                    className='referral-image'
                  />
                </div>
              )}
              <h2 className='heading-section'>Let’s get started</h2>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                  password: '',
                  country: {
                    countryCode: 'lk',
                    dialCode: '94',
                    format: '+.. ... ... ... ... ..',
                    name: 'Sri Lanka',
                  },
                }}
                validate={validate}
                onSubmit={submitForm}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form
                    onFinish={handleSubmit}
                    layout='vertical'
                    colon={false}
                    requiredMark
                  >
                    <Row gutter={16} justify='space-between'>
                      <Col span={12}>
                        <Form.Item
                          label='First name'
                          help={errors.firstName}
                          validateStatus={errors.firstName ? 'error' : ''}
                        >
                          <Input
                            name='firstName'
                            className='form-input'
                            onChange={handleChange}
                            value={values.firstName}
                            size='large'
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label='Last name'
                          help={errors.lastName}
                          validateStatus={errors.lastName ? 'error' : ''}
                        >
                          <Input
                            name='lastName'
                            className='form-input'
                            onChange={handleChange}
                            value={values.lastName}
                            size='large'
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label='Email address'
                      help={errors.email}
                      validateStatus={errors.email ? 'error' : ''}
                      style={{ marginTop: 5 }}
                    >
                      <Input
                        type='email'
                        name='email'
                        className='form-input'
                        placeholder='example@email.com'
                        onChange={handleChange}
                        value={values.email}
                        size='large'
                      />
                    </Form.Item>
                    <Form.Item
                      label='Phone'
                      help={errors.phone}
                      validateStatus={errors.phone ? 'error' : ''}
                      style={{ marginTop: 14 }}
                    >
                      <PhoneInput
                        country={values.country.countryCode}
                        value={values.phone}
                        placeholder='0000-000000'
                        onChange={(value, country) => {
                          setFieldValue('country', country);
                          setFieldValue('phone', value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span>Password&nbsp;</span>}
                      help={errors.password}
                      validateStatus={errors.password ? 'error' : ''}
                      style={{ marginTop: 14 }}
                    >
                      <Input.Password
                        name='password'
                        className='form-input'
                        onChange={handleChange}
                        value={values.password}
                        type='password'
                        size='large'
                        iconRender={(visible) =>
                          visible ? (
                            <img src={eyeVisible} />
                          ) : (
                            <img src={eyeInVisible} />
                          )
                        }
                      />
                    </Form.Item>
                    <div style={{ marginTop: 30 }}>
                      <Button
                        block
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                        size='large'
                        className='primarycta'
                        style={{
                          borderRadius: 5,
                        }}
                      >
                        Create profile
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <Row>
                <Col xs={0} md={24} lg={24} xl={24}>
                  <div
                    style={{
                      padding: '1rem 0rem',
                      marginTop: '3rem',
                      textAlign: 'center',
                      fontSize: 14,
                    }}
                  >
                    Already have an account? &nbsp;
                    <Link to='/sign-in' style={{ fontWeight: 600 }}>
                      Sign in
                    </Link>
                  </div>
                </Col>
                <Col xs={24} md={24} lg={0} xl={0}>
                  <div
                    style={{
                      padding: '1rem 0rem',
                      marginTop: '3rem',
                      fontSize: 14,
                    }}
                  >
                    Already have an account?&nbsp;
                    <Link to='/sign-in' style={{ fontWeight: 600 }}>
                      Sign in
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </AuthLayout>
  );
};
