import create from 'zustand';

// this store is created to update the components appropriately when the data is updated
type refreshState = {
  refreshState: boolean;
  setRefreshState: (refresh: boolean) => void;
};

const useRefreshStore = create<refreshState>((set) => ({
  refreshState: false,
  setRefreshState: (refreshState) => set({ refreshState }),
}));

export default useRefreshStore;
