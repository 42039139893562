import React, { useState } from 'react';
import { Row, Col, Card, Divider, Button, Space, Typography, Tag } from 'antd';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { TransitionGroup } from 'react-transition-group';

//assets
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import jobType from '../../Common/assets/job-type.svg';
import modality from '../../Common/assets/modality.svg';
import location from '../../Common/assets/location.svg';
import reject from '../../Common/assets/reject-cross.svg';
import accept from '../../Common/assets/accept-tick.svg';
import time from '../../Common/assets/time-icon.svg';

//types
import { FetchHuntRequest } from '../../Common/types/requests.type';

type Props = {
  requestId: number;
  request: FetchHuntRequest;
  handleRequestUpdate: (request: FetchHuntRequest, status: boolean) => void;
};

const { Text, Paragraph } = Typography;

const RequestDesktop = ({ requestId, request, handleRequestUpdate }: Props) => {
  const [expanded, setExpanded] = useState<Boolean>(false);

  return (
    <>
      <Card className='request-card'>
        {/* card header */}
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: 16 }}
        >
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: '1 1 69%',
            }}
          >
            <img
              alt='company_logo'
              src={request.company.logoUrl}
              width={45}
              height={45}
            />
            <Space style={{ marginLeft: 12 }}>
              <Text strong style={{ color: '#F7671E', fontSize: 16 }}>
                {request.company.name}
              </Text>
              <Text>
                is requesting to interview you for the following position.
              </Text>
            </Space>
          </Col>
          <Col style={{ marginRight: 9, flex: '1 1 6%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <div>
                <Button
                  type='primary'
                  style={{
                    width: '118px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleRequestUpdate(request, true)}
                >
                  <img src={accept} alt='accept' />
                  <Text
                    style={{
                      color: ' #FFFFFF',
                      opacity: 1,
                      fontWeight: 600,
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    Accept
                  </Text>
                </Button>
              </div>
              <div>
                <Button
                  type='link'
                  style={{
                    width: '70px',
                    height: '23px',
                    color: '#ED1C24',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleRequestUpdate(request, false)}
                >
                  <img src={reject} alt='accept' />
                  <Text
                    style={{
                      color: '#ED1C24',
                      opacity: 1,
                      fontWeight: 600,
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    Reject
                  </Text>
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Divider
          style={{ backgroundColor: '#0C203A', opacity: 0.2 }}
          className='divider-style'
        />
        {/* card Body */}
        <Row>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text strong style={{ fontSize: 18 }}>
                {request.job.title}
              </Text>
              <Text style={{ fontSize: 14, opacity: 0.6, color: '#0C203A' }}>
                <img src={time} alt='time' />
                <span style={{ marginLeft: 10 }}>
                  {moment(request.requestedAt).fromNow()}
                </span>
              </Text>
            </div>
            <Paragraph
              style={{ fontSize: 14, marginTop: 8 }}
            >{`${request.job.department} > ${request.job.class}`}</Paragraph>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img alt='job-type' src={jobType} width={14} height={14} />
                  <Text
                    style={{
                      color: '#0C203A',
                      opacity: 0.6,
                      marginLeft: 8,
                      fontSize: 14,
                    }}
                  >
                    {request.job.jobType}
                  </Text>
                </div>
                {request.job.remote && (
                  <div
                    style={{
                      marginLeft: 12,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img alt='modality' src={modality} width={14} height={14} />
                    <Text
                      style={{
                        color: '#0C203A',
                        opacity: 0.6,
                        marginLeft: 8,
                        fontSize: 14,
                      }}
                    >
                      {request.job.remote && 'Remote'}
                    </Text>
                  </div>
                )}

                <div
                  style={{
                    marginLeft: 12,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img alt='location' src={location} width={14} height={14} />
                  <Text
                    style={{
                      color: '#0C203A',
                      opacity: 0.6,
                      marginLeft: 8,
                      fontSize: 14,
                    }}
                  >
                    {request.job.formattedAddress}
                  </Text>
                </div>
              </div>

              <div className='clickToExpand'>
                {!expanded ? (
                  <a
                    href=''
                    onClick={(e) => {
                      setExpanded(true);
                      e.preventDefault();
                    }}
                  >
                    Click to expand <DownOutlined style={{ marginLeft: 10 }} />
                  </a>
                ) : (
                  <a
                    href=''
                    onClick={(e) => {
                      setExpanded(false);
                      e.preventDefault();
                    }}
                  >
                    Click to collapse <UpOutlined style={{ marginLeft: 10 }} />
                  </a>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <TransitionGroup
          component={null}
          childFactory={(child) =>
            React.cloneElement(child, { classNames: 'collapsible-panel' })
          }
        >
          {expanded ? (
            <div className='collapsible-panel' style={{ paddingTop: 10 }}>
              <Divider />
              <h5>
                {request.job.company} is Hiring a{' '}
                <span
                  className='blue'
                  style={{ fontWeight: 'inherit', fontSize: 'inherit' }}
                >
                  {request.job.title}
                </span>
              </h5>
              <div style={{ padding: '0.6rem 0rem' }}>
                {request.job.tags.map((tag, i) => (
                  <Tag className='primary-tag' key={i}>
                    {tag}
                  </Tag>
                ))}
              </div>
              <div style={{ padding: '1rem 0rem' }}>
                <ReactMarkdown
                  source={request.job.description}
                  escapeHtml={true}
                />
              </div>
              <div
                style={{
                  padding: '1rem 0rem 0rem 0rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              ></div>
            </div>
          ) : null}
        </TransitionGroup>
      </Card>
    </>
  );
};
export default RequestDesktop;
