import React, { FunctionComponent } from "react";
import { Card, Row, Col, Avatar } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import moment from "moment";

// types
import { Candidate } from "../../Common/types/candidate.type";

// partials
import { CandidateStatusTag } from "../../Common/partials/InterviewStage";

// assets
import time from '../../Common/assets/time-icon.svg';

const SpanStyles = {
    marginLeft: 8,
};

interface JobStatusProps{
    candidate: Partial<Candidate>;
}

export const JobStatus: FunctionComponent<JobStatusProps> = (props: JobStatusProps) => {
    const { candidate } = props;

    return (
        <Card className="request-card">
            <Row gutter={16}>
                <Col>
                    <Avatar size={48} src={candidate.job.company.logoUrl}>{candidate.job.company.name.length > 0 ? candidate.job.company.name[0] : "U"}</Avatar>
                </Col>
                <Col flex={1}>
                    <h6><a href={`${process.env.REACT_APP_PUBLIC_JOB_BOARD_HOST}jobs/${candidate.job.id}`} target="_blank">{candidate.job.title}</a>
                        <span style={{ marginLeft: "0.6rem"}}><CandidateStatusTag status={candidate.latestStatusUpdate.status} substatus={candidate.latestStatusUpdate.status === "interviews" ? "Interviews" : undefined} /></span>
                    </h6>
                    <h6>{candidate.job.company.name}</h6>
                    <Row gutter={16} style={{ color: "rgb(12, 32, 58)" }}>
                        <Col>
                            <MessageOutlined />
                            <span style={SpanStyles}>{candidate.candidateCommentCount} comments</span>
                        </Col>
                        <Col>
                            <img src={time} alt="time" />
                            <span style={SpanStyles}>Last update {moment(candidate.latestStatusUpdate.occurredAt).fromNow()}</span>
                        </Col>
                        <Col>
                            <img src={time} alt="time" />
                            <span style={SpanStyles}>Applied {moment(candidate.createdAt).fromNow()}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}