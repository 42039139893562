import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { Formik, FormikErrors } from 'formik';

import Layout from '../Common/containers/Layout';

//queries
import { UpdatePasswordMutations } from '../Common/queries/auth';

//types
import {
	UpdatePasswordInput,
	UpdatePasswordResponse,
} from '../Common/types/auth.types';

// constants
import { SIZE } from '../Common/themes';

interface UpdatePasswordForm extends UpdatePasswordInput {
	confirmPassword: string;
}

const UpdatePassword: FunctionComponent = () => {
	const [updatePassword, { loading }] = useMutation<
		UpdatePasswordResponse,
		{ data: UpdatePasswordInput }
	>(UpdatePasswordMutations.updatePassword);

	const history = useHistory();

	const updatePasswordInitValues: UpdatePasswordForm = {
		currentPassword: undefined,
		newPassword: undefined,
		confirmPassword: undefined,
	};

	const validate = (
		values: UpdatePasswordForm,
	): FormikErrors<UpdatePasswordForm> => {
		const errors: FormikErrors<UpdatePasswordForm> = {};
		// Password
		if (!values.newPassword) {
			errors.newPassword = 'Required';
		}
		if (values.newPassword && values.newPassword.length < 6) {
			errors.newPassword = 'Your password should have at least 6 characters';
		}

		if (!values.confirmPassword) {
			errors.confirmPassword = 'Required';
		}

		if (
			values.confirmPassword &&
			values.newPassword !== values.confirmPassword
		) {
			errors.confirmPassword = "Oops... Your passwords don't match";
		}
		return errors;
	};

	const submitForm = async (values: UpdatePasswordForm) => {
		try {
			const updatePasswordInput: UpdatePasswordInput = {
				currentPassword: values.currentPassword,
				newPassword: values.newPassword,
			};

			await updatePassword({ variables: { data: updatePasswordInput } });
			message.success('Your password has been update successfully!', 3);
			history.push('/');
		} catch (e) {
			if (e.graphQLErrors) {
				e.graphQLErrors.forEach((error) => {
					message.error(error.message, 3);
				});
			} else {
				message.error(e.message, 3);
			}
		}
	};

	return (
		<Layout>
			<div className="container">
				<Row className="justify-center">
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={8}
						xl={10}
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<div className="inner-card" style={{ marginTop: '10rem' }}>
							<h2 className="onboarding-headline">Update your password</h2>
							<Formik
								initialValues={updatePasswordInitValues}
								validate={validate}
								onSubmit={submitForm}
							>
								{({ values, errors, handleChange, handleSubmit }) => (
									<Form onFinish={handleSubmit} colon={false} layout="vertical">
										<Form.Item
											label="Current Password"
											help={errors.currentPassword}
											validateStatus={errors.currentPassword ? 'error' : ''}
										>
											<Input
												name="currentPassword"
												onChange={handleChange}
												type="password"
												value={values.currentPassword}
												size={SIZE}
											/>
										</Form.Item>
										<Form.Item
											label="New Password"
											help={errors.newPassword}
											validateStatus={errors.newPassword ? 'error' : ''}
										>
											<Input
												name="newPassword"
												onChange={handleChange}
												type="password"
												value={values.newPassword}
												size={SIZE}
											/>
										</Form.Item>
										<Form.Item
											label="Confirm New Password"
											help={errors.confirmPassword}
											validateStatus={errors.confirmPassword ? 'error' : ''}
										>
											<Input
												name="confirmPassword"
												onChange={handleChange}
												type="password"
												value={values.confirmPassword}
												size={SIZE}
											/>
										</Form.Item>
										<Form.Item>
											<div style={{ marginTop: 25 }}>
												<Button
													type="primary"
													htmlType="submit"
													size={SIZE}
													loading={loading}
												>
													Update Password
												</Button>
											</div>
										</Form.Item>
									</Form>
								)}
							</Formik>
						</div>
					</Col>
				</Row>
			</div>
		</Layout>
	);
};

export default UpdatePassword;
