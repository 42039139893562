import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

// import '../components/Layout';
// import MetaTags from '../components/MetaTags';

import pageNotFound from '../assets/404.svg';

export default function Page404() {
	return (
		<>
			<div className="app-area">
				<div className="container">
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '4rem 0rem',
							textAlign: 'center',
						}}
					>
						<h2>Page Not Found</h2>
						<Link href="/">
							<Button type="primary">Take me home</Button>
						</Link>
						<img
							src={pageNotFound}
							alt="404"
							style={{
								maxWidth: 350,
								marginTop: '2rem',
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
