import { gql } from "@apollo/client";

// GraphQL
export const Queries = {
	getJob: gql`
    query GetJob($id: Int!){
      fetchOneJobPublic(id: $id){
        id
        title
        description
        jobType
        location
        remote
        class
        subclass
        tags
        active
        department
        location
        customQuestions{
          type
          question
          required
        }
        company{
          id
          subdomain
          name
          logoUrl
        }
      }
    }
	`,
};