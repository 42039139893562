import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Select, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import Layout from '../../Common/containers/Layout';

import RequestDesktop from '../containers/RequestDesktop';
import RequestMobile from '../containers/RequestMobile';

//Queries
import {
	getHuntRequestQueries,
	updateHuntRequestAppQueries,
} from '../../Common/queries/requests';

//Types
import {
	HuntRequestsResponse,
	UpdateHuntRequestAppInput,
	UpdateHuntRequestAppResponse,
} from '../../Common/types/requests.type';

//skeletons
import FormSkeleton from '../../Common/partials/Skeletons/FormSkeleton';

const { Option } = Select;

const Requests: FunctionComponent = () => {
	const { data: huntRequests, loading: isLoading } =
		useQuery<HuntRequestsResponse>(getHuntRequestQueries.HuntRequests);

	const [updateHuntRequestApp, { loading: isUpdating }] = useMutation<
		UpdateHuntRequestAppResponse,
		{ data: UpdateHuntRequestAppInput }
	>(updateHuntRequestAppQueries.UpdateHuntRequests);

	if (isLoading) {
		return <FormSkeleton page="requests" />;
	}
	const handleRequestUpdate = async (request, status) => {
		try {
			const payload = {
				huntId: request.id,
				acceptRequest: status,
			};

			await updateHuntRequestApp({
				variables: { data: payload },
			});

			if (!isUpdating) {
				message.success(
					`Hunt request ${status ? ' approved' : ' rejected'}`,
					3,
				);
			}
		} catch (e) {
			e.graphQLErrors.forEach((error) => {
				message.error(error.message, 3);
			});
		}
	};

	//Get all the pending requests
	const unApprovedRequests = huntRequests.fetchHuntRequests.filter(
		(request) => request.status === 0 || request.status === 1,
	);

	if (unApprovedRequests.length === 0) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '16px',
					fontVariant: 'normal',
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					color: '#0C203A',
					opacity: 1,
				}}
			>
				No requests available
			</div>
		);
	}

	return (
		<Layout>
			<>
				<Row>
					<Col xs={0} lg={24}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '0 2px',
								margin: '20px 0',
							}}
						>
							<div
								style={{ fontSize: '18px' }}
							>{`${unApprovedRequests.length} Interview requests`}</div>
						</div>
					</Col>
					<Col xs={24} lg={0}>
						<div
							style={{
								fontSize: '18px',
								alignItems: 'center',
								padding: '0 2px',
								margin: '20px 0 0',
							}}
						>{`${unApprovedRequests.length} Interview requests`}</div>
					</Col>
				</Row>
				<Row style={{ marginBottom: 30, paddingBottom: 20 }}>
					{unApprovedRequests.map((request, i) => {
						return (
							<>
								<Col xs={0} lg={24} xl={24} className="jobRequestCard">
									<RequestDesktop
										requestId={i}
										request={request}
										handleRequestUpdate={handleRequestUpdate}
									/>
								</Col>
								<Col xs={24} lg={0} className="jobRequestCard">
									<RequestMobile
										requestId={i}
										request={request}
										handleRequestUpdate={handleRequestUpdate}
									/>
								</Col>
							</>
						);
					})}
				</Row>
			</>
		</Layout>
	);
};

export default Requests;
