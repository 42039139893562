import React, { FunctionComponent } from 'react';
import { Avatar } from 'antd';

interface ProfilePictureProps {
  user: {
    firstName: string;
    profilePictureURL: string | undefined | null;
  };
  size: number;
  overlay?: boolean;
}

export const ProfilePicture: FunctionComponent<ProfilePictureProps> = ({
  user,
  size,
  overlay,
}: ProfilePictureProps) => {
  return (
    <>
      <Avatar
        size={size}
        src={user.profilePictureURL ? user.profilePictureURL : null}
        style={{
          backgroundColor: '#2c5560',
          fontSize: size / 2.6,
          color: '#F8793A',
        }}
      >
        {user.firstName.length > 0 ? user.firstName[0] : ''}
      </Avatar>
      {overlay === false ? null : <div className='overlay' />}
    </>
  );
};
